import React, {useEffect, useState} from 'react';

const DayPicker = ({ label, onDaySelect, selected }) => {
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        // Update isSelected state based on the selected prop
        setIsSelected(selected.indexOf(label) !== -1);
    }, [selected, label]);

    const firstLetter = label.charAt(0);

    const handleClick = () => {
        setIsSelected(!isSelected);
        onDaySelect(label, !isSelected);
    };

    return (
        <button
            className={`custom-button ${isSelected ? 'selected' : ''}`}
            onClick={handleClick}
        >
      <span className="letter" style={{ color: isSelected ? '#FFF' : '#000', fontFamily:"Nunito" }}>
        {firstLetter}
      </span>
        </button>
    );
};

export default DayPicker;