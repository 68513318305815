import {useEffect, useState} from "react";
import {createTheme, ThemeProvider} from "@mui/material";
import {Routes, BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Footer from "./components/footer";
import Login from "./components/login";

export default function App() {

  const [theme, setTheme] = useState('light');

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    console.log("new theme ", theme);
  };

  useEffect(() => {
    //const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    //console.log("prefer dark mode ", prefersDarkMode);
    // if (prefersDarkMode) {
    setTheme('light');
    // }
  }, []);

  useEffect(() => {
    // Apply the theme to your app's root element or body
    document.body.className = theme;
  }, [theme]);

  return (
              <Login></Login>
  );
};
