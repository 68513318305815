import React, {useEffect, useState} from 'react';
import firebase from "firebase/compat/app";
import 'firebase/firestore'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import {useLocation, useNavigate} from "react-router-dom";
import trashlogo from "../assets/trashic.svg";
import icLydia from "../assets/iconLydia.svg";
import icPaylib from "../assets/iconPaylib.svg";
import icWise from "../assets/iconWise.svg";
import icRevolut from "../assets/iconRevolut.svg";
import icLinktree from "../assets/iconLinktree.svg";
import icBeacons from "../assets/iconBeacons.svg";
import icAllMyLinks from "../assets/iconAllMyLinks.svg";
import icSumUp from "../assets/iconSumUp.svg";

import {
    Autocomplete, Backdrop,
    Chip, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography, useMediaQuery, useTheme
} from "@mui/material";

import {
    checkIfPseudoExists, checkIfUrlExists,
    getUserPostCreateStatus,
    handleUpload, logout, updateAgenda,
    updateUserDocument
} from '../firebase.js'
import Button from "@mui/material/Button";
import {
    FaDailymotion, FaEnvelope,
    FaFacebook,
    FaFacebookMessenger, FaGlobe,
    FaInstagram, FaPaperclip,
    FaPinterest,
    FaSnapchat, FaStripe,
    FaTiktok,
    FaWhatsapp, FaYoutube
} from "react-icons/fa";
import SnackbarWithCustomBackground from "./snackbar";
import {FaPaypal} from "react-icons/fa6";
import rec from "../assets/rec.png";
import deleteic from "../assets/icdeletetag.svg";
import {serverTimestamp} from "@firebase/firestore";

function removeSpecialCharacters(inputString) {
    // Define a regular expression to match characters other than letters, digits, dashes, and underscores
    const regex = /[^a-zA-Z0-9-_]/g;

    // Use the replace method to remove the matched characters
    const cleanedString = inputString.replace(regex, '');

    return cleanedString;
}

const isValidURL = (urlField) => {
    // TODO restrict used routes from the customer app
    // Check if the URL field does not contain 'faismoibg.com', 'faismoibg', and spaces
    const containsForbiddenStrings = ['faismoibg.com', 'faismoibg'].some((forbiddenString) =>
        urlField.includes(forbiddenString)
    );

    // Check if the URL field contains any spaces
    const containsSpaces = /\s/.test(urlField);

    return !containsForbiddenStrings && !containsSpaces;
};

const checkPseudoValid = (pseudo) => {
    // Check if the URL field contains any spaces
    return /\s/.test(pseudo);
};

function formatSocialForDb(array) {
    const formattedArray = array.map(item => {
        if (!item.link) {
            return { ...item, link: '' };
        }
        return item;
    });

    const missingNetworks = [
        { network: 'instagram', link: '' },
        { network: 'facebook', link: '' },
        { network: 'tiktok', link: '' },
        { network: 'messenger', link: '' },
        { network: 'whatsapp', link: '' },
        { network: 'pinterest', link: '' },
        { network: 'snapchat', link: '' },
        { network: 'paypal', link: '' },
        { network: 'lydia', link: '' },
        { network: 'paylib', link: '' },
        { network: 'stripe', link: '' },
        { network: 'sumup', link: '' },
        { network: 'wise', link: '' },
        { network: 'revolut', link: '' },
        { network: 'linktree', link: '' },
        { network: 'linkinbio', link: '' },
        { network: 'beacons', link: '' },
        { network: 'allmylinks', link: '' },
        { network: 'youtube', link: '' },
        { network: 'dailymotion', link: '' },
        { network: 'site', link: '' },
        { network: 'email', link: '' }
    ];

    missingNetworks.forEach(missingItem => {
        if (!formattedArray.find(item => item.network === missingItem.network)) {
            formattedArray.push(missingItem);
        }
    });

    return formattedArray;
}

const timezones = [
    'Europe/Paris',              // France (CEST)
    'America/Martinique',       // Martinique (AST)
    'America/Guadeloupe',       // Guadeloupe (AST)
    'America/Cayenne',          // French Guiana (GFT)
    'America/Port_of_Spain',    // Saint Martin (AST)
    'America/Montreal',         // French-speaking Canada (Eastern Time)
    'Europe/London'             // United Kingdom (GMT)
];

function PostCreate() {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const [showBackdrop, setShowBackdrop] = useState(true);

    useEffect( () => {
            const unregisterAuthObserver = firebase.auth().onAuthStateChanged( async(user) => {
                if (!user) {
                    navigate("/");
                } else if (user.isAnonymous && user.providerData.length<1) {
                    // User is authenticated as an anonymous user, so sign them out. Unsure if conflicts could arise since we use different domains for the customer app
                    try {
                        await firebase.auth().signOut();
                    } catch (error) {
                        // Handle any sign-out errors here.
                        console.error("Error signing out:", error);
                    }
                } else {
                    const fetchData = async () => {
                        try {
                            const uid = firebase.auth().currentUser.uid;
                            const postCreateDone = await getUserPostCreateStatus(uid);
                            return postCreateDone;
                        } catch (error) {
                            console.error('Error retrieving postCreateDone status:', error);
                            await firebase.auth().signOut();
                        }
                    };
                    // Call the fetchData function directly
                    const displayPage = await fetchData();
                    if (displayPage){
                        navigate("/home");
                    } else {
                        setShowBackdrop(false);
                    }
                }
            });
            // Cleanup the observer when the component is unmounted
            return () => unregisterAuthObserver();
        }, []);

        const [imageUrl, setImageUrl] = useState('');
        const [file, setFile] = useState(null);

        const thematiques = [
            {label:"Spa et Massage", id:1},
            {label:"Coiffure", id:2},
            {label:"Cils et Sourcils", id:3},
            {label:"Ongles", id:4},
            {label:"Soins esthétiques", id:5},
            {label:"Tatouage et Piercing", id:6},
            {label:"Maquillage", id:7},
            {label: "Autre", id:8}
        ];

        const [themValue, setThemValue] = React.useState([]);

        const handleFileChange = async (event) => {

           /* let selectedFile = event.target.files[0];
            setFile(selectedFile);
            try {
                console.log("func called");
                const urll = await handleUpload(selectedFile);
                console.log("url ", urll);
                setImageUrl(urll);
            } catch (e) {
                //todo display upload error
                console.log("Error while uploading image ", e);
            }*/

            const newfile = event.target.files[0];
            setFile(newfile)
            if (newfile) {
                const reader = new FileReader();
                reader.onload = () => {
                    // reader.result contains the data URL
                    setImageUrl(reader.result);
                };
                reader.readAsDataURL(newfile);
            }
        };

    const [socialLinks, setSocialLinks] = useState([
    ]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleLinkChange = (network, event) => {
        const updatedLinks = socialLinks.map((link) => {
            if (link.network === network) {
                return { ...link, link: event.target.value.trim() };
            }
            return link;
        });
        setSocialLinks(updatedLinks);
    };

    const handleAddLink = () => {
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleDialogOptionClick = (network) => {
        const isNetworkSelected = socialLinks.some((link) => link.network === network);
        if (!isNetworkSelected) {
            const updatedLinks = [...socialLinks, { network, link: '' }];
            setSocialLinks(updatedLinks);
        }
        setIsDialogOpen(false);
    };

    const handleDeleteLink = (network) => {
        const updatedLinks = socialLinks.filter((link) => link.network !== network);
        setSocialLinks(updatedLinks);
    };


    const deleteImage = async (url) => {

        try {
            setImageUrl('');
            setFile(null);
            const fileInput = document.getElementById('file-input');
            if (fileInput) {
                fileInput.value = null;
            }
        } catch (e) {
            //todo display delete error
            console.log("Error while deleting image ", e);
        }

    };


    //Keep the dropdown list opened after an element is picked
    const [isPopperOpen, setPopperOpen] = useState(false);

    // Custom styles for the dropdown list options
    const getOptionStyle = () => ({
        borderRadius: "15px", // Set the border radius for each option
        margin: "10px 0", // Add space between the list items
        background: "#DAE0E5", // Set background color for each option
        padding: "15px", // Add padding for each option
    });

    const [formData, setFormData] = useState({
            pseudo: '',
            urlPerso: '',
            pres: '',
        });
    const handleFormChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.name==="urlPerso" ? removeSpecialCharacters(event.target.value.toLowerCase().trim()) : event.target.value
        });
    };


    const [isPseudoValid, setPseudoValid] = useState(true);
    const [isUrlValid, setUrlValid] = useState(true);
    const [isthemValid, setThemValid] = useState(true);

    //TODO check URL is not taken in DB
    const handleFormSubmit = async () => {
        // Here you can use the 'formData' object to process the data as needed
        setShowBackdrop(true);

        if (formData.pseudo.length===0){
            handleOpenSnackbar("Vous devez remplir les champs en rouge");
            setPseudoValid(false);
            setShowBackdrop(false);
            return;
        } else {
            if (checkPseudoValid(formData.pseudo)){
                setPseudoValid(false);
                setShowBackdrop(false);
                handleOpenSnackbar("Votre pseudo ne doit pas contenir d'espaces");
                return;
            } else {
                setPseudoValid(true);
            }
        }

        if (formData.urlPerso.length===0){
            setUrlValid(false);
            setShowBackdrop(false);
            handleOpenSnackbar("Vous devez remplir les champs en rouge");
            return;
        } else {
            if (!isValidURL(formData.urlPerso)){
                setUrlValid(false);
                setShowBackdrop(false);
                handleOpenSnackbar("Entrez uniquement la personnalisation de l'URL, pas de https ni de nom de domaine. L'URL doit contenir des champs valides, pas d'espaces ni de '+'");
                return;
            } else if (await checkIfUrlExists(formData.urlPerso.toLowerCase())){
                setUrlValid(false);
                setShowBackdrop(false);
                handleOpenSnackbar("Cette URL existe déjà, choisissez en une autre");
                return;
            } else {
                setUrlValid(true);
            }
        }

        if (themValue.length===0){
            handleOpenSnackbar("Vous devez remplir les champs en rouge");
            setThemValid(false);
            setShowBackdrop(false);
            return;
        } else {
            setThemValid(true);
        }

        let newLink = "";
        //file logic
        if (file){
            try {
                newLink = await handleUpload(file);
            } catch (e){
                setShowBackdrop(false);
                handleOpenSnackbar("Quelques chose s'est mal passé lors du chargement de l'image, contactez nous => ", e);
                return;
            }
        }

        //Todo DB call to check that URL is not taken already
        //TODO replaces all occurrences of e with e.message

        try {
            if (await checkIfPseudoExists(formData.pseudo)){
                setShowBackdrop(false);
                handleOpenSnackbar("Ce pseudo existe déjà :/");
                return;
            } else {
                //good all condition met, let's write our object to firestore
                try {
                    const dataToUpdate = {
                        imageUrl: newLink,
                        urlPerso: formData.urlPerso.toLowerCase(),
                        pseudo: formData.pseudo,
                        presentation: formData.pres,
                        socialLinks: formatSocialForDb(socialLinks),
                        themeValues: themValue,
                        postCreateDone:true,
                        address:[],
                        timezone: selectedTimezone
                    };
                    let agendaObject = {
                        lundiWorked : {
                            checked : true,
                            start : "10h00",
                            end : "17h00",
                            breaks : [
                            ]
                        },
                        mardiWorked: {
                            checked : true,
                            start : "10h00",
                            end : "17h00",
                            breaks : [
                            ]
                        },
                        mercrediWorked:{
                            checked : true,
                            start : "10h00",
                            end : "17h00",
                            breaks : [
                            ]
                        },
                        jeudiWorked: {
                            checked : true,
                            start : "10h00",
                            end : "17h00",
                            breaks : [
                            ]
                        },
                        vendrediWorked:{
                            checked : true,
                            start : "10h00",
                            end : "17h00",
                            breaks : [
                            ]
                        },
                        samediWorked:{
                            checked : false,
                            start : "",
                            end : "",
                            breaks : [
                            ]
                        },
                        dimancheWorked:{
                            checked : false,
                            start : "",
                            end : "",
                            breaks : [
                            ]
                        },
                        timeBeforePresta:"1j",
                        googleAGCode : false,
                        timestamp : serverTimestamp(),
                        uid : firebase.auth().currentUser.uid,
                    }

                    const subObj = {
                        status:"free",
                        count:0
                    }
                    await updateAgenda(agendaObject, firebase.auth().currentUser.uid);
                    await updateUserDocument(firebase.auth().currentUser.uid, dataToUpdate);
                    setShowBackdrop(false);
                    navigate("/agendaparam",{
                        state:{
                            isPostCreate:true
                        }
                    });
                } catch (e) {
                    setShowBackdrop(false);
                    console.error('Error updating document: ', + e.message);
                    handleOpenSnackbar("oooops ", e.message);
                }
            }
        } catch (e) {
            setShowBackdrop(false);
            handleOpenSnackbar("Contactez nous : " + e.message);
        }


        setShowBackdrop(false);

    };

    //snackbar logic

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [selectedTimezone, setSelectedTimezone] = useState("Europe/Paris");
    const handleChange = (event, newValue) => {
        setSelectedTimezone(newValue);
    };

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    const handleLogOut = async () => {
        try {
            await logout();
        } catch (e){
            console.log(e);
        }
    };

    const formattedLabel = (
        <label className="label-style">
            faismoibg.com/
            <span className="bold-part">{formData.urlPerso.toLowerCase()}</span>
        </label>
    );

/*    const isOptionEqualToValue = (option, value) => {
        //bypassing this check
        return true;
    };*/


    //Loading
    //Display before data loaded
    if (showBackdrop) {
        return <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showBackdrop}
        >
            <CircularProgress color="inherit" />
        </Backdrop>;
    }


    return(
        <div className="sub-container">
            <div className="sub-container-no-top">
                <div className="title-container">
                    <span className="title-style">Créez votre profil</span>
                </div>

                <div className="title-field-container">
                                      <span className="text-field-style">
                                          Photo
                                      </span>
                </div>
                <div style={{display:"flex", justifyContent:"start", flexDirection:"column", alignItems:"start", alignContent:"start", width:"100%"}}>
                    <div style={{flexDirection:"row", display:"flex", alignItems:"center"}}>
                        <div
                            style={{
                                width: '100px',
                                height: '100px',
                                borderRadius: '50%',
                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
                                margin: 'auto',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                overflow: 'visible',
                                backgroundColor:'#F1F1F1',
                                position:"relative"
                            }}
                        >
                            {imageUrl ? (
                                <img onLoad={handleImageLoad}
                                     className="fade-in" src={imageUrl} alt="Uploaded" style={{
                                    borderRadius: '50%', width: '100%', height: '100%', objectFit: 'cover', minWidth:"100px" }}/>
                            ) : (
                                <img src={rec} style={{ borderRadius: '50%', width: '110px', height: '110px', objectFit: 'cover' }} ></img>
                            )}
                        </div>
                    </div>

                    <div style={{ color:"white", display:"flex", alignContent:'start', alignItems:'start', justifyContent:'start', marginTop:"10px"}}>
                        <Button disableElevation={true} className="button-style-noborders-lil" component="label" variant="contained" style={{backgroundColor:"black", color:"white"}}>
                            <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px", fontWeight:"400"}}>
                                Ajouter
                            </Typography>
                            <input accept="image/jpeg,image/png,image/gif,image/svg+xml"
                                   id="file-input" style={{ display: 'none' }} type="file" onChange={handleFileChange} />
                        </Button>
                        <div style={{ color:"white", display:"flex", alignContent:'center', alignItems:'center', justifyContent:'center', marginLeft:"13px"}}>
                            <IconButton className="button-style-borders-icon" variant="contained" onClick={deleteImage} style={{ color:"white", display:"flex", padding:"10px"}}>
                                <img src={trashlogo} style={{color:"black", width:"20px", height:"20px"}}></img>
                            </IconButton>
                        </div>
                    </div>
                </div>

                <div className="field-container" style={{marginTop:"40px"}}>
                    <div className="title-field-container">
                                      <span className="text-field-style">
                                          Pseudo <span className="aster">*</span>
                                      </span>
                    </div>
                    <TextField className="field-style" id="outlined-basic" label="Ecrire" variant="outlined" value={formData.pseudo}
                               onChange={handleFormChange} name="pseudo" error={!isPseudoValid}/>
                </div>

                <div className="field-container-b">
                    <div className="title-field-container">
                                      <span className="text-field-style">
                                          Thématiques <span className="aster">*</span>
                                      </span>
                    </div>

                        {/*<Autocomplete className="custom-autocomplete button-autocomplete" style={{background:"#F1F5F8", borderRadius:"15px"}}
                            disablePortal
                            id="combo-box-demo"
                            options={thematiques}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Thématique" />}
                        />*/}

                    <div style={{width:"100%"}}>
                        <Autocomplete
                            PopperComponent={(props) => (
                                <div style={{display:"flex", flexDirection:"column"}}>
                                    {props.children}
                                </div>
                            )}
                            disableClearable={true} className="custom-autocomplete button-autocomplete" style={{background:"#F1F5F8", borderRadius:"15px", width:"100%"}}
                            multiple
                            id="fixed-tags-demo"
                            onOpen={() => setPopperOpen(true)}
                            onClose={() => setPopperOpen(false)}
                            open={isPopperOpen}
                            // isOptionEqualToValue={isOptionEqualToValue}
                            value={themValue}
                                      disablePortal
                            onChange={(event, newValue) => {
                                const uniqueTags = newValue.filter(
                                    (tag, index) => newValue.findIndex((t) => t.label === tag.label) === index
                                );
                                setThemValue(uniqueTags)
                            }}
                              options={thematiques.filter(
                                  (option) => !themValue.some((tag) => tag.label === option.label)
                              )}
                            getOptionLabel={(option) => option?.label || ''}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                    <Chip style={{background:"linear-gradient(90deg, #4808FF 0%, #9747FF 100%)", color:"white", fontWeight:400}}
                                        label={option?.label}
                                        deleteIcon={<img src={deleteic} style={{width:"20px", height:"20px", marginRight:"8px"}}></img>}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                          disableCloseOnSelect
                          componentsProps={{
                              popper: {
                                  modifiers: [
                                      {
                                          name: 'flip',
                                          enabled: false
                                      },
                                      {
                                          name: 'preventOverflow',
                                          enabled: false
                                      }
                                  ]
                              }
                          }}
                          renderOption={(props, option, { selected }) => {
                              if (selected) {
                                  return null;
                              }
                              return <li style={getOptionStyle()} {...props}>{option.label}</li>;
                          }}
                            renderInput={({ inputProps, ...params }) => (
                                <TextField {...params}
                                           label="Choix"
                                           inputProps={{ ...inputProps, readOnly: true }}
                                           error={!isthemValid}
                                           placeholder="Séléctionner une thématique" />
                            )}
                        />
                    </div>
                </div>

                <div className="field-container-b">
                    <div className="title-field-container">
                                      <span style={{fontWeight:"700", color:"black", fontSize:"16px"}}>
                                          Fuseau Horaire
                                      </span>
                    </div>
                </div>
                <div style={{width:"100%"}}>
                    <Autocomplete
                        disableClearable={true}
                        value={selectedTimezone}
                        onChange={handleChange}
                        options={timezones}
                        PopperComponent={(props) => (
                            <div style={{display:"flex", flexDirection:"column"}}>
                                {props.children}
                            </div>
                        )}
                        renderInput={({ inputProps, ...params }) => (
                            <TextField {...params}
                                       label="Timezone"
                                       inputProps={{ ...inputProps, readOnly: true }}
                                       placeholder="Timezone" />
                        )}
                        getOptionLabel={(option) => option}
                        fullWidth
                        componentsProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'flip',
                                        enabled: false
                                    },
                                    {
                                        name: 'preventOverflow',
                                        enabled: false
                                    }
                                ]
                            }
                        }}
                    />
                </div>

                <div className="field-container-b">
                    <div className="title-field-container">
                                      <span className="text-field-style">
                                          Url personnalisée de votre page <span className="aster">*</span>
                                      </span>
                    </div>
                    <TextField className="field-style" id="outlined-basic" label={formattedLabel} variant="outlined" value={formData.urlPerso.toLowerCase()}
                               onChange={handleFormChange} name="urlPerso" error={!isUrlValid}/>
                </div>
                {/*todo autogrow that TextField - Allow line breaks */}
                <div className="field-container-b">
                    <div className="title-field-container">
                                      <span className="text-field-style">
                                          Présentation
                                      </span>
                    </div>
                    <TextField multiline
                               maxRows={12}
                        className="field-style" id="outlined-basic" label="Ecrire" variant="outlined" value={formData.pres}
                               onChange={handleFormChange} name="pres"/>
                </div>
                <div className="field-container-b">
                    <div className="title-field-container">
                                      <span style={{fontWeight:"700", color:"black", fontSize:"16px"}}>
                                          Réseaux sociaux
                                      </span>
                    </div>
                </div>
                <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
                    {socialLinks.map((socialLink) => (
                        <div key={socialLink.network} style={{ display: 'flex', marginTop:"10px", alignItems: 'center', alignContent:"space-evenly", justifyContent:"space-evenly", marginBottom: '10px', width:"100%" }}>
                            {socialLink.network === 'instagram' &&<div style={{width:"20%"}}> <FaInstagram  style={{ marginRight: '10px', height:"40px", width:"40px" }} /></div>}
                            {socialLink.network === 'facebook' && <div style={{width:"20%"}}><FaFacebook style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'tiktok' && <div style={{width:"20%"}}><FaTiktok style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'messenger' && <div style={{width:"20%"}}><FaFacebookMessenger style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'pinterest' && <div style={{width:"20%"}}><FaPinterest style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'snapchat' && <div style={{width:"20%"}}><FaSnapchat style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'whatsapp' && <div style={{width:"20%"}}><FaWhatsapp style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'paypal' && <div style={{width:"20%"}}><FaPaypal style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'lydia' && <div style={{width:"20%"}}> <img src={icLydia} style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'paylib' && <div style={{width:"20%"}}> <img src={icPaylib} style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'stripe' && <div style={{width:"20%"}}><FaStripe style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'sumup' && <div style={{width:"20%"}}> <img src={icSumUp} style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'wise' && <div style={{width:"20%"}}><img src={icWise} style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'revolut' && <div style={{width:"20%"}}> <img src={icRevolut} style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'linktree' && <div style={{width:"20%"}}> <img src={icLinktree} style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'linkinbio' && <div style={{width:"20%"}}> <FaPaperclip style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'beacons' && <div style={{width:"20%"}}> <img src={icBeacons} style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'allmylinks' && <div style={{width:"20%"}}> <img src={icAllMyLinks} style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'youtube' && <div style={{width:"20%"}}> <FaYoutube style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'dailymotion' && <div style={{width:"20%"}}> <FaDailymotion style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'site' && <div style={{width:"20%"}}> <FaGlobe style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'email' && <div style={{width:"20%"}}> <FaEnvelope style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            <TextField
                                label={ socialLink.network==='email' ? "Ex: mailto:example@gmail.com" : `Lien ${socialLink.network}`}
                                variant="outlined"
                                fullWidth
                                value={socialLink.link}
                                onChange={(event) => handleLinkChange(socialLink.network, event)}
                            />
                            <div style={{width:"20%", display:"flex", justifyContent:"end", marginLeft:"6px"}}>
                                <IconButton className="button-style-borders-icon" variant="contained" size="medium" onClick={() => handleDeleteLink(socialLink.network)}  style={{ color:"white", display:"flex", justifySelf:"end",padding:"10px"}}>
                                    <img src={trashlogo} style={{color:"black", width:"20px", height:"20px"}}></img>
                                </IconButton>
                            </div>
                        </div>
                    ))}

                    <div style={{ color:"white", alignContent:'start', alignItems:'start', justifyContent:'start', marginTop:"14px"}}>
                        <Button disableElevation={true} className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}} onClick={handleAddLink}>
                            <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px", fontWeight:"400"}}>
                                Ajouter
                            </Typography>
                        </Button>
                    </div>

                    <div className="button-container" style={{marginTop:"70px"}}>
                        <Button onClick={handleFormSubmit} className="button-style button-style-noborders" variant="contained">
                            <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                Enregistrer
                            </Typography>
                        </Button>
                    </div>
                    <div className="button-container" style={{marginTop:"15px"}}>
                        <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={handleLogOut}>
                            <Typography variant="button" style={{ textTransform: 'none', fontWeight:"400" }}>
                                Annuler
                            </Typography>
                        </Button>
                    </div>


                    <Dialog fullWidth={!isLargeScreen}
                            PaperProps={{
                                style: {
                                    minWidth: isLargeScreen ? '500px' : 'auto',
                                    maxWidth: isLargeScreen ? '500px' : 'auto',
                                },
                            }} open={isDialogOpen} onClose={handleDialogClose} style={{minWidth:"200px"}}>
                        <DialogContent style={{backgroundColor:"white"}}>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                    <span style={{fontWeight:700, fontSize:"16px"}}>
                                        Ajouter un réseau social
                                    </span>
                            </div>
                            <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap",marginTop:"30px"}}>
                                <div style={{display:"flex", marginTop:"10px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'instagram') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'instagram') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('instagram')}
                                            disabled={socialLinks.some((link) => link.network === 'instagram')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Instagram
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'facebook') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'facebook') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('facebook')}
                                            disabled={socialLinks.some((link) => link.network === 'facebook')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Facebook
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'tiktok') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'tiktok') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('tiktok')}
                                            disabled={socialLinks.some((link) => link.network === 'tiktok')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            TikTok
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'messenger') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'messenger') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('messenger')}
                                            disabled={socialLinks.some((link) => link.network === 'messenger')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Messenger
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'whatsapp') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'whatsapp') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('whatsapp')}
                                            disabled={socialLinks.some((link) => link.network === 'whatsapp')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Whatsapp
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'pinterest') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'pinterest') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('pinterest')}
                                            disabled={socialLinks.some((link) => link.network === 'pinterest')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Pinterest
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'snapchat') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'snapchat') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('snapchat')}
                                            disabled={socialLinks.some((link) => link.network === 'snapchat')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Snapchat
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'paypal') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'paypal') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('paypal')}
                                            disabled={socialLinks.some((link) => link.network === 'paypal')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Paypal
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'lydia') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'lydia') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('lydia')}
                                            disabled={socialLinks.some((link) => link.network === 'lydia')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Lydia
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'paylib') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'paylib') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('paylib')}
                                            disabled={socialLinks.some((link) => link.network === 'paylib')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Paylib
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'stripe') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'stripe') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('stripe')}
                                            disabled={socialLinks.some((link) => link.network === 'stripe')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Stripe
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'sumup') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'sumup') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('sumup')}
                                            disabled={socialLinks.some((link) => link.network === 'sumup')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            SumUp
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'wise') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'wise') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('wise')}
                                            disabled={socialLinks.some((link) => link.network === 'wise')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Wise
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'revolut') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'revolut') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('revolut')}
                                            disabled={socialLinks.some((link) => link.network === 'revolut')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Revolut
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'linktree') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'linktree') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('linktree')}
                                            disabled={socialLinks.some((link) => link.network === 'linktree')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Linktree
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'linkinbio') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'linkinbio') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('linkinbio')}
                                            disabled={socialLinks.some((link) => link.network === 'linkinbio')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Linkinbio
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'beacons') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'beacons') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('beacons')}
                                            disabled={socialLinks.some((link) => link.network === 'beacons')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Beacons
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'allmylinks') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'allmylinks') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('allmylinks')}
                                            disabled={socialLinks.some((link) => link.network === 'allmylinks')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            AllMyLinks
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'youtube') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'youtube') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('youtube')}
                                            disabled={socialLinks.some((link) => link.network === 'youtube')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Youtube
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'site') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'site') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('site')}
                                            disabled={socialLinks.some((link) => link.network === 'site')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Site internet
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'email') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'email') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('email')}
                                            disabled={socialLinks.some((link) => link.network === 'email')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Email
                                        </Typography>
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
            </div>
                <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />
            </div>

        </div>

    )
}

export default PostCreate;
