import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import {FaArrowLeft, FaArrowRight} from "react-icons/fa";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useLocation, useNavigate} from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/firestore'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import {getAgendaDoc, getPrestaDoc} from "../firebase";
import {useParams} from "react-router";
import SnackbarWithCustomBackground from "./snackbar";
import {Skeleton, styled, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import icLeftArrow from "../assets/icLeftArrow.svg";
import icRightArrow from "../assets/icRightArrow.svg";
import {HeaderHome} from "./headerHome";
import Switch, {SwitchProps} from "@mui/material/Switch";
import {convertUTCToTimeZone} from "./utils";
const monthMap = {
    "janvier": 0,
    "février": 1,
    "mars": 2,
    "avril": 3,
    "mai": 4,
    "juin": 5,
    "juillet": 6,
    "août": 7,
    "septembre": 8,
    "octobre": 9,
    "novembre": 10,
    "décembre": 11
};
// Function to format the date in the desired format
const dayNames = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
function formatDate(date) {
    const dayNames = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
    const monthNames = Object.keys(monthMap);
    return `${dayNames[date.getDay()]} ${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
}
function formatTime(input) {
    const date = new Date(input);
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    return `${hours}h${minutes}`;
}

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch size="medium" focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 63,
    height: 38,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 6,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(23px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                background: 'linear-gradient(90deg, #FF0844 0%, #4808FF 100%)',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 28, // Adjusted width (smaller)
        height: 28, // Adjusted height (smaller)
        transform: 'translateY(-4%)',
        //transform: 'translateY(50%)', // Adjust vertical position
    },
    '& .MuiSwitch-track': {
        borderRadius: 39 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

//todo use this function along some agenda variables to get start and end correct in jsx
function isWorkingHour(start, end, hour, checked) {
    // Helper function to convert a time string into minutes
    if (!checked) return false;
    function timeToMinutes(time) {
        const [hours, minutes] = time.split('h').map(Number);
        return hours * 60 + minutes;
    }

    const startMinutes = timeToMinutes(start);
    const endMinutes = timeToMinutes(end);
    const hourMinutes = timeToMinutes(hour);

    // Check if the hour is within the start included and end times excluded
    return hourMinutes >= startMinutes && hourMinutes < endMinutes;
}
export default function AppointDay() {
    const location = useLocation();
    const freeTimeSlotsFunction = firebase.functions().httpsCallable('getFreeTimeSlotsv2');
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const queryParams = new URLSearchParams(location.search);
    const choiceLoc = queryParams.get('choice');
    const monthLoc = queryParams.get('month');
    const yearLoc = queryParams.get('year');
    const dateString = choiceLoc + " " + monthLoc + " " + yearLoc;
    const dateComponents = dateString.split(" ");
    const day = parseInt(dateComponents[1]);
    const monthIndex = monthMap[dateComponents[2]];
    const year = parseInt(dateComponents[3]);
    const [currentDate, setCurrentDate] = useState(new Date(year, monthIndex, day));
    const [displayDate, setDisplayDate] = useState(formatDate(new Date(year, monthIndex, day)));
    const [showBackdrop, setShowBackdrop] = useState(true);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [isGoogle, setIsGoogle] = useState(false);
    const [dateItems, setDateItems] = useState([]);
    const [prestaDura, setPrestaDura] = useState(null);
    const [prestaBt, setPrestaBt] = useState(null);
    const [prestaObject, setPrestaObject] = useState(null);
    const [agendaObject, setAgendaObject] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [blockButtons, setBlockButtons] = useState(false);
    const [isAllSlots, setIsAllSlots] = useState(false);
    const [todayStart, setTodayStart] = useState(null);
    const [todayEnd, setTodayEnd] = useState(null);
    const [agendaDayChecked, setAgendaDayChecked] = useState(null);
    const [timezone, setTimezone] = useState("Europe/Paris")

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect( () => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged( async(user) => {
            setShowBackdrop(true);
            if (!user) {
                setShowBackdrop(false);
                navigate("/");
            } else {
                const fetchData = async () => {
                    //firebase.functions().useEmulator("127.0.0.1", 5001);
                    try {
                        /*const currentDate = new Date();
                        const nextDay = new Date(currentDate);
                        nextDay.setDate(currentDate.getDate() + 1);*/
                        setShowBackdrop(true);
                        const agendaData = await getAgendaDoc(user?.uid);
                        setAgendaObject(agendaData);
                        const isGoogleEnabled = agendaData.googleAGCode;
                        setIsGoogle(isGoogleEnabled)
                        const prestaData = await getPrestaDoc(id);
                        setPrestaObject(prestaData);
                        const prestaDuration = prestaData.prestaDuration;
                        const prestaBreak = prestaData.breakDuration;


                        const inputDayName = dayNames[currentDate.getDay()];
                        const dayDefinition = agendaData[`${inputDayName.toLowerCase()}Worked`];
                        setTodayStart(dayDefinition.start);
                        setTodayEnd(dayDefinition.end);
                        setAgendaDayChecked(dayDefinition.checked);
                        setPrestaDura(prestaDuration);
                        setPrestaBt(prestaBreak);
                        let skipDatabaseSearch = true;
                        for (let daystr of prestaData.selectedDays){
                            if (dayNames[currentDate.getDay()]===daystr){
                                skipDatabaseSearch=false;
                                break;
                            }
                        }
                        if (!skipDatabaseSearch){
                            // Get the local timezone offset in minutes, add it as abs - this is a workaround to ship fast
                            const offsetInMinutes = currentDate.getTimezoneOffset();
                            const offsetInMilliseconds = offsetInMinutes * 60 * 1000;
                            const adjustedDate = new Date(currentDate.getTime() + Math.abs(offsetInMilliseconds));
                            const result = await freeTimeSlotsFunction({ isGoogleSync: agendaData.googleAGCode,
                                prestaDuration: prestaDuration, prestaBreak : prestaBreak, inputDate: adjustedDate, isAllSlots:isAllSlots
                            });
                            const array = []

                            if (result.data.length>0){
                                for (let res of result.data){
                                    array.push(formatTime(res.start))
                                }

                            }
                            setDateItems(array);
                            setShowBackdrop(false);
                        } else {
                            setDateItems([]);
                            setShowBackdrop(false);
                        }
                    } catch (error) {
                        console.error('Error calling function:', error.message);
                        setShowBackdrop(false);
                    }
                };
                await fetchData();
                setShowBackdrop(false);
            }
            setShowBackdrop(false);
        });
        // Cleanup the observer when the component is unmounted
        return () => {
            unregisterAuthObserver();
        }
    }, []);

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const navigatePreviousDay = async () => {
        setBlockButtons(true);
        setShowBackdrop(true);
        const previousDay = new Date(currentDate);
        previousDay.setDate(currentDate.getDate() - 1);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (previousDay>today){
            setCurrentDate(previousDay);
            const newDateString = formatDate(previousDay);
            setDisplayDate(newDateString);

            //Skip db search if presta unavailable this day
            let skipDatabaseSearch = true;
            for (let daystr of prestaObject.selectedDays){
                if (dayNames[previousDay.getDay()]===daystr){
                    skipDatabaseSearch=false;
                    break;
                }
            }
            if (!isAllSlots  && skipDatabaseSearch){
                setDateItems([]);
                setShowBackdrop(false);
                setBlockButtons(false)
                return;
            }

            const inputDayName = dayNames[previousDay.getDay()];
            const dayDefinition = agendaObject[`${inputDayName.toLowerCase()}Worked`];
            setTodayStart(dayDefinition.start);
            setTodayEnd(dayDefinition.end);
            setAgendaDayChecked(dayDefinition.checked);

            const offsetInMinutes = previousDay.getTimezoneOffset();
            const offsetInMilliseconds = offsetInMinutes * 60 * 1000;
            const adjustedDate = new Date(previousDay.getTime() + Math.abs(offsetInMilliseconds));
            try {
                const result = await freeTimeSlotsFunction({ isGoogleSync: isGoogle,
                    prestaDuration: prestaDura, prestaBreak : prestaBt, inputDate: adjustedDate, isAllSlots:isAllSlots
                });
                const array = []
                if (result.data.length>0){
                    for (let res of result.data){
                        array.push(formatTime(res.start))
                    }
                }
                setDateItems(array);
            } catch (e) {
                console.log("Error ", e.message);
                handleOpenSnackbar("Quelque chose s'est mal passé, contactez nous. ", e.message);
            }
        } else {
            handleOpenSnackbar("Séléctionnez une date ultérieure pour continuer :)");
        }

        setShowBackdrop(false);
        setBlockButtons(false);
    }
    const navigateNextDay = async () => {
        setBlockButtons(true);
        setShowBackdrop(true);
        const nextDay = new Date(currentDate);
        nextDay.setDate(currentDate.getDate() + 1);
        setCurrentDate(nextDay);
        const newDateString = formatDate(nextDay);
        setDisplayDate(newDateString);

        //Skip db search if presta unavailable this day
        let skipDatabaseSearch = true;
        for (let daystr of prestaObject.selectedDays){
            if (dayNames[nextDay.getDay()]===daystr){
                skipDatabaseSearch=false;
                break;
            }
        }
        if (!isAllSlots  && skipDatabaseSearch){
            setDateItems([]);
            setShowBackdrop(false);
            setBlockButtons(false);
            return;
        }

        const inputDayName = dayNames[nextDay.getDay()];
        const dayDefinition = agendaObject[`${inputDayName.toLowerCase()}Worked`];
        setTodayStart(dayDefinition.start);
        setTodayEnd(dayDefinition.end);
        setAgendaDayChecked(dayDefinition.checked);
        const offsetInMinutes = nextDay.getTimezoneOffset();
        const offsetInMilliseconds = offsetInMinutes * 60 * 1000;
        const adjustedDate = new Date(nextDay.getTime() + Math.abs(offsetInMilliseconds));
        try {
            const result = await freeTimeSlotsFunction({ isGoogleSync: isGoogle,
                prestaDuration: prestaDura, prestaBreak : prestaBt, inputDate: adjustedDate, isAllSlots:isAllSlots
            });
            const array = []
            if (result.data.length>0){
                for (let res of result.data){
                    array.push(formatTime(res.start))
                }

            }
            setDateItems(array);
        } catch (e) {
            console.log(e.message);
            handleOpenSnackbar("Quelque chose s'est mal passé, contactez nous. ", e.message);
        }
        setBlockButtons(false);
        setShowBackdrop(false);
    }

    const handleCardClick = (index) => {
        setSelectedItem((prevSelected) => (prevSelected === index ? null : index));
    };

    const navigateBack = async () => {
        navigate("/appointDate/"+id);
    }

    const navigateHome = async () => {
        navigate("/home");
    }

    const navigateToFillData = async () => {
        if (selectedItem!=null){
            //navigate(`/appointday/${id}?choice=${choice}&month=${displayMonth}&year=${displayYear}`);
            //const prestaObjectStr = encodeURIComponent(JSON.stringify(prestaObject));
            //const agendaObjectStr = encodeURIComponent(JSON.stringify(agendaObject));
            //const selectedItemStr = encodeURIComponent(JSON.stringify(dateItems[selectedItem]));
            //const selectedDateStr = encodeURIComponent(JSON.stringify(displayDate));

            //navigate("/appointconfirm/"+id + `?prestaObject=${prestaObjectStr}&agendaObject=${agendaObjectStr}&selectedItem=${selectedItemStr}&date=${selectedDateStr}`,
            navigate("/appointconfirm/"+id,
                {
                    state:{
                        prestaObject: prestaObject,
                        agendaObject: agendaObject,
                        selectedItem: dateItems[selectedItem],
                        date: displayDate,
                        isAllSlots : isAllSlots
                    }
                })
        } else {
            handleOpenSnackbar('Vous devez séléctionner une date pour continuer.');
        }

    }

    const handleEnrichSlots = async () => {
        const isAllSlotsCurrentValue = isAllSlots;
        setIsAllSlots(!isAllSlotsCurrentValue);
        setBlockButtons(true);
        setShowBackdrop(true);
        const offsetInMinutes = currentDate.getTimezoneOffset();
        const offsetInMilliseconds = offsetInMinutes * 60 * 1000;
        const adjustedDate = new Date(currentDate.getTime() + Math.abs(offsetInMilliseconds));
        const result = await freeTimeSlotsFunction({ isGoogleSync: agendaObject.googleAGCode,
            prestaDuration: prestaDura, prestaBreak : prestaBt, inputDate: adjustedDate, isAllSlots: !isAllSlotsCurrentValue
        });
        const array = []

        if (result.data.length>0){
            for (let res of result.data){
                array.push(formatTime(res.start))
            }

        }
        setSelectedItem(null);
        setDateItems(array);
        setShowBackdrop(false);
        setBlockButtons(false);
    }

    if (!choiceLoc || !monthLoc || !yearLoc) {
        return (
            <div className="sub-container">
                <HeaderHome></HeaderHome>
                <div className="sub-container-no-top">
                    <div style={{display: "flex", width:"80%", padding: "20px", flexDirection: "column", justifyContent: "center", marginTop:"30px",
                        alignItems: "center", borderRadius: "15px", background: "#FFF", boxShadow:'0px 4px 15px 0px rgba(218, 224, 229, 0.90)'}}>
                                        <span style={{lineHeight: "24px"}}>
                                            Cette page n'existe pas ou a expirée 🤷
                                        </span>
                    </div>
                </div>
                <div className="button-container" style={{marginTop:"60px"}}>
                    <Button className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateBack}>
                        <Typography variant="button" style={{ textTransform: 'none' }}>
                            Retour
                        </Typography>
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            <div className="sub-container-no-top">
                <span className="title-style">Séléctionner une heure</span>
                <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", marginTop:"10px"}}>
                    <IconButton disabled={blockButtons} onClick={navigatePreviousDay}
                                variant="contained" style={{ color:"white", display:"flex"}}>
                        <img src={icLeftArrow} style={{width:"40px", height:"40px"}}/>
                    </IconButton>
                    <div style={{fontWeight:"700", display:"flex", alignContent:"center", alignItems:"center", justifyContent:"center", textAlign:"center"}}>
                        {displayDate}
                    </div>
                    <IconButton disabled={blockButtons} onClick={navigateNextDay}
                                variant="contained" style={{ color:"white", display:"flex"}}>
                        <img src={icRightArrow} style={{width:"40px", height:"40px"}}/>
                    </IconButton>
                </div>

                <div style={{flexDirection:"column", display:"flex", background:"#F9F3FF", borderRadius:"15px", marginTop:"20px", marginBottom:'10px', width:"100%"}}>
                    <div className="field-container" style={{marginTop:"16px", marginLeft:"16px", marginRight:"16px", width:'fit-content'}}>
                        <div className="title-field-container">
                                  <span className="text-field-style">
                                      Afficher tous les créneaux
                                  </span>
                        </div>
                        <div>
                            <span>Les créneaux hors de vos horaires et pendant vos pauses pourront être séléctionnés. Les créneaux déjà réservés sont exclus.</span>
                        </div>
                    </div>
                    <div style={{ marginTop:"10px" , marginBottom:"0px", width:"100%", display:"flex", flexDirection:"row", justifyContent:"start", alignItems:"center", margin:"16px"}}>

                        <IOSSwitch
                            checked={isAllSlots}
                            onChange={(event) => handleEnrichSlots(event)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />

                        {isAllSlots ? <span style={{marginLeft:"20px", color:"black"}}>Activé</span>
                            : <span style={{marginLeft:"20px", color:"black"}}>Desactivé</span>}

                    </div>
                </div>

                {showBackdrop && (
                    <div style={{display:"flex", width:"100%", flexDirection:"column",
                        justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                        <Skeleton style={{marginTop:"50px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                    </div>
                )}

                {/*<div style={{width:"100%", display:"flex", flexDirection:"column"}}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={showBackdrop}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>*/}

                <div className="date-layout">
                    { !showBackdrop && dateItems.map((date, index) => (
                        <div key={index}
                             className="date-card"
                             onClick={() => handleCardClick(index)}

                             style={{background:
                                     !isWorkingHour(convertUTCToTimeZone(todayStart, timezone), convertUTCToTimeZone(todayEnd, timezone), date, agendaDayChecked)
                                         ?  selectedItem === index ? "linear-gradient(100deg, #FF0844 0%, #4808FF 100%)" : "#F9F3FF" : selectedItem === index
                                         ? "linear-gradient(100deg, #FF0844 0%, #4808FF 100%)" : "white",
                                 color: selectedItem === index
                                     ? "white"
                                     : "black",
                                 cursor:"pointer"
                             }}
                        >
                            <div style={{margin:"20px"}}>
                                {date}
                            </div>
                        </div>
                    ))}
                </div>

                {dateItems.length===0 && !showBackdrop && (
                    <div className="date-card" style={{width:"100%"}}>
                        <div style={{margin:"20px"}}>
                            <span>Aucun créneau disponible ce jour</span>
                        </div>
                    </div>
                )}
                { !showBackdrop && (
                <div className="button-container">
                    <Button disabled={dateItems.length===0} onClick={navigateToFillData} className="button-style button-style-noborders" variant="contained">
                        <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                            Valider ce créneau
                        </Typography>
                    </Button>
                </div>
                )}
                { !showBackdrop && (
                <div className="button-container" style={{marginTop:"15px"}}>
                    <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateBack}>
                        <Typography variant="button" style={{ textTransform: 'none' }}>
                            Retour
                        </Typography>
                    </Button>
                </div>
                )}
                <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />
            </div>
        </div>
    )

}