import React, {useEffect, useState} from "react";
import {
    Accordion, AccordionDetails, AccordionSummary,
    Backdrop,
    CircularProgress, Dialog, DialogActions, DialogContent, Divider,
    Skeleton,
    styled, TextField,
    Typography, useMediaQuery, useTheme
} from "@mui/material";
import Switch, {SwitchProps} from "@mui/material/Switch";
import firebase from "firebase/compat/app";
import 'firebase/firestore'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import TimePickerWholeDay from "./timepickerwholeday";
import TimeBeforePrestaPicker from "./timebeforeprestapicker";
import IconButton from "@mui/material/IconButton";
import {useLocation, useNavigate} from "react-router-dom";
import gcal from '../assets/gcals.png'
import Button from "@mui/material/Button";
import SnackbarWithCustomBackground from "./snackbar";
import {serverTimestamp} from "@firebase/firestore";
import {createUnavSlot, deleteUnavSlot, getAgendaDoc, getUnavSlots, getUser, updateAgenda} from "../firebase";
import plusicon from "../assets/ph_plus-bold.png";
import trashlogo from "../assets/trashic.svg";
import {DateTime} from "luxon";
import {HeaderHome} from "./headerHome";
import TimeForwardPicker from "./timeForwardPicker";
import {FaAngleDown, FaArrowDown} from "react-icons/fa";
import {FaCaretDown} from "react-icons/fa6";
import AutocompleteAddressFrance from "./addressac";
import {DateTimeField, DateTimePicker, StaticDateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'; // for UTC time manipulation
import timezone from 'dayjs/plugin/timezone'; // for timezone handling
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {AiOutlineCaretDown} from "react-icons/ai";
import {BiCaretDown} from "react-icons/bi";
import googleButton from "../assets/googleButton.svg"; // if you need to parse custom formats

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.locale('fr');

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch size="medium" focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 63,
    height: 38,
    padding: 0,
    marginTop:"8px",
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 6,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(23px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                background: 'linear-gradient(90deg, #FF0844 0%, #4808FF 100%)',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 28, // Adjusted width (smaller)
        height: 28, // Adjusted height (smaller)
        transform: 'translateY(-4%)',
        //transform: 'translateY(50%)', // Adjust vertical position
    },
    '& .MuiSwitch-track': {
        borderRadius: 39 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

function everythingToTimezone(everything, timezone){
    const lundiStartTz = everything.lundiWorked.start.length>1 ?
        convertUTCToTimeZone(everything.lundiWorked.start, timezone) : "";
    const lundiEndTz = everything.lundiWorked.end.length>1 ?
        convertUTCToTimeZone(everything.lundiWorked.end, timezone) : "";
    let lundiBreaksTz = [];
    for (let brk of everything.lundiWorked.breaks){
        lundiBreaksTz.push({
            start : brk.start.length>1 ? convertUTCToTimeZone(brk.start, timezone) : "",
            end : brk.end.length>1 ? convertUTCToTimeZone(brk.end, timezone) : ""
        })
    }
    let lundiWorked = {
        checked : everything.lundiWorked.checked,
        start: lundiStartTz,
        end: lundiEndTz,
        breaks : lundiBreaksTz
    }

    const mardiStartTz = everything.mardiWorked.start.length>1 ?
        convertUTCToTimeZone(everything.mardiWorked.start, timezone) : "";
    const mardiEndTz = everything.mardiWorked.end.length>1 ?
        convertUTCToTimeZone(everything.mardiWorked.end, timezone) : "";
    let mardiBreaksTz = [];
    for (let brk of everything.mardiWorked.breaks){
        mardiBreaksTz.push({
            start : brk.start.length>1 ? convertUTCToTimeZone(brk.start, timezone) : "",
            end : brk.end.length>1 ? convertUTCToTimeZone(brk.end, timezone) : ""
        })
    }
    let mardiWorked = {
        checked : everything.mardiWorked.checked,
        start: mardiStartTz,
        end: mardiEndTz,
        breaks : mardiBreaksTz
    }

    const mercrediStartTz = everything.mercrediWorked.start.length>1 ?
        convertUTCToTimeZone(everything.mercrediWorked.start, timezone) : "";
    const mercrediEndTz = everything.mercrediWorked.end.length>1 ?
        convertUTCToTimeZone(everything.mercrediWorked.end, timezone) : "";
    let mercrediBreaksTz = [];
    for (let brk of everything.mercrediWorked.breaks){
        mercrediBreaksTz.push({
            start : brk.start.length>1 ? convertUTCToTimeZone(brk.start, timezone) : "",
            end : brk.end.length>1 ? convertUTCToTimeZone(brk.end, timezone) : ""
        })
    }
    let mercrediWorked = {
        checked : everything.mercrediWorked.checked,
        start: mercrediStartTz,
        end: mercrediEndTz,
        breaks : mercrediBreaksTz
    }

    const jeudiStartTz = everything.jeudiWorked.start.length>1 ?
        convertUTCToTimeZone(everything.jeudiWorked.start, timezone) : "";
    const jeudiEndTz = everything.jeudiWorked.end.length>1 ?
        convertUTCToTimeZone(everything.jeudiWorked.end, timezone) : "";
    let jeudiBreaksTz = [];
    for (let brk of everything.jeudiWorked.breaks){
        jeudiBreaksTz.push({
            start : brk.start.length>1 ? convertUTCToTimeZone(brk.start, timezone) : "",
            end : brk.end.length>1 ? convertUTCToTimeZone(brk.end, timezone) : ""
        })
    }
    let jeudiWorked = {
        checked : everything.jeudiWorked.checked,
        start: jeudiStartTz,
        end: jeudiEndTz,
        breaks : jeudiBreaksTz
    }

    const vendrediStartTz = everything.vendrediWorked.start.length>1 ?
        convertUTCToTimeZone(everything.vendrediWorked.start, timezone) : "";
    const vendrediEndTz = everything.vendrediWorked.end.length>1 ?
        convertUTCToTimeZone(everything.vendrediWorked.end, timezone) : "";
    let vendrediBreaksTz = [];
    for (let brk of everything.vendrediWorked.breaks){
        vendrediBreaksTz.push({
            start : brk.start.length>1 ? convertUTCToTimeZone(brk.start, timezone) : "",
            end : brk.end.length>1 ? convertUTCToTimeZone(brk.end, timezone) : ""
        })
    }
    let vendrediWorked = {
        checked : everything.vendrediWorked.checked,
        start: vendrediStartTz,
        end: vendrediEndTz,
        breaks : vendrediBreaksTz
    }


    const samediStartTz = everything.samediWorked.start.length>1 ?
        convertUTCToTimeZone(everything.samediWorked.start, timezone) : "";
    const samediEndTz = everything.samediWorked.end.length>1 ?
        convertUTCToTimeZone(everything.samediWorked.end, timezone) : "";
    let samediBreaksTz = [];
    for (let brk of everything.samediWorked.breaks){
        samediBreaksTz.push({
            start : brk.start.length>1 ? convertUTCToTimeZone(brk.start, timezone) : "",
            end : brk.end.length>1 ? convertUTCToTimeZone(brk.end, timezone) : ""
        })
    }
    let samediWorked = {
        checked : everything.samediWorked.checked,
        start: samediStartTz,
        end: samediEndTz,
        breaks : samediBreaksTz
    }

    const dimancheStartTz = everything.dimancheWorked.start.length>1 ?
        convertUTCToTimeZone(everything.dimancheWorked.start, timezone) : "";
    const dimancheEndTz = everything.dimancheWorked.end.length>1 ?
        convertUTCToTimeZone(everything.dimancheWorked.end, timezone) : "";
    let dimancheBreaksTz = [];
    for (let brk of everything.dimancheWorked.breaks){
        dimancheBreaksTz.push({
            start : brk.start.length>1 ? convertUTCToTimeZone(brk.start, timezone) : "",
            end : brk.end.length>1 ? convertUTCToTimeZone(brk.end, timezone) : ""
        })
    }
    let dimancheWorked = {
        checked : everything.dimancheWorked.checked,
        start: dimancheStartTz,
        end: dimancheEndTz,
        breaks : dimancheBreaksTz
    }

    return [lundiWorked, mardiWorked, mercrediWorked, jeudiWorked, vendrediWorked, samediWorked, dimancheWorked];
}

function everythingToUtc(lundi, mardi, mercredi, jeudi, vendredi, samedi, dimanche, timezone){
    const lundiStartTz = lundi.start.length>1 ?
        convertTimeToUTC(lundi.start, timezone) : "";
    const lundiEndTz = lundi.end.length>1 ?
        convertTimeToUTC(lundi.end, timezone) : "";
    let lundiBreaksTz = [];
    for (let brk of lundi.breaks){
        lundiBreaksTz.push({
            start : brk.start.length>1 ? convertTimeToUTC(brk.start, timezone) : "",
            end : brk.end.length>1 ? convertTimeToUTC(brk.end, timezone) : ""
        })
    }
    let lundiWorked = {
        checked : lundi.checked,
        start: lundiStartTz,
        end: lundiEndTz,
        breaks : lundiBreaksTz
    }

    const mardiStartTz = mardi.start.length>1 ?
        convertTimeToUTC(mardi.start, timezone) : "";
    const mardiEndTz = mardi.end.length>1 ?
        convertTimeToUTC(mardi.end, timezone) : "";
    let mardiBreaksTz = [];
    for (let brk of mardi.breaks){
        mardiBreaksTz.push({
            start : brk.start.length>1 ? convertTimeToUTC(brk.start, timezone) : "",
            end : brk.end.length>1 ? convertTimeToUTC(brk.end, timezone) : ""
        })
    }
    let mardiWorked = {
        checked : mardi.checked,
        start: mardiStartTz,
        end: mardiEndTz,
        breaks : mardiBreaksTz
    }

    const mercrediStartTz = mercredi.start.length>1 ?
        convertTimeToUTC(mercredi.start, timezone) : "";
    const mercrediEndTz = mercredi.end.length>1 ?
        convertTimeToUTC(mercredi.end, timezone) : "";
    let mercrediBreaksTz = [];
    for (let brk of mercredi.breaks){
        mercrediBreaksTz.push({
            start : brk.start.length>1 ? convertTimeToUTC(brk.start, timezone) : "",
            end : brk.end.length>1 ? convertTimeToUTC(brk.end, timezone) : ""
        })
    }
    let mercrediWorked = {
        checked : mercredi.checked,
        start: mercrediStartTz,
        end: mercrediEndTz,
        breaks : mercrediBreaksTz
    }

    const jeudiStartTz = jeudi.start.length>1 ?
        convertTimeToUTC(jeudi.start, timezone) : "";
    const jeudiEndTz = jeudi.end.length>1 ?
        convertTimeToUTC(jeudi.end, timezone) : "";
    let jeudiBreaksTz = [];
    for (let brk of jeudi.breaks){
        jeudiBreaksTz.push({
            start : brk.start.length>1 ? convertTimeToUTC(brk.start, timezone) : "",
            end : brk.end.length>1 ? convertTimeToUTC(brk.end, timezone) : ""
        })
    }
    let jeudiWorked = {
        checked : jeudi.checked,
        start: jeudiStartTz,
        end: jeudiEndTz,
        breaks : jeudiBreaksTz
    }

    const vendrediStartTz = vendredi.start.length>1 ?
        convertTimeToUTC(vendredi.start, timezone) : "";
    const vendrediEndTz = vendredi.end.length>1 ?
        convertTimeToUTC(vendredi.end, timezone) : "";
    let vendrediBreaksTz = [];
    for (let brk of vendredi.breaks){
        vendrediBreaksTz.push({
            start : brk.start.length>1 ? convertTimeToUTC(brk.start, timezone) : "",
            end : brk.end.length>1 ? convertTimeToUTC(brk.end, timezone) : ""
        })
    }
    let vendrediWorked = {
        checked : vendredi.checked,
        start: vendrediStartTz,
        end: vendrediEndTz,
        breaks : vendrediBreaksTz
    }


    const samediStartTz = samedi.start.length>1 ?
        convertTimeToUTC(samedi.start, timezone) : "";
    const samediEndTz = samedi.end.length>1 ?
        convertTimeToUTC(samedi.end, timezone) : "";
    let samediBreaksTz = [];
    for (let brk of samedi.breaks){
        samediBreaksTz.push({
            start : brk.start.length>1 ? convertTimeToUTC(brk.start, timezone) : "",
            end : brk.end.length>1 ? convertTimeToUTC(brk.end, timezone) : ""
        })
    }
    let samediWorked = {
        checked : samedi.checked,
        start: samediStartTz,
        end: samediEndTz,
        breaks : samediBreaksTz
    }

    const dimancheStartTz = dimanche.start.length>1 ?
        convertTimeToUTC(dimanche.start, timezone) : "";
    const dimancheEndTz = dimanche.end.length>1 ?
        convertTimeToUTC(dimanche.end, timezone) : "";
    let dimancheBreaksTz = [];
    for (let brk of dimanche.breaks){
        dimancheBreaksTz.push({
            start : brk.start.length>1 ? convertTimeToUTC(brk.start, timezone) : "",
            end : brk.end.length>1 ? convertTimeToUTC(brk.end, timezone) : ""
        })
    }
    let dimancheWorked = {
        checked : dimanche.checked,
        start: dimancheStartTz,
        end: dimancheEndTz,
        breaks : dimancheBreaksTz
    }

    return [lundiWorked, mardiWorked, mercrediWorked, jeudiWorked, vendrediWorked, samediWorked, dimancheWorked];
}
function convertUTCToTimeZone(utcTime, timeZone) {
    utcTime = utcTime.replace('h', ':');
    // Parse the UTC time
    const utcDateTime = DateTime.fromFormat(utcTime, 'HH:mm', { zone: 'utc' });
    // Set the desired time zone
    const localTime = utcDateTime.setZone(timeZone);
    // Format the local time as 'HH:mm'
    let formattedLocalTime = localTime.toFormat('HH:mm');
    formattedLocalTime = formattedLocalTime.replace(':', 'h')
    return formattedLocalTime;
}

function convertTimeToUTC(inputTime, timeZone) {
    inputTime = inputTime.replace('h', ':')
    // Parse the input time in the given time zone
    const localTime = DateTime.fromFormat(inputTime, 'HH:mm', { zone: timeZone });
    // Convert it to UTC
    const utcTime = localTime.toUTC();
    // Format the UTC time as 'HH:mm'
    let formattedUTCTime = utcTime.toFormat('HH:mm');
    formattedUTCTime = formattedUTCTime.replace(':', 'h')
    return formattedUTCTime;
}
export default function AgendaParam() {

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const location = useLocation();
    const stateData = location?.state || false;
    const [isPostCreate, setIsPostCreate] = useState(stateData?.isPostCreate || false);
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        setShowSkeleton(true);
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged( async(user) => {
            if (!user) {
                setShowSkeleton(false);
                setShowBackdrop(false);
                navigate("/");
            } else {

                const userData = await getUser(user?.uid);
                const unavSlotsData = await getUnavSlots(user?.uid);
                const unavSlotsConverted = unavSlotsData.map(slot => ({
                    ...slot, // spread the rest of the object's properties
                    start: new Date(slot.start.seconds * 1000),
                    end: new Date(slot.end.seconds * 1000),
                    timestamp : new Date(slot.timestamp.seconds * 1000)
                }));
                setUnavRanges(unavSlotsConverted);
                setInitUnavRanges(unavSlotsConverted);
                if (userData?.timezone){
                    setTimeZone(userData.timezone);
                }
                const url = new URL(window.location.href);
                const agauthorizationCode = url.searchParams.get('code');
                const authorizationError = url.searchParams.get('error');
                const agData = JSON.parse(localStorage.getItem('currentState')) || {};
                if (agauthorizationCode && agData?.lundiW) {
                    //restore state - user got back successfully
                    try {
                        const user = firebase.auth().currentUser;
                        if (user) {
                            const callerUrl = window.location.href;
                            const exchangeAuthorizationCode = firebase.functions().httpsCallable('exchangeAuthorizationCode');
                            await exchangeAuthorizationCode({ authorizationCode: agauthorizationCode, caller:callerUrl.split("?")[0]});
                            setGoogleAGCode(true);
                            handleOpenSnackbar("Cliquez sur enregistrer pour finaliser la synchronisation de votre agenda Google.");
                        } else {
                            setGoogleAGCode(false);
                        }
                    } catch (error) {
                        console.error('Error calling exchangeAuthorizationCode:', error);
                        setGoogleAGCode(false);
                    }
                    setLundiWorked(agData.lundiW);
                    setMardiWorked(agData.mardiW);
                    setMercrediWorked(agData.mercrediW);
                    setJeudiWorked(agData.jeudiW);
                    setVendrediWorked(agData.vendrediW);
                    setSamediWorked(agData.samediW);
                    setDimancheWorked(agData.dimancheW);
                    setTimeBeforePresta(agData.timeBeforePresta);
                    setTimeForward(agData.timeForward);
                    setTimeForwardChecked(agData.timeForwardChecked);
                    setShowBackdrop(false);
                    setShowSkeleton(false);
                } else if (authorizationError && agData?.lundiW) {
                    console.log("error => ", authorizationError);
                    //restore state - user got back with error
                    setLundiWorked(agData.lundiW);
                    setMardiWorked(agData.mardiW);
                    setMercrediWorked(agData.mercrediW);
                    setJeudiWorked(agData.jeudiW);
                    setVendrediWorked(agData.vendrediW);
                    setSamediWorked(agData.samediW);
                    setDimancheWorked(agData.dimancheW);
                    setTimeBeforePresta(agData.timeBeforePresta);
                    setTimeForward(agData.timeForward);
                    setTimeForwardChecked(agData.timeForwardChecked);
                    setShowBackdrop(false);
                    setShowSkeleton(false);
                } else if (agData?.lundiW) {
                    //There is a state so let's restore it
                    setLundiWorked(agData.lundiW);
                    setMardiWorked(agData.mardiW);
                    setMercrediWorked(agData.mercrediW);
                    setJeudiWorked(agData.jeudiW);
                    setVendrediWorked(agData.vendrediW);
                    setSamediWorked(agData.samediW);
                    setDimancheWorked(agData.dimancheW);
                    setTimeBeforePresta(agData.timeBeforePresta);
                    setTimeForward(agData.timeForward);
                    setTimeForwardChecked(agData.timeForwardChecked);
                    setShowBackdrop(false);
                    setShowSkeleton(false);
                } else {
                    //Fetch data from DB if any, init state, convert to user TZ
                    async function fetchAgendaData() {
                        try {
                            const agendaData = await getAgendaDoc(user?.uid);
                            if (agendaData!=null){
                                const [lundiTz, mardiTz, mercrediTz, jeudiTz, vendrediTz, samediTz, dimancheTz] = everythingToTimezone(agendaData, userData.timezone);
                                setLundiWorked(lundiTz || "");
                                setMardiWorked(mardiTz || "");
                                setMercrediWorked(mercrediTz || "");
                                setJeudiWorked(jeudiTz || "");
                                setVendrediWorked(vendrediTz || "");
                                setSamediWorked(samediTz || "");
                                setDimancheWorked(dimancheTz || "");
                                setTimeBeforePresta(agendaData?.timeBeforePresta || "");
                                setTimeForward(agendaData?.timeForward || 30);
                                setTimeForwardChecked(agendaData?.timeForwardChecked || false);
                                setGoogleAGCode(agendaData?.googleAGCode || false);
                            }
                        } catch (e){
                            handleOpenSnackbar("Ooops quelque chose s'est mal passé, contactez nous et communiquez cette erreur => ", e);
                            throw new Error("Error while loading data");
                        }
                        setShowSkeleton(false);
                    }
                    await fetchAgendaData();
                }
            }

        });

        return () => {
            unregisterAuthObserver();
        }
    }, []);


    //separate objects per day for future enrichment use cases -
    const [lundiWorked, setLundiWorked] = useState(
            {
                checked : true,
                start : "",
                end : "",
                breaks : [
                ]
            }
        )
    const [mardiWorked, setMardiWorked] = useState({
            checked: true,
            start : "",
            end : "",
            breaks : [
            ]
        }
    )
    const [mercrediWorked, setMercrediWorked] = useState({
            checked: true,
            start : "",
            end : "",
            breaks : [
            ]
        }
    )
    const [jeudiWorked, setJeudiWorked] = useState({
            checked: true,
            start : "",
            end : "",
            breaks : [
            ]
        }
    )
    const [vendrediWorked, setVendrediWorked] = useState({
            checked: true,
            start : "",
            end : "",
            breaks : [
            ]
        }
    )
    const [samediWorked, setSamediWorked] = useState({
            checked: false,
            start : "",
            end : "",
            breaks : [
            ]
        }
    )
    const [dimancheWorked, setDimancheWorked] = useState({
            checked: false,
            start : "",
            end : "",
            breaks : [
            ]
        }
    )

    const [timeForward, setTimeForward] = useState(15);
    const [timeForwardChecked, setTimeForwardChecked] = useState(false);
    const [timeBeforePresta, setTimeBeforePresta] = useState("1j");
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(true);

    const [isTimeDialogOpen, setIsTimeDialogOpen] = useState(false);
    const [endPeriod, setEndPeriod] = useState(dayjs().add(20,'day'));
    const [beginPeriod, setBeginPeriod] = useState(dayjs().add(10,'day'));
    const [expanded, setExpanded] = useState(true);
    const [timezone, setTimeZone] = useState('Europe/Paris');
    const [unavRanges, setUnavRanges] = useState([]);
    const [initUnavRanges, setInitUnavRanges] = useState([]);
    const [currentDate, setCurrentDate] = useState(dayjs());

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };
    const [googleAGCode, setGoogleAGCode] = useState(false);
    const handleBreakChange = (index, property, value, day) => {
        if (day==="Lundi"){
            const updatedBreaks = [...lundiWorked.breaks];
            updatedBreaks[index][property] = value;

            setLundiWorked((prevLundi) => ({
                ...prevLundi,
                breaks: updatedBreaks,
            }));
        } else if (day==="Mardi"){
            const updatedBreaks = [...mardiWorked.breaks];
            updatedBreaks[index][property] = value;
            setMardiWorked((prevMardi) => ({
                ...prevMardi,
                breaks: updatedBreaks,
            }));
        } else if (day==="Mercredi"){
            const updatedBreaks = [...mercrediWorked.breaks];
            updatedBreaks[index][property] = value;
            setMercrediWorked((prevMercredi) => ({
                ...prevMercredi,
                breaks: updatedBreaks,
            }));
        } else if (day==="Jeudi"){
            const updatedBreaks = [...jeudiWorked.breaks];
            updatedBreaks[index][property] = value;
            setJeudiWorked((prevJeudi) => ({
                ...prevJeudi,
                breaks: updatedBreaks,
            }));
        } else if (day==="Vendredi"){
            const updatedBreaks = [...vendrediWorked.breaks];
            updatedBreaks[index][property] = value;
            setVendrediWorked((prevVendredi) => ({
                ...prevVendredi,
                breaks: updatedBreaks,
            }));
        } else if (day==="Samedi"){
            const updatedBreaks = [...samediWorked.breaks];
            updatedBreaks[index][property] = value;
            setSamediWorked((prevSamedi) => ({
                ...prevSamedi,
                breaks: updatedBreaks,
            }));
        } else if (day==="Dimanche"){
            const updatedBreaks = [...dimancheWorked.breaks];
            updatedBreaks[index][property] = value;
            setDimancheWorked((prevDimanche) => ({
                ...prevDimanche,
                breaks: updatedBreaks,
            }));
        }
    };

    const addBreak = (day) => {
        if (day==="Lundi"){
            setLundiWorked((prevLundi) => ({
                ...prevLundi,
                breaks: [
                    ...prevLundi.breaks,
                    { start: "", end: "" },
                ],
            }));
        } else if (day==="Mardi"){
            setMardiWorked((prevMardi) => ({
                ...prevMardi,
                breaks: [
                    ...prevMardi.breaks,
                    { start: "12h00", end: "13h30" },
                ],
            }));
        } else if (day==="Mercredi"){
            setMercrediWorked((prevMercredi) => ({
                ...prevMercredi,
                breaks: [
                    ...prevMercredi.breaks,
                    { start: "12h00", end: "13h30" },
                ],
            }));
        } else if (day==="Jeudi"){
            setJeudiWorked((prevJeudi) => ({
                ...prevJeudi,
                breaks: [
                    ...prevJeudi.breaks,
                    { start: "12h00", end: "13h30" },
                ],
            }));
        } else if (day==="Vendredi"){
            setVendrediWorked((prevVendredi) => ({
                ...prevVendredi,
                breaks: [
                    ...prevVendredi.breaks,
                    { start: "12h00", end: "13h30" },
                ],
            }));
        } else if (day==="Samedi"){
            setSamediWorked((prevSamedi) => ({
                ...prevSamedi,
                breaks: [
                    ...prevSamedi.breaks,
                    { start: "12h00", end: "13h30" },
                ],
            }));
        } else if (day==="Dimanche"){
            setDimancheWorked((prevDimanche) => ({
                ...prevDimanche,
                breaks: [
                    ...prevDimanche.breaks,
                    { start: "12h00", end: "13h30" },
                ],
            }));
        }

    };

    const removeBreak = (index, day) => {
        if (day==="Lundi"){
            const updatedBreaks = lundiWorked.breaks.filter((_, i) => i !== index);
            setLundiWorked((prevLundi) => ({
                ...prevLundi,
                breaks: updatedBreaks,
            }));
        } else if (day==="Mardi"){
            const updatedBreaks = mardiWorked.breaks.filter((_, i) => i !== index);
            setMardiWorked((prevMardi) => ({
                ...prevMardi,
                breaks: updatedBreaks,
            }));
        } else if (day==="Mercredi"){
            const updatedBreaks = mercrediWorked.breaks.filter((_, i) => i !== index);
            setMercrediWorked((prevMercredi) => ({
                ...prevMercredi,
                breaks: updatedBreaks,
            }));
        } else if (day==="Jeudi"){
            const updatedBreaks = jeudiWorked.breaks.filter((_, i) => i !== index);
            setJeudiWorked((prevJeudi) => ({
                ...prevJeudi,
                breaks: updatedBreaks,
            }));
        } else if (day==="Vendredi"){
            const updatedBreaks = vendrediWorked.breaks.filter((_, i) => i !== index);
            setVendrediWorked((prevVendredi) => ({
                ...prevVendredi,
                breaks: updatedBreaks,
            }));
        } else if (day==="Samedi"){
            const updatedBreaks = samediWorked.breaks.filter((_, i) => i !== index);
            setSamediWorked((prevSamedi) => ({
                ...prevSamedi,
                breaks: updatedBreaks,
            }));
        } else if (day==="Dimanche"){
            const updatedBreaks = dimancheWorked.breaks.filter((_, i) => i !== index);
            setDimancheWorked((prevDimanche) => ({
                ...prevDimanche,
                breaks: updatedBreaks,
            }));
        }

    };

    const handleSyncGA = async () => {
        setShowBackdrop(true);
        if (!googleAGCode){
            const currentState = {
                lundiW:lundiWorked,
                mardiW: mardiWorked,
                mercrediW: mercrediWorked,
                jeudiW: jeudiWorked,
                vendrediW:vendrediWorked,
                samediW:samediWorked,
                dimancheW:dimancheWorked,
                timeBeforePresta:timeBeforePresta,
                timeForward:timeForward,
                timeForwardChecked:timeForwardChecked
            }
            //updateAgData(currentState);
            localStorage.setItem('currentState', JSON.stringify(currentState));
            try {
                const initiateOAuth = firebase.functions().httpsCallable('initiateOAuth');
                const callerUrl = window.location.href;
                const response = await initiateOAuth({caller : callerUrl});
                setShowBackdrop(false);
                window.location.href = response.data.authUrl; // Redirect to Google OAuth consent screen
            } catch (error) {
                console.error('Error:', error);
                setShowBackdrop(false);
            }
        } else {
            try {
                await updateAgenda({
                    googleAGCode : false,
                },firebase.auth().currentUser.uid);
                setGoogleAGCode(false);
                setShowBackdrop(false);
                handleOpenSnackbar("Votre agenda Google n'est plus synchronisé");
            } catch (error) {
                console.error('Error:', error);
                setShowBackdrop(false);
                setShowBackdrop(false);
            }
        }
        setShowBackdrop(false);
    };

    //TODO handle submit event
    //Handle reading event
    //Check no bug on no event
    //Handle unsync google

    const navigateBack = async () => {
        navigate("/home",{
            state:{
                isPostCreate:isPostCreate
            }
        });
    }

    const handleSubmitData = async () => {
        //Check start end is defined

        setShowBackdrop(true);

        if (lundiWorked.checked && (lundiWorked.start.length<3 || lundiWorked.end.length<3)){
            handleOpenSnackbar("Veuillez définir une heure de début et de fin pour lundi");
            setShowBackdrop(false);
            return;
        }
        if (mardiWorked.checked && (mardiWorked.start.length<3 || mardiWorked.end.length<3)){
            handleOpenSnackbar("Veuillez définir une heure de début et de fin pour mardi");
            setShowBackdrop(false);
            return;
        }
        if (mercrediWorked.checked && (mercrediWorked.start.length<3 || mercrediWorked.end.length<3)){
            handleOpenSnackbar("Veuillez définir une heure de début et de fin pour mercredi");
            setShowBackdrop(false);
            return;
        }
        if (jeudiWorked.checked && (jeudiWorked.start.length<3 || jeudiWorked.end.length<3)){
            handleOpenSnackbar("Veuillez définir une heure de début et de fin pour jeudi");
            return;
        }
        if (vendrediWorked.checked && (vendrediWorked.start.length<3 || vendrediWorked.end.length<3)){
            handleOpenSnackbar("Veuillez définir une heure de début et de fin pour vendredi");
            setShowBackdrop(false);
            return;
        }
        if (samediWorked.checked && (samediWorked.start.length<3 || samediWorked.end.length<3)){
            handleOpenSnackbar("Veuillez définir une heure de début et de fin pour samedi");
            setShowBackdrop(false);
            return;
        }
        if (dimancheWorked.checked && (dimancheWorked.start.length<3 || dimancheWorked.end.length<3)){
            handleOpenSnackbar("Veuillez définir une heure de début et de fin pour dimanche");
            setShowBackdrop(false);
            return;
        }

        for (let brk of lundiWorked.breaks){
            if (brk.start?.length<3 || brk.end?.length<3){
                handleOpenSnackbar("Veuillez définir une heure de début et de fin pour les pauses de lundi");
                setShowBackdrop(false);
                return;
            }
        }

        for (let brk of mardiWorked.breaks){
            if (brk.start===null || brk.end===null || brk.start?.length<3 || brk.end?.length<3){
                handleOpenSnackbar("Veuillez définir une heure de début et de fin pour les pauses de mardi");
                setShowBackdrop(false);
                return;
            }
        }

        for (let brk of mercrediWorked.breaks){
            if (brk.start?.length<3 || brk.end?.length<3){
                handleOpenSnackbar("Veuillez définir une heure de début et de fin pour les pauses de mercredi");
                setShowBackdrop(false);
                return;
            }
        }

        for (let brk of jeudiWorked.breaks){
            if (brk.start?.length<3 || brk.end?.length<3){
                handleOpenSnackbar("Veuillez définir une heure de début et de fin pour les pauses de jeudi");
                setShowBackdrop(false);
                return;
            }
        }

        for (let brk of vendrediWorked.breaks){
            if (brk.start?.length<3 || brk.end?.length<3){
                handleOpenSnackbar("Veuillez définir une heure de début et de fin pour les pauses de vendredi");
                setShowBackdrop(false);
                return;
            }
        }

        for (let brk of samediWorked.breaks){
            if (brk.start?.length<3 || brk.end?.length<3){
                handleOpenSnackbar("Veuillez définir une heure de début et de fin pour les pauses de samedi");
                setShowBackdrop(false);
                return;
            }
        }

        for (let brk of dimancheWorked.breaks){
            if (brk.start?.length<3 || brk.end?.length<3){
                handleOpenSnackbar("Veuillez définir une heure de début et de fin pour les pauses de dimanche");
                setShowBackdrop(false);
                return;
            }
        }

        const [lundi, mardi, mercredi, jeudi, vendredi, samedi, dimanche]
            = everythingToUtc(lundiWorked, mardiWorked, mercrediWorked, jeudiWorked, vendrediWorked, samediWorked, dimancheWorked, timezone);

        let dbObject = {
            lundiWorked : lundi,
            mardiWorked: mardi,
            mercrediWorked:mercredi,
            jeudiWorked: jeudi,
            vendrediWorked:vendredi,
            samediWorked:samedi,
            dimancheWorked:dimanche,
            timeBeforePresta:timeBeforePresta,
            timeForwardChecked:timeForwardChecked,
            timeForward:timeForward,
            googleAGCode : googleAGCode,
            timestamp : serverTimestamp(),
            uid : firebase.auth().currentUser.uid,
        }

        try {
            await updateAgenda(dbObject, firebase.auth().currentUser.uid);
        } catch (e) {
            console.log("error when uploading data to db => ", e);
            setShowBackdrop(false);
            handleOpenSnackbar("Quelques chose s'est mal passé lors du chargement de votre prestation, réessayez ou contactez nous ", e.message);
            return;
        }

        const addUnav = unavRanges.filter(item => !initUnavRanges.includes(item));
        const delUnav = initUnavRanges.filter(item => !unavRanges.includes(item));

        let addPromises = addUnav.map(addObj => createUnavSlot(addObj)); // use map to create promises for adding
        let remPromises = delUnav
            .filter(remObj => remObj.hasOwnProperty('documentId')) // Filter objects having 'documentId'
            .map(remObj => deleteUnavSlot(remObj.documentId));

        try {
            await Promise.all(addPromises);
            await Promise.all(remPromises);
        } catch (error) {
            setShowBackdrop(false);
            console.error('Error updating unavailability:', error);
            handleOpenSnackbar("Quelques chose s'est mal passé lors du chargement de votre prestation, réessayez ou contactez nous ", error.message);
            return;
        }

        setShowBackdrop(false);
        localStorage.removeItem('currentState');
        navigateBack();
    };

    const handleTimeCloseOutside = () => {

        setIsTimeDialogOpen(false);
    };

    const handleTimeDialogClose = () => {
        if (!endPeriod?.isValid() || !beginPeriod?.isValid()){
            handleOpenSnackbar("Vérifiez vos dates, celles-ci ne semblent pas valides");
            return;
        }
        if (endPeriod<=beginPeriod){
            handleOpenSnackbar('La date de fin ne peut pas être avant la date de début, vérifiez vos dates');
            return;
        }

        let nativeEndPeriod = endPeriod.toDate();
        let nativeStartPeriod = beginPeriod.toDate();

        setUnavRanges(prevItems => [...prevItems, {
            start:nativeStartPeriod,
            end:nativeEndPeriod,
            timestamp: new Date(),
            uid : firebase.auth().currentUser.uid
        }]);

        setIsTimeDialogOpen(false);

    };

    // Function to handle the accordion's change event
    const handleAccChange = () => {
        setExpanded(!expanded); // Toggle the state between true and false
    };

    const handleRemRange = (index) => {
        setUnavRanges((currentItems) =>
            currentItems.filter((item, currentIndex) => currentIndex !== index)
        );
    };


    if (showBackdrop) {
        return <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showBackdrop}
        >
            <CircularProgress color="inherit" />
        </Backdrop>;
    }





    //TODO Choose a strategy to align time -> HHhMMm or HH:MM or HHhMM
    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            {showSkeleton ?
                (
                    <div className="sub-container-no-top">
                        <div style={{display:"flex", width:"100%", flexDirection:"column",
                            justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                            <Skeleton style={{borderRadius:"15px"}} variant="text" sx={{ fontSize: '2rem' }} width="70%" />
                            <Skeleton style={{marginTop:"50px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                            <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                            <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        </div>
                    </div>
                ) : (
            <div className="sub-container-no-top">
                {isPostCreate ? <span className="title-style">Paramétrez votre agenda</span> : <span className="title-style">Paramètre de l'agenda</span>}
                <div className="field-container" >
                    <div className="title-field-container">
                                      <span className="text-field-style">
                                          Heures de travail
                                      </span>
                    </div>
                </div>


                <div style={{width:"100%", flexDirection:"column"}}>
                    <div style={{marginTop:"2px"}}>
                        <span style={{fontWeight:400}}>
                            Lundi
                        </span>
                    </div>
                    <div style={{width: "100%", flexDirection:"row", display:"flex", alignContent:"top", alignItems:"top", marginTop:"10px"}}>

                        <IOSSwitch
                            checked={lundiWorked.checked}
                            onChange={() => setLundiWorked(prevState => ({ ...prevState, checked: !prevState.checked }))}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        {
                            lundiWorked.checked ? (
                                    <div style={{width: "100%", flexDirection:"row", display:"flex", alignContent:"top", alignItems:"top"}}>
                                        <div style={{width:"40%", display:"flex", alignSelf:"start", marginLeft:"12px"}}>
                                            <TimePickerWholeDay selectedTime={lundiWorked.start}
                                                                disabled={lundiWorked.checked===false}
                                                                selectedOtherValue={lundiWorked.end}
                                                                timezone={timezone}
                                                                isStart={false}
                                                                onTimeSelected={(time) => setLundiWorked(prevState => ({ ...prevState, start: time}))}
                                            >
                                            </TimePickerWholeDay>
                                        </div>
                                        <span style={{marginLeft:"12px", marginTop:"20px"}}>à</span>
                                        <div style={{width:"40%", display:"flex", alignSelf:"start", marginLeft:"12px"}}>
                                            <TimePickerWholeDay selectedTime={lundiWorked.end}
                                                                disabled={lundiWorked.checked===false}
                                                                isStart={true}
                                                                timezone={timezone}
                                                                selectedOtherValue={lundiWorked.start}
                                                                onTimeSelected={(time) => setLundiWorked(prevState => ({ ...prevState, end: time}))}
                                            >
                                            </TimePickerWholeDay>
                                        </div>
                                    </div>
                            ) : (
                                <span style={{marginLeft:"20px", color:"black", marginTop:"16px"}}>Ne travaille pas</span>
                                )
                        }
                    </div>
                    <div style={{marginTop:"10px"}}>
                        <span style={{fontWeight:400}}>
                            Mardi
                        </span>
                    </div>

                    <div style={{width: "100%", flexDirection:"row", display:"flex", alignContent:"top", alignItems:"top", marginTop:"10px"}}>
                        <IOSSwitch
                            checked={mardiWorked.checked}
                            onChange={() => setMardiWorked(prevState => ({ ...prevState, checked: !prevState.checked }))}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        {
                            mardiWorked.checked ? (
                                    <div style={{width: "100%", flexDirection:"row", display:"flex", alignContent:"top", alignItems:"top"}}>
                                        <div style={{width:"40%", display:"flex", alignSelf:"start", marginLeft:"12px"}}>
                                            <TimePickerWholeDay selectedTime={mardiWorked.start}
                                                                disabled={mardiWorked.checked===false}
                                                                selectedOtherValue={mardiWorked.end}
                                                                isStart={false}
                                                                timezone={timezone}
                                                                onTimeSelected={(time) => setMardiWorked(prevState => ({ ...prevState, start: time}))}
                                            >
                                            </TimePickerWholeDay>
                                        </div>
                                        <span style={{marginLeft:"12px", marginTop:"20px"}}>à</span>
                                        <div style={{width:"40%", display:"flex", alignSelf:"start", marginLeft:"12px"}}>
                                            <TimePickerWholeDay selectedTime={mardiWorked.end}
                                                                disabled={mardiWorked.checked===false}
                                                                selectedOtherValue={mardiWorked.start}
                                                                timezone={timezone}
                                                                isStart={true}
                                                                onTimeSelected={(time) => setMardiWorked(prevState => ({ ...prevState, end: time}))}
                                            >
                                            </TimePickerWholeDay>
                                        </div>
                                    </div>):(
                                <span style={{marginLeft:"20px", color:"black", marginTop:"16px"}}>Ne travaille pas</span>
                                )
                        }
                    </div>
                    <div style={{marginTop:"10px"}}>
                        <span style={{fontWeight:400}}>
                            Mercredi
                        </span>
                    </div>
                    <div style={{width: "100%", flexDirection:"row", display:"flex", alignContent:"top", alignItems:"top", marginTop:"10px"}}>
                        <IOSSwitch
                            checked={mercrediWorked.checked}
                            onChange={() => setMercrediWorked(prevState => ({ ...prevState, checked: !prevState.checked }))}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        {
                            mercrediWorked.checked ? (
                                    <div style={{width: "100%", flexDirection:"row", display:"flex", alignContent:"top", alignItems:"top"}}>
                                        <div style={{width:"40%", display:"flex", alignSelf:"start", marginLeft:"12px"}}>
                                            <TimePickerWholeDay selectedTime={mercrediWorked.start}
                                                                disabled={mercrediWorked.checked===false}
                                                                selectedOtherValue={mercrediWorked.end}
                                                                timezone={timezone}
                                                                isStart={false}
                                                                onTimeSelected={(time) => setMercrediWorked(prevState => ({ ...prevState, start: time}))}
                                            >
                                            </TimePickerWholeDay>
                                        </div>
                                        <span style={{marginLeft:"12px", marginTop:"20px"}}>à</span>
                                        <div style={{width:"40%", display:"flex", alignSelf:"start", marginLeft:"12px"}}>
                                            <TimePickerWholeDay selectedTime={mercrediWorked.end}
                                                                disabled={mercrediWorked.checked===false}
                                                                selectedOtherValue={mercrediWorked.start}
                                                                timezone={timezone}
                                                                isStart={true}
                                                                onTimeSelected={(time) => setMercrediWorked(prevState => ({ ...prevState, end: time}))}
                                            >
                                            </TimePickerWholeDay>
                                        </div>
                                    </div>) : (
                                <span style={{marginLeft:"20px", color:"black", marginTop:"16px"}}>Ne travaille pas</span>                                )
                        }

                    </div>
                    <div style={{marginTop:"10px"}}>
                        <span style={{fontWeight:400}}>
                            Jeudi
                        </span>
                    </div>
                    <div style={{width: "100%", flexDirection:"row", display:"flex", alignContent:"top", alignItems:"top", marginTop:"10px"}}>
                        <IOSSwitch
                            checked={jeudiWorked.checked}
                            onChange={() => setJeudiWorked(prevState => ({ ...prevState, checked: !prevState.checked }))}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        {
                            jeudiWorked.checked ? (
                                    <div style={{width: "100%", flexDirection:"row", display:"flex", alignContent:"top", alignItems:"top"}}>
                                        <div style={{width:"40%", display:"flex", alignSelf:"start", marginLeft:"12px"}}>
                                            <TimePickerWholeDay selectedTime={jeudiWorked.start}
                                                                disabled={jeudiWorked.checked===false}
                                                                selectedOtherValue={jeudiWorked.end}
                                                                timezone={timezone}
                                                                isStart={false}
                                                                onTimeSelected={(time) => setJeudiWorked(prevState => ({ ...prevState, start: time}))}
                                            >
                                            </TimePickerWholeDay>
                                        </div>
                                        <span style={{marginLeft:"12px", marginTop:"20px"}}>à</span>
                                        <div style={{width:"40%", display:"flex", alignSelf:"start", marginLeft:"12px"}}>
                                            <TimePickerWholeDay selectedTime={jeudiWorked.end}
                                                                disabled={jeudiWorked.checked===false}
                                                                selectedOtherValue={jeudiWorked.start}
                                                                timezone={timezone}
                                                                isStart={true}
                                                                onTimeSelected={(time) => setJeudiWorked(prevState => ({ ...prevState, end: time}))}
                                            >
                                            </TimePickerWholeDay>
                                        </div>
                                    </div>) : (
                                <span style={{marginLeft:"20px", color:"black", marginTop:"16px"}}>Ne travaille pas</span>
                                )
                        }

                    </div>
                    <div style={{marginTop:"10px"}}>
                        <span style={{fontWeight:400}}>
                            Vendredi
                        </span>
                    </div>
                    <div style={{width: "100%", flexDirection:"row", display:"flex", alignContent:"top", alignItems:"top", marginTop:"10px"}}>
                        <IOSSwitch
                            checked={vendrediWorked.checked}
                            onChange={() => setVendrediWorked(prevState => ({ ...prevState, checked: !prevState.checked }))}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        {
                            vendrediWorked.checked ? (
                                    <div style={{width: "100%", flexDirection:"row", display:"flex", alignContent:"top", alignItems:"top"}}>
                                        <div style={{width:"40%", display:"flex", alignSelf:"start", marginLeft:"12px"}}>
                                            <TimePickerWholeDay selectedTime={vendrediWorked.start}
                                                                disabled={vendrediWorked.checked===false}
                                                                selectedOtherValue={vendrediWorked.end}
                                                                timezone={timezone}
                                                                isStart={false}
                                                                onTimeSelected={(time) => setVendrediWorked(prevState => ({ ...prevState, start: time}))}
                                            >
                                            </TimePickerWholeDay>
                                        </div>
                                        <span style={{marginLeft:"12px", marginTop:"20px"}}>à</span>
                                        <div style={{width:"40%", display:"flex", alignSelf:"start", marginLeft:"12px"}}>
                                            <TimePickerWholeDay selectedTime={vendrediWorked.end}
                                                                disabled={vendrediWorked.checked===false}
                                                                selectedOtherValue={vendrediWorked.start}
                                                                timezone={timezone}
                                                                isStart={true}
                                                                onTimeSelected={(time) => setVendrediWorked(prevState => ({ ...prevState, end: time}))}
                                            >
                                            </TimePickerWholeDay>
                                        </div>
                                    </div>) : (
                                <span style={{marginLeft:"20px", color:"black", marginTop:"16px"}}>Ne travaille pas</span>
                                )
                        }
                    </div>
                    <div style={{marginTop:"10px"}}>
                        <span style={{fontWeight:400}}>
                            Samedi
                        </span>
                    </div>
                    <div style={{width: "100%", flexDirection:"row", display:"flex", alignContent:"top", alignItems:"top", marginTop:"10px"}}>
                        <IOSSwitch
                            checked={samediWorked.checked}
                            onChange={() => setSamediWorked(prevState => ({ ...prevState, checked: !prevState.checked }))}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        {
                            samediWorked.checked ? (
                                    <div style={{width: "100%", flexDirection:"row", display:"flex", alignContent:"top", alignItems:"top"}}>
                                        <div style={{width:"40%", display:"flex", alignSelf:"start", marginLeft:"12px"}}>
                                            <TimePickerWholeDay selectedTime={samediWorked.start}
                                                                disabled={samediWorked.checked===false}
                                                                selectedOtherValue={samediWorked.end}
                                                                timezone={timezone}
                                                                isStart={false}
                                                                onTimeSelected={(time) => setSamediWorked(prevState => ({ ...prevState, start: time}))}
                                            >
                                            </TimePickerWholeDay>
                                        </div>
                                        <span style={{marginLeft:"12px", marginTop:"20px"}}>à</span>
                                        <div style={{width:"40%", display:"flex", alignSelf:"start", marginLeft:"12px"}}>
                                            <TimePickerWholeDay selectedTime={samediWorked.end}
                                                                disabled={samediWorked.checked===false}
                                                                selectedOtherValue={samediWorked.start}
                                                                timezone={timezone}
                                                                isStart={true}
                                                                onTimeSelected={(time) => setSamediWorked(prevState => ({ ...prevState, end: time}))}
                                            >
                                            </TimePickerWholeDay>
                                        </div>
                                    </div>) : (
                                <span style={{marginLeft:"20px", color:"black", marginTop:"16px"}}>Ne travaille pas</span>
                                )
                        }

                    </div>
                    <div style={{marginTop:"10px"}}>
                        <span style={{fontWeight:400}}>
                            Dimanche
                        </span>
                    </div>
                    <div style={{width: "100%", flexDirection:"row", display:"flex", alignContent:"top", alignItems:"top", marginTop:"10px"}}>
                        <IOSSwitch
                            checked={dimancheWorked.checked}
                            onChange={() => setDimancheWorked(prevState => ({ ...prevState, checked: !prevState.checked }))}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        {
                            dimancheWorked.checked ? (
                                <div style={{width: "100%", flexDirection:"row", display:"flex", alignContent:"top", alignItems:"top"}}>
                                    <div style={{width:"40%", display:"flex", alignSelf:"start", marginLeft:"12px"}}>
                                        <TimePickerWholeDay selectedTime={dimancheWorked.start}
                                                            disabled={dimancheWorked.checked===false}
                                                            selectedOtherValue={dimancheWorked.end}
                                                            timezone={timezone}
                                                            isStart={false}
                                                            onTimeSelected={(time) => setDimancheWorked(prevState => ({ ...prevState, start: time}))}
                                        >
                                        </TimePickerWholeDay>
                                    </div>
                                    <span style={{marginLeft:"12px", marginTop:"20px"}}>à</span>
                                    <div style={{width:"40%", display:"flex", alignSelf:"start", marginLeft:"12px"}}>
                                        <TimePickerWholeDay selectedTime={dimancheWorked.end}
                                                            disabled={dimancheWorked.checked===false}
                                                            selectedOtherValue={dimancheWorked.start}
                                                            timezone={timezone}
                                                            isStart={true}
                                                            onTimeSelected={(time) => setDimancheWorked(prevState => ({ ...prevState, end: time}))}
                                        >
                                        </TimePickerWholeDay>
                                    </div>
                                </div>) : (
                                <span style={{marginLeft:"20px", color:"black", marginTop:"16px"}}>Ne travaille pas</span>
                                )
                        }

                    </div>
                </div>

                <div className="field-container">
                    <div className="title-field-container">
                                      <span className="text-field-style">
                                          Périodes d'indisponibilité
                                      </span>
                    </div>
                    <p style={{lineHeight:"20px", width:"100%"}}>
                        Les périodes d'indisponibilité s'appliquent sur l'agenda, avec une date de début et une date de fin, et sont supprimées une fois la date de fin passée.
                    </p>
                </div>

                <div style={{display:"flex", width:"100%"}}>
                    <Accordion onChange={handleAccChange} expanded={expanded} style={{ borderRadius: '15px', boxShadow: 'none', background:"#F1F5F8", color:"black", width:"100%" }}>
                        <AccordionSummary
                            expandIcon={<BiCaretDown />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <span>Périodes à venir</span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                                {unavRanges.length === 0 ? ( // Check if the array is empty
                                    <div>Aucune</div> // Display this message if the array is empty
                                ) : (
                                        unavRanges.map((elem, index) => (
                                            <div style={{display:"flex",flexDirection:"column"}} key={index}>

                                                {
                                                    index>0 && (
                                                        <Divider></Divider>
                                                    )
                                                }
                                                <div  style={{flexDirection:"row", display:"flex", width:"100%",marginTop: index>0 ? "20px" : "0px", marginBottom:"20px"}}>
                                                    <div style={{width:"85%", display:"flex", flexDirection:"column"}}>
                                                        <span style={{display:"flex", flexDirection:"column", width:"100%"}}>Début <span style={{fontWeight:600, marginBottom:"10px"}}>{dayjs(elem.start).tz(timezone).format("LLL")}</span>Fin <span style={{fontWeight:600}}>{dayjs(elem.end).tz(timezone).format("LLL")}</span></span>
                                                    </div>
                                                    <div style={{width:"15%", display:"flex", alignItems:'center', justifyContent:"end"}}>
                                                        <IconButton onClick={()=> handleRemRange(index)} disableRipple disableFocusRipple className="icon" style={{ border:"1px solid black", width: "40px", height:"40px", display: "flex", alignSelf: "top", marginTop:"8px" }}>
                                                            <img src={trashlogo} style={{width:"20px", height:"20px"}}/>
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </div>

                                        ))
                                )}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div style={{ textAlign: "center", display: "flex", alignSelf: "start", marginTop: "25px" }}>
                    <Button disableElevation={true} onClick={()=>setIsTimeDialogOpen(true)} component="label" className="button-style-noborders-lil" variant="contained" style={{ backgroundColor: "black", color: "white" }}>
                        <Typography variant="button" style={{ textTransform: 'none', margin: "2px", fontSize:"14px" }}>
                            Ajouter une période
                        </Typography>
                    </Button>
                </div>

                <div className="field-container">
                    <div className="title-field-container">
                                      <span className="text-field-style">
                                          Temps minimum avant réservation
                                      </span>
                    </div>
                </div>
                <div style={{width:"50%", display:"flex", alignSelf:"start"}}>
                    <TimeBeforePrestaPicker selectedTime={timeBeforePresta}
                                        onTimeSelected={(time) => setTimeBeforePresta(prevState => (time))}
                    >
                    </TimeBeforePrestaPicker>
                </div>
                <div className="field-container">
                    <div className="title-field-container">
                                      <span className="text-field-style">
                                          Intervalles de disponibilité
                                      </span>
                    </div>
                </div>
                <div style={{display:"flex", flexDirection:"row", width:"100%", alignItems:"top"}}>
                    <IOSSwitch
                        checked={timeForwardChecked}
                        onChange={() => setTimeForwardChecked(prevState => (!timeForwardChecked))}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    {timeForwardChecked ? (<span style={{marginLeft:"20px", marginTop:"8px"}}>Activé : Choisir les intervalles</span>) : (
                        <span style={{marginLeft:"20px", marginTop:"8px"}}>Desactivé : Les intervalles sont optimisés pour éviter les creux</span>
                    )}
                </div>
                {timeForwardChecked && (
                    <div style={{width:"50%", display:"flex", alignSelf:"start", marginTop:"20px"}}>
                        <TimeForwardPicker selectedTime={timeForward}
                                                onTimeSelected={(time) => setTimeForward(prevState => (time))}
                        >
                        </TimeForwardPicker>
                    </div>
                )}
                <div className="field-container">
                    <div className="title-field-container">
                                      <span className="text-field-style">
                                          Heures d'indisponibilité
                                      </span>
                    </div>
                    <p style={{lineHeight:"20px", width:"100%"}}>
                        Les heures d’indisponibilités s’appliquent chaque semaine.
                    </p>
                </div>

                <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", alignContent:"center"}}>
                    <span style={{fontWeight:400}}>
                        Lundi
                    </span>
                    <div style={{display:"flex", flexDirection:"row", width:"12%", alignItems:"center",
                        alignContent:"center", justifyContent:"center"}}>
                        <IconButton className="iconbutton-noborders" onClick={() => addBreak("Lundi")} style={{backgroundColor: lundiWorked.checked ? "black" : "transparent"}}>
                            {lundiWorked.checked ? <img src={plusicon} style={{width:"20px", height:"20px", color:"white"}}></img>
                                : <span style={{fontSize:"1rem", color:"black"}}>OFF</span>}
                        </IconButton>
                    </div>
                </div>
                {lundiWorked.checked && (
                    <div style={{width:"100%", overflow:"visible",}}>
                        {lundiWorked.breaks.map((breakItem, index) => (
                            <div key={index} style={{ overflow:"visible", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "top", alignContent: "top", marginTop: "12px" }}>
                                <div style={{ width: "42%", display: "flex", alignSelf: "start" }}>
                                    <TimePickerWholeDay
                                        selectedTime={breakItem.start}
                                        onTimeSelected={(time) => handleBreakChange(index, "start", time, "Lundi")}
                                        selectedOtherValue={breakItem.end}
                                        timezone={timezone}
                                        isStart={false}
                                    />
                                </div>
                                <span style={{marginLeft:"6px", marginRight:"6px", marginTop:"20px"}}>à</span>
                                <div style={{ width: "42%", display: "flex", alignSelf: "start" }}>
                                    <TimePickerWholeDay
                                        selectedTime={breakItem.end}
                                        timezone={timezone}
                                        onTimeSelected={(time) => handleBreakChange(index, "end", time, "Lundi")}
                                        selectedOtherValue={breakItem.start}
                                        isStart={true}
                                    />
                                </div>
                                <IconButton disableRipple disableFocusRipple className="icon" style={{ border:"1px solid black", width: "40px", height:"40px", display: "flex", alignSelf: "top", marginTop:"8px", marginLeft:"8px" }} onClick={() => removeBreak(index, "Lundi")}>
                                    <img src={trashlogo} style={{width:"20px", height:"20px"}}/>
                                </IconButton>
                            </div>
                        ))}
                    </div>
                )}

                <div style={{width:"100%",marginTop:"10px", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", alignContent:"center"}}>
                    <span style={{fontWeight:400}}>
                        Mardi
                    </span>
                    <div style={{display:"flex", flexDirection:"row", width:"12%", alignItems:"center",
                        alignContent:"center", justifyContent:"center"}}>
                        <IconButton className="iconbutton-noborders" style={{backgroundColor: mardiWorked.checked ? "black" : "transparent"}}
                                    onClick={() => addBreak("Mardi")} >
                            {mardiWorked.checked ? <img src={plusicon} style={{width:"20px", height:"20px", color:"white"}}></img>
                                : <span style={{fontSize:"1rem", color:"black"}}>OFF</span>}
                        </IconButton>
                    </div>
                </div>
                {mardiWorked.checked && (
                    <div style={{width:"100%"}}>
                        {mardiWorked.breaks.map((breakItem, index) => (
                            <div key={index} style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "top", alignContent: "top", marginTop: "12px" }}>
                                <div style={{ width: "42%", display: "flex", alignSelf: "start" }}>
                                    <TimePickerWholeDay
                                        selectedTime={breakItem.start}
                                        timezone={timezone}
                                        onTimeSelected={(time) => handleBreakChange(index, "start", time, "Mardi")}
                                        selectedOtherValue={breakItem.end}
                                        isStart={false}
                                    />
                                </div>
                                <span style={{marginLeft:"6px", marginRight:"6px", marginTop:"20px"}}>à</span>
                                <div style={{ width: "42%", display: "flex", alignSelf: "start" }}>
                                    <TimePickerWholeDay
                                        selectedTime={breakItem.end}
                                        timezone={timezone}
                                        onTimeSelected={(time) => handleBreakChange(index, "end", time, "Mardi")}
                                        selectedOtherValue={breakItem.start}
                                        isStart={true}
                                    />
                                </div>
                                <IconButton disableRipple disableFocusRipple className="icon" style={{ border:"1px solid black", width: "40px", height:"40px", display: "flex", alignSelf: "top", marginTop:"8px", marginLeft:"8px" }} onClick={() => removeBreak(index, "Mardi")}>
                                    <img src={trashlogo} style={{width:"20px", height:"20px"}}/>
                                </IconButton>
                            </div>
                        ))}
                    </div>
                )}
                <div style={{width:"100%",marginTop:"10px", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", alignContent:"center"}}>
                    <span style={{fontWeight:400}}>
                        Mercredi
                    </span>
                    <div style={{display:"flex", flexDirection:"row", width:"12%", alignItems:"center",
                        alignContent:"center", justifyContent:"center"}}>
                        <IconButton className="iconbutton-noborders" style={{backgroundColor: mercrediWorked.checked ? "black" : "transparent"}}
                                    onClick={() => addBreak("Mercredi")}>
                            {mercrediWorked.checked ? <img src={plusicon} style={{width:"20px", height:"20px", color:"white"}}></img>
                                : <span style={{fontSize:"1rem", color:"black"}}>OFF</span>}
                        </IconButton>
                    </div>
                </div>
                {mercrediWorked.checked && (
                    <div style={{width:"100%"}}>
                        {mercrediWorked.breaks.map((breakItem, index) => (
                            <div key={index} style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "top", alignContent: "top", marginTop: "12px" }}>
                                <div style={{ width: "42%", display: "flex", alignSelf: "start" }}>
                                    <TimePickerWholeDay
                                        selectedTime={breakItem.start}
                                        timezone={timezone}
                                        onTimeSelected={(time) => handleBreakChange(index, "start", time, "Mercredi")}
                                        selectedOtherValue={breakItem.end}
                                        isStart={false}
                                    />
                                </div>
                                <span style={{marginLeft:"6px", marginRight:"6px", marginTop:"20px"}}>à</span>
                                <div style={{ width: "42%", display: "flex", alignSelf: "start" }}>
                                    <TimePickerWholeDay
                                        selectedTime={breakItem.end}
                                        timezone={timezone}
                                        onTimeSelected={(time) => handleBreakChange(index, "end", time, "Mercredi")}
                                        selectedOtherValue={breakItem.start}
                                        isStart={true}
                                    />
                                </div>
                                <IconButton disableRipple disableFocusRipple className="icon" style={{ border:"1px solid black", width: "40px", height:"40px", display: "flex", alignSelf: "top", marginTop:"8px", marginLeft:"8px" }} onClick={() => removeBreak(index, "Mercredi")}>
                                    <img src={trashlogo} style={{width:"20px", height:"20px"}}/>
                                </IconButton>
                            </div>
                        ))}
                    </div>
                )}
                <div style={{width:"100%", marginTop:"10px", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", alignContent:"center"}}>
                    <span style={{fontWeight:400}}>
                        Jeudi
                    </span>
                    <div style={{display:"flex", flexDirection:"row", width:"12%", alignItems:"center",
                        alignContent:"center", justifyContent:"center"}}>
                        <IconButton className="iconbutton-noborders" style={{backgroundColor: jeudiWorked.checked ? "black" : "transparent"}}
                                    onClick={() => addBreak("Jeudi")}>
                            {jeudiWorked.checked ? <img src={plusicon} style={{width:"20px", height:"20px", color:"white"}}></img>
                                : <span style={{fontSize:"1rem", color:"black"}}>OFF</span>}
                        </IconButton>
                    </div>
                </div>
                {jeudiWorked.checked && (
                    <div style={{width:"100%"}}>
                        {jeudiWorked.breaks.map((breakItem, index) => (
                            <div key={index} style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "top", alignContent: "top", marginTop: "12px" }}>
                                <div style={{ width: "42%", display: "flex", alignSelf: "start" }}>
                                    <TimePickerWholeDay
                                        selectedTime={breakItem.start}
                                        timezone={timezone}
                                        onTimeSelected={(time) => handleBreakChange(index, "start", time, "Jeudi")}
                                        selectedOtherValue={breakItem.end}
                                        isStart={false}
                                    />
                                </div>
                                <span style={{marginLeft:"6px", marginRight:"6px", marginTop:"20px"}}>à</span>
                                <div style={{ width: "42%", display: "flex", alignSelf: "start" }}>
                                    <TimePickerWholeDay
                                        selectedTime={breakItem.end}
                                        timezone={timezone}
                                        onTimeSelected={(time) => handleBreakChange(index, "end", time, "Jeudi")}
                                        selectedOtherValue={breakItem.start}
                                        isStart={true}
                                    />
                                </div>
                                <IconButton disableRipple disableFocusRipple className="icon" style={{ border:"1px solid black", width: "40px", height:"40px", display: "flex", alignSelf: "top", marginTop:"8px", marginLeft:"8px" }} onClick={() => removeBreak(index, "Jeudi")}>
                                    <img src={trashlogo} style={{width:"20px", height:"20px"}}/>
                                </IconButton>
                            </div>
                        ))}
                    </div>
                )}
                <div style={{width:"100%",marginTop:"10px", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", alignContent:"center"}}>
                    <span style={{fontWeight:400}}>
                        Vendredi
                    </span>
                    <div style={{display:"flex", flexDirection:"row", width:"12%", alignItems:"center",
                        alignContent:"center", justifyContent:"center"}}>
                        <IconButton className="iconbutton-noborders" style={{backgroundColor: vendrediWorked.checked ? "black" : "transparent"}}
                                    onClick={() => addBreak("Vendredi")}>
                            {vendrediWorked.checked ? <img src={plusicon} style={{width:"20px", height:"20px", color:"white"}}></img>
                                : <span style={{fontSize:"1rem", color:"black"}}>OFF</span>}
                        </IconButton>
                    </div>
                </div>
                {vendrediWorked.checked && (
                    <div style={{width:"100%"}}>
                        {vendrediWorked.breaks.map((breakItem, index) => (
                            <div key={index} style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "top", alignContent: "top", marginTop: "12px" }}>
                                <div style={{ width: "42%", display: "flex", alignSelf: "start" }}>
                                    <TimePickerWholeDay
                                        selectedTime={breakItem.start}
                                        timezone={timezone}
                                        onTimeSelected={(time) => handleBreakChange(index, "start", time, "Vendredi")}
                                        selectedOtherValue={breakItem.end}
                                        isStart={false}
                                    />
                                </div>
                                <span style={{marginLeft:"6px", marginRight:"6px", marginTop:"20px"}}>à</span>
                                <div style={{ width: "42%", display: "flex", alignSelf: "start" }}>
                                    <TimePickerWholeDay
                                        selectedTime={breakItem.end}
                                        timezone={timezone}
                                        onTimeSelected={(time) => handleBreakChange(index, "end", time, "Vendredi")}
                                        selectedOtherValue={breakItem.start}
                                        isStart={true}
                                    />
                                </div>
                                <IconButton disableRipple disableFocusRipple className="icon" style={{ border:"1px solid black", width: "40px", height:"40px", display: "flex", alignSelf: "top", marginTop:"8px", marginLeft:"8px" }} onClick={() => removeBreak(index, "Vendredi")}>
                                    <img src={trashlogo} style={{width:"20px", height:"20px"}}/>
                                </IconButton>
                            </div>
                        ))}
                    </div>
                )}
                <div style={{width:"100%", display:"flex", marginTop:"10px", flexDirection:"row", justifyContent:"space-between", alignItems:"center", alignContent:"center"}}>
                    <span style={{fontWeight:400}}>
                        Samedi
                    </span>
                    <div style={{display:"flex", flexDirection:"row", width:"12%", alignItems:"center",
                        alignContent:"center", justifyContent:"center"}}>
                        <IconButton className="iconbutton-noborders" style={{backgroundColor: samediWorked.checked ? "black" : "transparent"}}
                                    onClick={() => addBreak("Samedi")}>
                            {samediWorked.checked ? <img src={plusicon} style={{width:"20px", height:"20px", color:"white"}}></img>
                                : <span style={{fontSize:"1rem", color:"black"}}>OFF</span>}
                        </IconButton>
                    </div>
                </div>
                {samediWorked.checked && (
                    <div style={{width:"100%"}}>
                        {samediWorked.breaks.map((breakItem, index) => (
                            <div key={index} style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "top", alignContent: "top", marginTop: "12px" }}>
                                <div style={{ width: "42%", display: "flex", alignSelf: "start" }}>
                                    <TimePickerWholeDay
                                        selectedTime={breakItem.start}
                                        timezone={timezone}
                                        onTimeSelected={(time) => handleBreakChange(index, "start", time, "Samedi")}
                                        selectedOtherValue={breakItem.end}
                                        isStart={false}
                                    />
                                </div>
                                <span style={{marginLeft:"6px", marginRight:"6px", marginTop:"20px"}}>à</span>
                                <div style={{ width: "42%", display: "flex", alignSelf: "start" }}>
                                    <TimePickerWholeDay
                                        selectedTime={breakItem.end}
                                        timezone={timezone}
                                        onTimeSelected={(time) => handleBreakChange(index, "end", time, "Samedi")}
                                        selectedOtherValue={breakItem.start}
                                        isStart={true}
                                    />
                                </div>
                                <IconButton disableRipple disableFocusRipple className="icon" style={{ border:"1px solid black", width: "40px", height:"40px", display: "flex", alignSelf: "top", marginTop:"8px", marginLeft:"8px" }} onClick={() => removeBreak(index, "Samedi")}>
                                    <img src={trashlogo} style={{width:"20px", height:"20px"}}/>
                                </IconButton>
                            </div>
                        ))}
                    </div>
                )}
                <div style={{width:"100%", marginTop:"10px", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", alignContent:"center"}}>
                    <span style={{fontWeight:400}}>
                        Dimanche
                    </span>
                    <div style={{display:"flex", flexDirection:"row", width:"12%", alignItems:"center",
                        alignContent:"center", justifyContent:"center"}}>
                        <IconButton className="iconbutton-noborders" style={{backgroundColor: dimancheWorked.checked ? "black" : "transparent"}}
                                    onClick={() => addBreak("Dimanche")}>
                            {dimancheWorked.checked ? <img src={plusicon} style={{width:"20px", height:"20px", color:"white"}}></img>
                                : <span style={{fontSize:"1rem", color:"black"}}>OFF</span>}
                        </IconButton>
                    </div>
                </div>
                {dimancheWorked.checked && (
                    <div style={{width:"100%"}}>
                        {dimancheWorked.breaks.map((breakItem, index) => (
                            <div key={index} style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "top", alignContent: "top", marginTop: "12px" }}>
                                <div style={{ width: "42%", display: "flex", alignSelf: "start" }}>
                                    <TimePickerWholeDay
                                        selectedTime={breakItem.start}
                                        timezone={timezone}
                                        onTimeSelected={(time) => handleBreakChange(index, "start", time, "Dimanche")}
                                        selectedOtherValue={breakItem.end}
                                        isStart={false}
                                    />
                                </div>
                                <span style={{marginLeft:"6px", marginRight:"6px", marginTop:"20px"}}>à</span>
                                <div style={{ width: "42%", display: "flex", alignSelf: "start" }}>
                                    <TimePickerWholeDay
                                        selectedTime={breakItem.end}
                                        timezone={timezone}
                                        onTimeSelected={(time) => handleBreakChange(index, "end", time, "Dimanche")}
                                        selectedOtherValue={breakItem.start}
                                        isStart={true}
                                    />
                                </div>
                                <IconButton disableRipple disableFocusRipple className="icon" style={{ border:"1px solid black", width: "40px", height:"40px", display: "flex", alignSelf: "top", marginTop:"8px", marginLeft:"8px" }} onClick={() => removeBreak(index, "Dimanche")}>
                                    <img src={trashlogo} style={{width:"20px", height:"20px"}}/>
                                </IconButton>
                            </div>
                        ))}
                    </div>
                )}

                <div>
                    <p style={{lineHeight:"20px"}}>
                        Si vous souhaitez définir une période d’indisponibilité plus précise ou récurrente, nous vous recommandons de synchroniser votre agenda Google qui permettra de rendre indisponible les créneaux de prestations.<br></br> La synchronisation se fait dans les deux sens (ajout des rendez-vous faismoibg dans google calendar, horaires indisponibles sur faismoibg lorsqu'il y'a un événement dans google calendar).
                    </p>
                </div>



                <div style={{width:"100%", display:"flex", flexDirection:"column", marginTop:"50px"}}>
                    <div style={{display:"flex", flexDirection:"row", width:"100%", alignItems:"center"}}>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <img src={gcal} style={{width:"100%"}}/>
                        </div>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <span style={{marginLeft:"12px", fontWeight:"600"}}>
                                Synchronisation avec Google Agenda
                            </span>
                        </div>
                    </div>
                    {!googleAGCode ? (
                        /*<div style={{marginTop:"20px"}}>
                            <p>
                                Vous pouvez synchroniser vos rdv Faismoibg sur votre agenda Google.
                            </p>
                            <p>
                                <span style={{fontWeight:700}}>Cette fonctionnalité est en cours de validation par Google</span> mais vous pouvez toutefois l’utiliser sans problème de sécurité.
                            </p>
                            <p>
                                Pour cela nous vous invitons à :
                            </p>
                            <p style={{marginLeft:"8px"}}>
                                1. Cliquer sur <span style={{fontWeight:700}}>"Continuer avec Google"</span>
                            </p>
                            <p style={{marginLeft:"8px"}}>
                                2. Sélectionner un compte Google
                            </p>
                            <p style={{marginLeft:"8px"}}>
                                3. Cliquer sur <span style={{fontWeight:700}}>"Paramètres avancés"</span> puis plus bas, sur <span style={{fontWeight:700}}>"Accéder à faismoibg.com"</span>
                            </p>
                        </div>*/
                        <div style={{marginTop:"10px"}}>
                            <p>
                                Vous pouvez synchroniser vos rdv Faismoibg sur votre agenda Google.
                            </p>
                            <p>
                                Pour cela nous vous invitons à cliquer sur <span style={{fontWeight:700}}>"Continuer avec Google"</span>
                            </p>
                        </div>
                    ) : (
                        <div style={{marginTop:"20px"}}>
                            <p>
                                Votre agenda google est actuellement synchronisé avec votre compte Faismoibg.
                            </p>
                        </div>
                        )
                    }

                    <div style={{ textAlign: "center", display: "flex", alignSelf: "start", marginTop: "10px" }}>
                        <Button startIcon={<img style={{width:"20px", height:"20px", marginRight:"4px"}} src={gcal}></img>} disableElevation={true} onClick={handleSyncGA} component="label" className="button-style-noborders-lil" variant="contained" style={{ backgroundColor: "#131314", color: "white",border: "1px solid #747775" }}>
                            <Typography variant="button" style={{fontWeight:500, textTransform: 'none', margin: "2px", fontSize:"14px", fontFamily:"Roboto Medium, sans-serif" }}>
                                {googleAGCode ? 'Déconnecter Google Agenda' : 'Continuer avec Google'}
                            </Typography>
                        </Button>
                    </div>

                    <div style={{width:"100%", display:"flex", flexDirection:"column", marginTop:"50px"}}>
                        <div style={{display:"flex", flexDirection:"row", width:"100%", alignItems:"end"}}>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                💡
                            </div>
                            <div style={{display:"flex", flexDirection:"column"}}>
                            <span style={{marginLeft:"12px", fontWeight:"600"}}>
                                Centre d'aide
                            </span>
                            </div>
                        </div>
                        <span style={{marginTop:"20px"}}>
                            Nous vous recommandons vivement de consulter notre <a href="https://shine.faismoibg.com/centre-daide" target="_blank" style={{textDecoration:"none", color:"#4808FF"}}>centre d’aide</a> pour vous aider dans la configuration de votre agenda, ou pour tout autre besoin lié à l'utilisation de Faismoibg.
                        </span>
                    </div>

                    <div className="button-container" style={{marginTop:"70px"}}>
                        <Button onClick={handleSubmitData} className="button-style button-style-noborders" variant="contained">
                            <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                Enregistrer
                            </Typography>
                        </Button>
                    </div>

                    <div className="button-container" style={{marginTop:"15px"}}>
                        <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateBack}>
                            <Typography variant="button" style={{ textTransform: 'none' }}>
                                Retour
                            </Typography>
                        </Button>
                    </div>
                </div>

                <Dialog open={isTimeDialogOpen} onClose={handleTimeCloseOutside}
                        fullWidth={!isLargeScreen}
                        PaperProps={{
                            style: {
                                minWidth: isLargeScreen ? '500px' : 'auto',
                                maxWidth: isLargeScreen ? '500px' : 'auto'
                            },
                        }}
                >

                    <DialogContent style={{backgroundColor:"white"}}>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            {/*<span style={{fontWeight:400}}>{timeZoneName}</span>
                            <br></br>*/}
                            <span style={{fontWeight:700, fontSize:"16px"}}>
                                Ajouter une nouvelle période d'indisponibilité
                            </span>

                            <span style={{fontWeight:500, fontSize:"16px", marginTop:"25px"}}>
                                Séléctionner un jour et une heure de début
                            </span>

                            <div style={{marginTop:"25px", width:"100%"}}>
                                <DateTimeField style={{width:"100%"}}
                                    label="Début"
                                    value={beginPeriod}
                                    onChange={(newValue) => setBeginPeriod(dayjs(newValue).tz(timezone))}
                                    format="LLL"
                                />
                            </div>

                            <span style={{fontWeight:500, fontSize:"16px",marginTop:"25px"}}>
                                Séléctionner un jour et une heure de fin
                            </span>

                            <div style={{marginTop:"25px", width:"100%"}}>
                                <DateTimeField style={{width:"100%"}}
                                    label="Fin"
                                    value={endPeriod}
                                    onChange={(newValue) => setEndPeriod(dayjs(newValue).tz(timezone))}
                                    format="LLL"
                                />
                            </div>

                        </div>
                    </DialogContent>
                    <DialogActions style={{backgroundColor:"white" }}>
                        <div style={{width:"100%", display:"flex", alignItems:"end", justifyContent:"end"}}>
                            <Button className="blue-button button-style-noborders" style={{marginLeft:"32px", marginRight:"32px", marginBottom:"30px", marginTop:"10px", backgroundColor:"black"}}
                                    variant="button" onClick={handleTimeDialogClose}>
                                <Typography variant="button" style={{textTransform: 'none', color:"white"}}>
                                    Confirmer
                                </Typography>
                            </Button>
                        </div>

                    </DialogActions>
                </Dialog>

                <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={showBackdrop}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>)}
        </div>
    )
}