import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import {
    checkIfPseudoExists, checkIfUrlExists,
    getUser,
    getUserPostCreateStatus,
    handleDelete,
    handleUpload,
    updateUserDocument
} from "../firebase";
import firebase from "firebase/compat/app";
import 'firebase/firestore'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import Button from "@mui/material/Button";
import {
    Autocomplete, Backdrop,
    Chip, CircularProgress,
    Dialog,
    DialogContent,
    IconButton, Skeleton,
    TextField,
    Typography, useMediaQuery, useTheme,
} from "@mui/material";
import {
    FaDailymotion, FaEnvelope,
    FaFacebook,
    FaFacebookMessenger, FaGlobe,
    FaInstagram, FaPaperclip,
    FaPinterest,
    FaSnapchat, FaStripe,
    FaTiktok,
    FaWhatsapp, FaYoutube
} from "react-icons/fa";
import {FaPaypal} from "react-icons/fa6";
import SnackbarWithCustomBackground from "./snackbar";
import trashlogo from "../assets/trashic.svg";
import rec from "../assets/rec.png";
import {HeaderHome} from "./headerHome";
import deleteic from "../assets/icdeletetag.svg";
import icLydia from "../assets/iconLydia.svg";
import icPaylib from "../assets/iconPaylib.svg";
import icWise from "../assets/iconWise.svg";
import icRevolut from "../assets/iconRevolut.svg";
import icLinktree from "../assets/iconLinktree.svg";
import icBeacons from "../assets/iconBeacons.svg";
import icAllMyLinks from "../assets/iconAllMyLinks.svg";
import icSumUp from "../assets/iconSumUp.svg";

const checkPseudoValid = (pseudo) => {
    // Check if the URL field contains any spaces
    return /\s/.test(pseudo);
};

function removeSpecialCharacters(inputString) {
    // Define a regular expression to match characters other than letters, digits, dashes, and underscores
    const regex = /[^a-zA-Z0-9-_]/g;

    // Use the replace method to remove the matched characters
    const cleanedString = inputString.replace(regex, '');

    return cleanedString;
}

// Function to remove objects with an empty 'link' property
const filterEmptyLinks = (arr) => {
    return arr.filter(item => item.link.length >= 1);
};

const isValidURL = (urlField) => {
    // Check if the URL field does not contain 'faismoibg.com', 'faismoibg', and spaces
    const containsForbiddenStrings = ['faismoibg.com', 'faismoibg'].some((forbiddenString) =>
        urlField.includes(forbiddenString)
    );

    // Check if the URL field contains any spaces
    const containsSpaces = /\s/.test(urlField);

    return !containsForbiddenStrings && !containsSpaces;
};

function formatSocialForDb(array) {
    const formattedArray = array.map(item => {
        if (!item.link) {
            return { ...item, link: '' };
        }
        return item;
    });

    const missingNetworks = [
        { network: 'instagram', link: '' },
        { network: 'facebook', link: '' },
        { network: 'tiktok', link: '' },
        { network: 'messenger', link: '' },
        { network: 'whatsapp', link: '' },
        { network: 'pinterest', link: '' },
        { network: 'snapchat', link: '' },
        { network: 'paypal', link: '' },
        { network: 'lydia', link: '' },
        { network: 'paylib', link: '' },
        { network: 'stripe', link: '' },
        { network: 'sumup', link: '' },
        { network: 'wise', link: '' },
        { network: 'revolut', link: '' },
        { network: 'linktree', link: '' },
        { network: 'linkinbio', link: '' },
        { network: 'beacons', link: '' },
        { network: 'allmylinks', link: '' },
        { network: 'youtube', link: '' },
        { network: 'dailymotion', link: '' },
        { network: 'site', link: '' },
        { network: 'email', link: '' }
    ];

    missingNetworks.forEach(missingItem => {
        if (!formattedArray.find(item => item.network === missingItem.network)) {
            formattedArray.push(missingItem);
        }
    });

    return formattedArray;
}
export default function Preferences() {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    const { pathname } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect( () => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged( async(user) => {
            if (!user) {
                navigate("/");
            } else {
                setShowSkeleton(true);
                const fetchData = async () => {
                    try {
                        const postCreateDone = await getUserPostCreateStatus(user.uid);
                        return postCreateDone;
                    } catch (error) {
                        console.error('Error retrieving postCreateDone status:', error);
                    }
                };
                // Call the fetchData function directly
                const displayPage = await fetchData(user.uid);
                if (!displayPage){
                    //User needs to setup minimal info before seeing this page
                    setShowBackdrop(false);
                    navigate("/info" , { replace: true });
                } else {
                    //fetch page data
                    async function fetchUserData() {
                        try {
                            const userData = await getUser(user?.uid);
                            setUserObject({...userData});
                            setThemValue(userData.themeValues);
                            setFormData({pseudo: userData.pseudo,
                                urlPerso: userData.urlPerso.toLowerCase(),
                                pres: userData.presentation,});
                            setSocialLinks([...filterEmptyLinks(userData.socialLinks)])
                            setImageUrl(userData.imageUrl);
                        } catch (e){
                            handleOpenSnackbar("Ooops quelque chose s'est mal passé, contactez nous et communiquez cette erreur => ", e);
                        }
                        setShowBackdrop(false);
                        setShowSkeleton(false);
                    }
                    await fetchUserData();
                }
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => unregisterAuthObserver();
    }, []);
    //form data used for send modifications != userData that is used only for hint display

    const [formData, setFormData] = useState({
        pseudo: '',
        urlPerso: '',
        pres: '',
    });

    const handleFormPresChange = (event) => {
        const newText = event.target.value.replace(/\r?\n/g, '\n');
        setFormData({
            ...formData,
            [event.target.name]: newText
        });
    }

    const handleFormChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.name==="urlPerso" ? removeSpecialCharacters(event.target.value.toLowerCase().trim()) : event.target.value
        });
    };

    const [userObject, setUserObject] = useState({});
    const [isPseudoValid, setPseudoValid] = useState(true);


    const [showBackdrop, setShowBackdrop] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(true);
    //snackbar handling

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');

    const [imageUrl, setImageUrl] = useState('');
    const [file, setFile] = useState(null);

    useEffect(() => {
    }, [imageUrl]);

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };



    const deleteImage = () => {
        setImageUrl('');
        setFile(null);
    };

    const handleFileChange = (event) => {
        const newfile = event.target.files[0];
        setFile(newfile)
        if (newfile) {
            const reader = new FileReader();
            reader.onload = () => {
                // reader.result contains the data URL
                setImageUrl(reader.result);
            };
            reader.readAsDataURL(newfile);
        }
    };


    const deleteImageFromDb = async (url) => {

        try {
            await handleDelete();
            setImageUrl('');
            setFile(null);
            const fileInput = document.getElementById('file-input');
            if (fileInput) {
                fileInput.value = null;
            }
        } catch (e) {
            //todo display delete error
            handleOpenSnackbar("Oops quelque chose s'est mal passé lors de la suppression de votre photo, contactez nous => ", e);
            console.log("Error while deleting image ", e.message);
        }

    };




    //Autocomplete stuff
    const [isPopperOpen, setPopperOpen] = useState(false);
    const [themValue, setThemValue] = React.useState([]);
    const [isthemValid, setThemValid] = useState(true);

    const thematiques = [
        {label:"Spa et Massage", id:1},
        {label:"Coiffure", id:2},
        {label:"Cils et Sourcils", id:3},
        {label:"Ongles", id:4},
        {label:"Soins esthétiques", id:5},
        {label:"Tatouage et Piercing", id:6},
        {label:"Maquillage", id:7},
        {label: "Autre", id:8}
    ];
    const getOptionStyle = () => ({
        borderRadius: "15px", // Set the border radius for each option
        margin: "10px 0", // Add space between the list items
        background: "#DAE0E5", // Set background color for each option
        padding: "15px", // Add padding for each option
    });

    //Url perso
    const [isUrlValid, setUrlValid] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    //Social links
    const [socialLinks, setSocialLinks] = useState([
        { network: 'instagram', link: '' }
    ]);

    const handleLinkChange = (network, event) => {
        const updatedLinks = socialLinks.map((link) => {
            if (link.network === network) {
                return { ...link, link: event.target.value.trim() };
            }
            return link;
        });
        setSocialLinks(updatedLinks);
    };

    const handleDeleteLink = (network) => {
        const updatedLinks = socialLinks.filter((link) => link.network !== network);
        setSocialLinks(updatedLinks);
    };


    const handleAddLink = () => {
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };



    const handleDialogOptionClick = (network) => {
        const isNetworkSelected = socialLinks.some((link) => link.network === network);
        if (!isNetworkSelected) {
            const updatedLinks = [...socialLinks, { network, link: '' }];
            setSocialLinks(updatedLinks);
        }
        setIsDialogOpen(false);
    };

    //Check and load db data
    const handleFormSubmit = async () => {
        setShowBackdrop(true);
        //OK
        const socialForDb = formatSocialForDb(socialLinks);
        const pseudoNok = checkPseudoValid(formData.pseudo);

        if (formData.pseudo!==userObject.pseudo){
            if (formData.pseudo.length<1){
                handleOpenSnackbar("Votre pseudo ne doit contenir au moins un charactère");
                setPseudoValid(false);
                setShowBackdrop(false);
                return;
            } else if (pseudoNok){
                handleOpenSnackbar("Votre pseudo ne doit pas contenir d'espaces, veuillez les retirer");
                setPseudoValid(false);
                setShowBackdrop(false);
                return;
            } else {
                if (await checkIfPseudoExists(formData.pseudo)){
                    handleOpenSnackbar("Ce pseudo existe déjà :/ Veuillez en choisir un autre");
                    setPseudoValid(false);
                    setShowBackdrop(false);
                    return;
                } else {
                    setPseudoValid(true);
                }
            }
        } else {
            //just to avoid confusion
            setPseudoValid(true);
        }


        //urlPerso logic
        if (formData.urlPerso.toLowerCase()!==userObject.urlPerso.toLowerCase()){
            if (formData.urlPerso.toLowerCase().length<1){
                setUrlValid(false);
                handleOpenSnackbar("Votre URL doit contenir au moins 1 charactère.");
                setShowBackdrop(false);
                return;
            }
            else if (!isValidURL(formData.urlPerso.toLowerCase())){
                setUrlValid(false);
                handleOpenSnackbar("Entrez uniquement la personnalisation de l'URL, pas de https ni de nom de domaine. L'URL doit contenir des champs valides, pas d'espaces ni de '+'");
                setShowBackdrop(false);
                return;
            }
            else if (await checkIfUrlExists(formData.urlPerso.toLowerCase())){
                handleOpenSnackbar("Cette URL existe déjà, choisissez en une autre");
                setUrlValid(false);
                setShowBackdrop(false);
                return;
            } else {
                setUrlValid(true);
            }
        } else {
            setUrlValid(true);
        }


        //Todo avoid image conservation; delete when link is length == 0
        let newLink = "";
        //file logic
        if (file){
            try {
                newLink = await handleUpload(file);
            } catch (e){
                handleOpenSnackbar("Quelques chose s'est mal passé, contactez nous => ", e);
                setShowBackdrop(false);
                return;
            }
        } else if (imageUrl.length>0) {
            newLink = imageUrl;
        }

        //Social links logic dont need any check except format - we take the current content and upload to db

        //Presentation logic dont need any check - we take the current content and upload to db

        //theme logic
        if (themValue.length===0){
            handleOpenSnackbar("Vous devez choisir au moins un thème. Si vous ne trouvez pas le votre, contactez nous :)");
            setThemValid(false);
            setShowBackdrop(false);
            return;
        } else {
            setThemValid(true);
        }

        const dataToUpdate = {
            imageUrl: newLink,
            urlPerso: formData.urlPerso.toLowerCase(),
            pseudo: formData.pseudo,
            presentation: formData.pres,
            socialLinks: socialForDb,
            themeValues: themValue
        };

        try {
            await updateUserDocument(firebase.auth().currentUser.uid, dataToUpdate);
            setShowBackdrop(false);
            navigate("/home" , { replace: true });
        } catch (e){
            console.log(e);
            handleOpenSnackbar("Quelque chose s'est mal passé :/ Contactez nous => ", e);
        }
        setShowBackdrop(false);

        //todo prepare data for db and send data

    }

 /*   const isOptionEqualToValue = (option, value) => {
        console.log("option ", option);
        console.log("value ", value);
        return option.label.toString() === value.label.toString();
    };*/

    //Navigation
    const navigateToHome = () => {
        navigate("/home");
    };

    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    const formattedLabel = (
        <label className="label-style">
            faismoibg.com/
            <span className="bold-part">{formData.urlPerso.toLowerCase()}</span>
        </label>
    );

    //Manage loading here - or Add better loading eg Skeleton
    /*if (Object.keys(userObject).length === 0) {
        return <div>Loading...</div>;
    }
*/

    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            {showSkeleton ? (
                <div className="sub-container-no-top">
                    <div style={{display:"flex", width:"100%", flexDirection:"column",
                        justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                        <Skeleton style={{marginTop:"5px", borderRadius:"15px"}} variant="rectangular" width="70%" height={30}/>
                        <Skeleton style={{marginTop:"52px", placeSelf:"start"}} variant="circular" width={100} height={100} />
                        <Skeleton style={{borderRadius:"5px", marginTop:"20px", placeSelf:"start"}} variant="text" sx={{ fontSize: '2rem' }} width="70%" />
                        <Skeleton style={{marginTop:"30px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                    </div>
                </div>
            ) : (
            <div className="sub-container-no-top">
                <span className="title-style">Modifiez votre profil</span>
                <div className="field-container">
                    <div className="title-field-container">
                              <span className="text-field-style">
                                  Photo
                              </span>
                    </div>
                </div>
                <div style={{display:"flex", justifyContent:"start", flexDirection:"column", alignItems:"start", alignContent:"start", width:"100%"}}>
                    <div style={{flexDirection:"row", display:"flex", alignItems:"center"}}>
                        <div
                            style={{
                                width: '100px',
                                height: '100px',
                                borderRadius: '50%',
                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
                                margin: 'auto',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                overflow: 'visible',
                                backgroundColor:'#F1F1F1',
                                position:"relative"
                            }}
                        >
                            {imageUrl ? (
                                <img onLoad={handleImageLoad}
                                     className="fade-in" src={imageUrl} alt="Uploaded" style={{ borderRadius: '50%', width: '100%', height: '100%', objectFit: 'cover' }} />
                            ) : (
                                <img src={rec} style={{ borderRadius: '50%', width: '110px', height: '110px', objectFit: 'cover' }} ></img>
                            )}
                        </div>
                    </div>

                    <div style={{ color:"white", display:"flex", alignContent:'start', alignItems:'start', justifyContent:'start', marginTop:"10px"}}>
                        <Button disableElevation={true} className="button-style-noborders-lil" component="label" variant="contained" style={{backgroundColor:"black", color:"white", display:"flex", textAlign:"center"}}>
                            <Typography variant="button" style={{ textTransform: 'none', width:"100%", fontSize:"14px", fontWeight:"400"}}>
                                Ajouter
                            </Typography>
                            <input accept="image/jpeg,image/png,image/gif,image/svg+xml"
                                   id="file-input" style={{ display: 'none' }} type="file" onChange={handleFileChange} />
                        </Button>
                        <div style={{ color:"white", display:"flex", alignContent:'center', alignItems:'center', justifyContent:'center', marginLeft:"13px"}}>
                            <IconButton className="button-style-borders-icon" variant="contained" onClick={deleteImage} style={{ color:"white", display:"flex", padding:"10px"}}>
                                <img src={trashlogo} style={{color:"black", width:"20px", height:"20px"}}></img>
                            </IconButton>
                        </div>
                    </div>
                </div>

                <div className="field-container-b">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Pseudo <span className="aster">*</span>
                                  </span>
                    </div>
                    <TextField className="field-style" id="outlined-basic" label="Ecrire" variant="outlined" value={formData.pseudo}
                               onChange={handleFormChange} name="pseudo" error={!isPseudoValid}/>
                </div>

                <div className="field-container-b">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Thématique <span className="aster">*</span>
                                  </span>
                    </div>

                    {/*<Autocomplete className="custom-autocomplete button-autocomplete" style={{background:"#F1F5F8", borderRadius:"15px"}}
                        disablePortal
                        id="combo-box-demo"
                        options={thematiques}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Thématique" />}
                    />*/}

                    <div style={{width:"100%"}}>
                        <Autocomplete
                            PopperComponent={(props) => (
                                <div style={{display:"flex", flexDirection:"column"}}>
                                    {props.children}
                                </div>
                            )}
                            className="custom-autocomplete button-autocomplete" style={{background:"#F1F5F8", borderRadius:"15px", width:"100%"}}
                                      multiple
                                      disableClearable
                                      id="fixed-tags-demo"
                                      onOpen={() => setPopperOpen(true)}
                                      onClose={() => setPopperOpen(false)}
                                      open={isPopperOpen}
                                      value={themValue}
                                      // isOptionEqualToValue={isOptionEqualToValue}
                                      disablePortal
                                      onChange={(event, newValue) => {
                                          const uniqueTags = newValue.filter(
                                              (tag, index) => newValue.findIndex((t) => t.label === tag.label) === index
                                          );
                                          setThemValue(uniqueTags)
                                      }}
                                      options={thematiques.filter(
                                          (option) => !themValue.find((tag) => tag.label === option.label)
                                      )}
                                      getOptionLabel={(option) => option?.label || ''}
                                      renderTags={(tagValue, getTagProps) =>
                                          tagValue.map((option, index) => (
                                              <Chip style={{background:"linear-gradient(90deg, #4808FF 0%, #9747FF 100%)", color:"white", fontWeight:"400"}}
                                                    label={option?.label}
                                                    deleteIcon={<img src={deleteic} style={{width:"20px", height:"20px", marginRight:"8px"}}></img>}
                                                    {...getTagProps({ index })}
                                              />
                                          ))
                                      }
                                      disableCloseOnSelect
                                      renderOption={(props, option, { selected }) => {
                                          if (selected) {
                                              return null;
                                          }
                                          return <li style={getOptionStyle()} {...props}>{option.label}</li>;
                                      }}
                                     renderInput={({ inputProps, ...params }) => (
                                        <TextField {...params}
                                                   label="Choix"
                                                   inputProps={{ ...inputProps, readOnly: true }}
                                                   error={!isthemValid}
                                                   placeholder="Séléctionner une thématique" />

                                      )}
                        />

                    </div>
                </div>

                <div className="field-container-b">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Url personnalisée de votre page <span className="aster">*</span>
                                  </span>
                    </div>
                    <TextField className="field-style" id="outlined-basic" label={formattedLabel} variant="outlined" value={formData.urlPerso.toLowerCase()}
                               onChange={handleFormChange} name="urlPerso" error={!isUrlValid}/>
                </div>

                <div className="field-container-b">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Présentation
                                  </span>
                    </div>
                    <TextField className="field-style" label="Ecrire" variant="outlined" value={formData.pres}
                               multiline
                               maxRows={12}
                               onChange={handleFormPresChange} name="pres"/>
                </div>

                <div className="field-container-b">
                    <div className="title-field-container">
                                  <span style={{fontWeight:"700", color:"black", fontSize:"16px"}}>
                                      Réseaux sociaux
                                  </span>
                    </div>
                </div>

                <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
                    {socialLinks.map((socialLink) => (
                        <div key={socialLink.network} style={{ display: 'flex', marginTop:"10px", alignItems: 'center', alignContent:"space-evenly", justifyContent:"space-evenly", marginBottom: '10px', width:"100%" }}>
                            {socialLink.network === 'instagram' &&<div style={{width:"20%"}}> <FaInstagram  style={{ marginRight: '10px', height:"40px", width:"40px" }} /></div>}
                            {socialLink.network === 'facebook' && <div style={{width:"20%"}}><FaFacebook style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'tiktok' && <div style={{width:"20%"}}><FaTiktok style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'messenger' && <div style={{width:"20%"}}><FaFacebookMessenger style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'pinterest' && <div style={{width:"20%"}}><FaPinterest style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'snapchat' && <div style={{width:"20%"}}><FaSnapchat style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'whatsapp' && <div style={{width:"20%"}}><FaWhatsapp style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'paypal' && <div style={{width:"20%"}}><FaPaypal style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'lydia' && <div style={{width:"20%"}}> <img src={icLydia} style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'paylib' && <div style={{width:"20%"}}> <img src={icPaylib} style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'stripe' && <div style={{width:"20%"}}><FaStripe style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'sumup' && <div style={{width:"20%"}}> <img src={icSumUp} style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'wise' && <div style={{width:"20%"}}><img src={icWise} style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'revolut' && <div style={{width:"20%"}}> <img src={icRevolut} style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'linktree' && <div style={{width:"20%"}}> <img src={icLinktree} style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'linkinbio' && <div style={{width:"20%"}}> <FaPaperclip style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'beacons' && <div style={{width:"20%"}}> <img src={icBeacons} style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'allmylinks' && <div style={{width:"20%"}}> <img src={icAllMyLinks} style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'youtube' && <div style={{width:"20%"}}> <FaYoutube style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'dailymotion' && <div style={{width:"20%"}}> <FaDailymotion style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'site' && <div style={{width:"20%"}}> <FaGlobe style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            {socialLink.network === 'email' && <div style={{width:"20%"}}> <FaEnvelope style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                            <TextField
                                label={ socialLink.network==='email' ? "Ex: mailto:example@gmail.com" : `Lien ${socialLink.network}`}
                                variant="outlined"
                                fullWidth
                                value={socialLink.link}
                                onChange={(event) => handleLinkChange(socialLink.network, event)}
                            />
                            <div style={{width:"20%", display:"flex", justifyContent:"end", marginLeft:"6px"}}>
                                <IconButton className="button-style-borders-icon" variant="contained" size="medium" onClick={() => handleDeleteLink(socialLink.network)}  style={{ color:"white", display:"flex", justifySelf:"end", padding:"10px"}}>
                                    <img src={trashlogo} style={{color:"black", width:"20px", height:"20px"}}></img>
                                </IconButton>
                            </div>
                        </div>
                    ))}
                    <div style={{ color:"white", display:"flex", alignSelf:"start", alignContent:'start', alignItems:'start', justifyContent:'center', marginTop:"14px"}}>
                        <Button disableElevation={true}  className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}} onClick={handleAddLink}>
                            <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px" }}>
                                Ajouter
                            </Typography>
                        </Button>
                    </div>

                    <div className="button-container" style={{marginTop:"60px"}}>
                        <Button onClick={handleFormSubmit} className="button-style button-style-noborders" variant="contained">
                            <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                Enregistrer
                            </Typography>
                        </Button>
                    </div>

                    <div className="button-container" style={{marginTop:"15px"}}>
                        <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-borders" disableElevation={true}  variant="contained" onClick={navigateToHome}>
                            <Typography variant="button" style={{ textTransform: 'none', fontWeight:"400" }}>
                                Annuler
                            </Typography>
                        </Button>
                    </div>


                    <Dialog fullWidth={!isLargeScreen}
                            PaperProps={{
                                style: {
                                    minWidth: isLargeScreen ? '500px' : 'auto',
                                    maxWidth: isLargeScreen ? '500px' : 'auto',
                                },
                            }} open={isDialogOpen} onClose={handleDialogClose} style={{minWidth:"200px"}}>
                        <DialogContent style={{backgroundColor:"white"}}>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <span style={{fontWeight:700, fontSize:"16px"}}>
                                    Ajouter un réseau social
                                </span>
                            </div>
                            <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap",marginTop:"30px"}}>
                                <div style={{display:"flex", marginTop:"10px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'instagram') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'instagram') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('instagram')}
                                            disabled={socialLinks.some((link) => link.network === 'instagram')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Instagram
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'facebook') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'facebook') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('facebook')}
                                            disabled={socialLinks.some((link) => link.network === 'facebook')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Facebook
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'tiktok') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'tiktok') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('tiktok')}
                                            disabled={socialLinks.some((link) => link.network === 'tiktok')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            TikTok
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'messenger') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'messenger') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('messenger')}
                                            disabled={socialLinks.some((link) => link.network === 'messenger')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Messenger
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'whatsapp') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'whatsapp') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('whatsapp')}
                                            disabled={socialLinks.some((link) => link.network === 'whatsapp')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Whatsapp
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'pinterest') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'pinterest') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('pinterest')}
                                            disabled={socialLinks.some((link) => link.network === 'pinterest')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Pinterest
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'snapchat') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'snapchat') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('snapchat')}
                                            disabled={socialLinks.some((link) => link.network === 'snapchat')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Snapchat
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'paypal') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'paypal') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('paypal')}
                                            disabled={socialLinks.some((link) => link.network === 'paypal')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Paypal
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'lydia') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'lydia') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('lydia')}
                                            disabled={socialLinks.some((link) => link.network === 'lydia')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Lydia
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'paylib') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'paylib') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('paylib')}
                                            disabled={socialLinks.some((link) => link.network === 'paylib')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Paylib
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'stripe') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'stripe') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('stripe')}
                                            disabled={socialLinks.some((link) => link.network === 'stripe')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Stripe
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'sumup') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'sumup') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('sumup')}
                                            disabled={socialLinks.some((link) => link.network === 'sumup')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            SumUp
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'wise') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'wise') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('wise')}
                                            disabled={socialLinks.some((link) => link.network === 'wise')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Wise
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'revolut') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'revolut') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('revolut')}
                                            disabled={socialLinks.some((link) => link.network === 'revolut')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Revolut
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'linktree') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'linktree') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('linktree')}
                                            disabled={socialLinks.some((link) => link.network === 'linktree')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Linktree
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'linkinbio') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'linkinbio') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('linkinbio')}
                                            disabled={socialLinks.some((link) => link.network === 'linkinbio')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Linkinbio
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'beacons') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'beacons') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('beacons')}
                                            disabled={socialLinks.some((link) => link.network === 'beacons')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Beacons
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'allmylinks') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'allmylinks') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('allmylinks')}
                                            disabled={socialLinks.some((link) => link.network === 'allmylinks')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            AllMyLinks
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'youtube') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'youtube') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('youtube')}
                                            disabled={socialLinks.some((link) => link.network === 'youtube')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Youtube
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'site') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'site') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('site')}
                                            disabled={socialLinks.some((link) => link.network === 'site')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Site internet
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                    <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                        backgroundColor: socialLinks.some((link) => link.network === 'email') ? "black" : "white",
                                        color: socialLinks.some((link) => link.network === 'email') ? "white" : "black",
                                    }}
                                            onClick={() => handleDialogOptionClick('email')}
                                            disabled={socialLinks.some((link) => link.network === 'email')}
                                    >
                                        <Typography variant="button" style={{ textTransform: 'none'}}>
                                            Email
                                        </Typography>
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>

                <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={showBackdrop}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>)}
        </div>
    )

}