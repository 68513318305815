import testlogo from "../assets/goodlogo.png";
import React from "react";
import {useNavigate} from "react-router-dom";

export function HeaderBeforeLogin() {

    const navigate = useNavigate();
    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };
    const navigateRoot = () => {
        navigate("/")
    };



    return (
        <img onClick={navigateRoot} onLoad={event => handleImageLoad(event)}
             className="fade-in white"   src={testlogo} alt="fmbg" style={{minHeight:"45px", width:"133px", height:"46px", marginTop:"20px", cursor:"pointer"}}/>
    )

}