import {useDataContext} from "./datacontext";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import firebase from "firebase/compat/app";
import 'firebase/firestore'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import {getUserPostCreateStatus} from "../firebase";
import SnackbarWithCustomBackground from "./snackbar";
import {HeaderHome} from "./headerHome";
import Button from "@mui/material/Button";
import {
    Dialog,
    DialogContent,
    IconButton,
    Skeleton,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {
    FaDailymotion, FaEnvelope,
    FaFacebook,
    FaFacebookMessenger, FaGlobe,
    FaInstagram, FaPaperclip,
    FaPinterest,
    FaSnapchat, FaStripe,
    FaTiktok,
    FaWhatsapp, FaYoutube
} from "react-icons/fa";
import {FaPaypal} from "react-icons/fa6";
import icLydia from "../assets/iconLydia.svg";
import icPaylib from "../assets/iconPaylib.svg";
import icSumUp from "../assets/iconSumUp.svg";
import icWise from "../assets/iconWise.svg";
import icRevolut from "../assets/iconRevolut.svg";
import icLinktree from "../assets/iconLinktree.svg";
import icBeacons from "../assets/iconBeacons.svg";
import icAllMyLinks from "../assets/iconAllMyLinks.svg";
import trashlogo from "../assets/trashic.svg";

export default function Deposit() {


    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    const { data, updateData } = useDataContext();

    const { pathname } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const [showBackdrop, setShowBackdrop] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(true);

    const [deposit, setDeposit] = useState(
        {
            amount : "",
            paymentLinks: [],
        },
    )

    useEffect( () => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged( async(user) => {
            if (!user) {
                navigate("/");
            } else {
                setShowBackdrop(true);

                const fetchData = async () => {
                    try {
                        const postCreateDone = await getUserPostCreateStatus(user.uid);
                        return postCreateDone;
                    } catch (error) {
                        console.error('Error retrieving postCreateDone status:', error);
                    }
                };
                // Call the fetchData function directly
                const displayPage = await fetchData(user.uid);
                if (!displayPage){
                    //User needs to setup minimal info before seeing this page
                    setShowBackdrop(false);
                    navigate("/info" , { replace: true });
                } else {
                    if (data.formData.isDeposit){
                        setDeposit(data.deposit);
                    }
                }
                setShowSkeleton(false);
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => {
            unregisterAuthObserver();
        }
    }, []);

    const handleLinkChange = (network, event) => {
        const updatedLinks = deposit.paymentLinks.map((link) => {
            if (link.network === network) {
                return { ...link, link: event.target.value.trim() };
            }
            return link;
        });
        setDeposit({
            ...deposit,
            paymentLinks:updatedLinks
        });
    };

    const handleDeleteLink = (network) => {
        const updatedLinks = deposit.paymentLinks.filter((link) => link.network !== network);
        setDeposit({
            ...deposit,
            paymentLinks:updatedLinks
        });
    };


    const handleAddLink = () => {
        setIsDialogOpen(true);
    };

    const handleAmountChange = (event) => {
        setDeposit({
            ...deposit,
            amount : event.target.value,
        })
    };


    const handleDeleteDeposit = async () => {
        updateData({
            deposit:{
                amount : "",
                paymentLinks: [],
            },
            formData : {
                ...data.formData,
                isDeposit : false
            }
        })
        await navigateBack()
    }

    const handleFormSubmit = async () => {

        if (deposit?.amount?.length<1){
            handleOpenSnackbar("Vous devez ajouter un montant pour enregistrer.");
            return;
        }

        if (deposit.paymentLinks.length<1){
            handleOpenSnackbar("Vous devez ajouter au moins un lien de paiement pour enregistrer.");
            return;
        }

        for (let paymentLink of deposit.paymentLinks){
            if (paymentLink.link.length<7 || !paymentLink.link.includes('https://')){
                handleOpenSnackbar("Votre lien de paiement ne semble pas correct, vérifiez le.");
                return;
            }
        }

        updateData({
            deposit:{...deposit},
            formData : {
                ...data.formData,
                isDeposit : true
            }
        });

        await navigateBack();

    }

    const navigateBack = async () => {
        navigate(-1);
    }
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleDialogOptionClick = (network) => {
        const isNetworkSelected = deposit.paymentLinks.some((link) => link.network === network);
        if (!isNetworkSelected) {
            const updatedLinks = [...deposit.paymentLinks, { network, link: '' }];
            setDeposit({
                ...deposit,
                paymentLinks: updatedLinks
            });
        }
        setIsDialogOpen(false);
    };

    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            <div className="sub-container-no-top">
                <span className="title-style">{data.formData.isDeposit ? "Modifier l'acompte" : "Intégrer un acompte"}</span>
                {showSkeleton ?
                    (
                        <div className="sub-container-no-top">
                            <div style={{display:"flex", width:"100%", flexDirection:"column",
                                justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                                <Skeleton style={{marginTop:"30px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                                <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                            </div>
                        </div>
                    ) : (
                        <div className="sub-container-no-top">

                        </div>
                            )
                }
                <div className="field-container-b">
                    <div className="title-field-container">
                                      <span className="text-field-style">
                                         Montant
                                      </span>
                    </div>
                    <TextField className="field-style" id="outlined-basic" label="Ecrire" variant="outlined" value={deposit.amount}
                               style={{width:"50%", display:"flex", alignSelf:"start"}}
                               onChange={handleAmountChange} name="amount"/>
                </div>

                <div className="field-container-b">
                    <div className="title-field-container">
                                      <span className="text-field-style">
                                         Page de paiement
                                      </span>
                    </div>
                </div>
                <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
                    {deposit?.paymentLinks?.length>0 ? (
                        deposit.paymentLinks?.map((socialLink) => (
                            <div key={socialLink.network} style={{ display: 'flex', marginTop:"10px", alignItems: 'center', alignContent:"space-evenly", justifyContent:"space-evenly", marginBottom: '10px', width:"100%" }}>
                                {socialLink.network === 'paypal' && <div style={{width:"20%"}}><FaPaypal style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                                {socialLink.network === 'lydia' && <div style={{width:"20%"}}> <img src={icLydia} style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                                {socialLink.network === 'paylib' && <div style={{width:"20%"}}> <img src={icPaylib} style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                                {socialLink.network === 'stripe' && <div style={{width:"20%"}}><FaStripe style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                                {socialLink.network === 'sumup' && <div style={{width:"20%"}}> <img src={icSumUp} style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                                {socialLink.network === 'wise' && <div style={{width:"20%"}}><img src={icWise} style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                                {socialLink.network === 'revolut' && <div style={{width:"20%"}}> <img src={icRevolut} style={{ marginRight: '10px', width:"40px", height:"40px" }} /></div>}
                                <TextField
                                    label={ socialLink.network==='email' ? "Ex: mailto:example@gmail.com" : `Lien ${socialLink.network}`}
                                    variant="outlined"
                                    fullWidth
                                    value={socialLink.link}
                                    onChange={(event) => handleLinkChange(socialLink.network, event)}
                                />
                                <div style={{width:"20%", display:"flex", justifyContent:"end", marginLeft:"6px"}}>
                                    <IconButton className="button-style-borders-icon" variant="contained" size="medium" onClick={() => handleDeleteLink(socialLink.network)}  style={{ color:"white", display:"flex", justifySelf:"end", padding:"10px"}}>
                                        <img src={trashlogo} style={{color:"black", width:"20px", height:"20px"}}></img>
                                    </IconButton>
                                </div>
                            </div>
                        ))) :
                        (
                            <span></span>
                        )}

                    <div style={{ color:"white", display:"flex", alignSelf:"start", alignContent:'start', alignItems:'start', justifyContent:'center', marginTop:"14px"}}>
                        <Button disableElevation={true}  className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}} onClick={handleAddLink}>
                            <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px" }}>
                                Ajouter un lien
                            </Typography>
                        </Button>
                    </div>
                </div>
                <div className="button-container" style={{marginTop:"70px"}}>
                    <Button onClick={handleFormSubmit} className="button-style button-style-noborders" variant="contained">
                        <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                            Enregistrer
                        </Typography>
                    </Button>
                </div>

                <div className="button-container" style={{marginTop:"15px"}}>
                    <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateBack}>
                        <Typography variant="button" style={{ textTransform: 'none' }}>
                            Annuler
                        </Typography>
                    </Button>
                </div>
                <div  className="button-container">
                    <Button style={{marginBottom:"80px"}} className="button-style-nogr button-style-noborders" disableElevation variant="contained" onClick={handleDeleteDeposit}>
                        <Typography variant="button" style={{ textTransform: 'none', textDecoration: "underline" }}>
                            Supprimer l'acompte
                        </Typography>
                    </Button>
                </div>
                <Dialog fullWidth={!isLargeScreen}
                        PaperProps={{
                            style: {
                                minWidth: isLargeScreen ? '500px' : 'auto',
                                maxWidth: isLargeScreen ? '500px' : 'auto',
                            },
                        }} open={isDialogOpen} onClose={handleDialogClose} style={{minWidth:"200px"}}>
                    <DialogContent style={{backgroundColor:"white"}}>
                        <div style={{display:"flex", flexDirection:"column"}}>
                                        <span style={{fontWeight:700, fontSize:"16px"}}>
                                            Ajouter un lien de paiement
                                        </span>
                        </div>
                        <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap",marginTop:"30px"}}>
                            <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                    backgroundColor: deposit?.paymentLinks?.some((link) => link.network === 'paypal') ? "black" : "white",
                                    color: deposit?.paymentLinks?.some((link) => link.network === 'paypal') ? "white" : "black",
                                }}
                                        onClick={() => handleDialogOptionClick('paypal')}
                                        disabled={deposit?.paymentLinks?.some((link) => link.network === 'paypal')}
                                >
                                    <Typography variant="button" style={{ textTransform: 'none'}}>
                                        Paypal
                                    </Typography>
                                </Button>
                            </div>
                            <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                    backgroundColor: deposit?.paymentLinks?.some((link) => link.network === 'lydia') ? "black" : "white",
                                    color: deposit?.paymentLinks?.some((link) => link.network === 'lydia') ? "white" : "black",
                                }}
                                        onClick={() => handleDialogOptionClick('lydia')}
                                        disabled={deposit?.paymentLinks?.some((link) => link.network === 'lydia')}
                                >
                                    <Typography variant="button" style={{ textTransform: 'none'}}>
                                        Lydia
                                    </Typography>
                                </Button>
                            </div>
                            <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                    backgroundColor: deposit?.paymentLinks?.some((link) => link.network === 'paylib') ? "black" : "white",
                                    color: deposit?.paymentLinks?.some((link) => link.network === 'paylib') ? "white" : "black",
                                }}
                                        onClick={() => handleDialogOptionClick('paylib')}
                                        disabled={deposit?.paymentLinks?.some((link) => link.network === 'paylib')}
                                >
                                    <Typography variant="button" style={{ textTransform: 'none'}}>
                                        Paylib
                                    </Typography>
                                </Button>
                            </div>
                            <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                    backgroundColor: deposit?.paymentLinks?.some((link) => link.network === 'stripe') ? "black" : "white",
                                    color: deposit?.paymentLinks?.some((link) => link.network === 'stripe') ? "white" : "black",
                                }}
                                        onClick={() => handleDialogOptionClick('stripe')}
                                        disabled={deposit?.paymentLinks?.some((link) => link.network === 'stripe')}
                                >
                                    <Typography variant="button" style={{ textTransform: 'none'}}>
                                        Stripe
                                    </Typography>
                                </Button>
                            </div>
                            <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                    backgroundColor: deposit?.paymentLinks?.some((link) => link.network === 'sumup') ? "black" : "white",
                                    color: deposit?.paymentLinks?.some((link) => link.network === 'sumup') ? "white" : "black",
                                }}
                                        onClick={() => handleDialogOptionClick('sumup')}
                                        disabled={deposit?.paymentLinks?.some((link) => link.network === 'sumup')}
                                >
                                    <Typography variant="button" style={{ textTransform: 'none'}}>
                                        SumUp
                                    </Typography>
                                </Button>
                            </div>
                            <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                    backgroundColor: deposit?.paymentLinks?.some((link) => link.network === 'wise') ? "black" : "white",
                                    color: deposit?.paymentLinks?.some((link) => link.network === 'wise') ? "white" : "black",
                                }}
                                        onClick={() => handleDialogOptionClick('wise')}
                                        disabled={deposit?.paymentLinks?.some((link) => link.network === 'wise')}
                                >
                                    <Typography variant="button" style={{ textTransform: 'none'}}>
                                        Wise
                                    </Typography>
                                </Button>
                            </div>
                            <div style={{display:"flex", marginTop:"10px", marginLeft:"4px"}}>
                                <Button className="button-style-noborders-lil" style={{border:"1px solid black",
                                    backgroundColor: deposit?.paymentLinks?.some((link) => link.network === 'revolut') ? "black" : "white",
                                    color: deposit?.paymentLinks?.some((link) => link.network === 'revolut') ? "white" : "black",
                                }}
                                        onClick={() => handleDialogOptionClick('revolut')}
                                        disabled={deposit?.paymentLinks?.some((link) => link.network === 'revolut')}
                                >
                                    <Typography variant="button" style={{ textTransform: 'none'}}>
                                        Revolut
                                    </Typography>
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />
            </div>
        </div>
    )
}