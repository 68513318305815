import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import playIc from "../assets/playSvg.svg";
import imglogo from "../assets/imgIcon.svg";



const handleDragStart = (e) => e.preventDefault();


export const Gallery = (urls) => {

    const fallBackUrl = "https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA.."
    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    let displaydef = false;
    if (urls?.urls[0].imageLink.includes("static")) {
        displaydef=true;
    }

    const openLink = (link) => {
        window.open(link, '_blank');
    };

    const instaButtonStyle = {
        position: 'absolute',
        bottom: '20px',
        left: '20px',
        zIndex: 1,
        padding: '6px 12px',
        backgroundColor: '#F2F2F2',
        color:"black",
        opacity:"70%"
    };

    const typeIconStyle = {
        position: 'absolute',
        bottom: '20px',
        right: '20px',
        opacity:'90%',
        width: '30px',
        height: '30px',
        zIndex: 1,
    };

    const playMidStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity: '98%',
        width: '90px',
        height: '90px',
        zIndex: 1,
    };

    return (
        <AliceCarousel mouseTracking
                       items={urls.urls.map((url, index) => (
                           <div key={index} style={{position: "relative"}}>
                               <img
                                   onLoad={handleImageLoad}
                                   onError={(e) => {
                                       if (e.target.src!==fallBackUrl){
                                           e.target.onerror = null;
                                           e.target.src= fallBackUrl
                                       }
                                   }}
                                   className="fade-in"
                                   key={index}
                                   style={displaydef ? { objectFit: "contain" } : { objectFit: "cover" }}
                                   src={url.imageLink}
                                   onDragStart={handleDragStart}
                                   role="presentation"
                               />

                               {url.vlink.length>0 && (
                                   <div style={playMidStyle} onClick={() => openLink(url.vlink)}>
                                       <img className="importantwidthheightbis" style={{objectFit: "contain", cursor:"pointer"}} src={playIc} />
                                   </div>
                               )}

                               {/*{url.vlink.length>0 ? (
                                   <div style={typeIconStyle} onClick={() => openLink(url.vlink)}>
                                       <img className="importantwidthheight" style={{objectFit: "contain", cursor:"pointer"}} src={playIc} />
                                   </div>
                               ) : (
                                   <div style={typeIconStyle} >
                                       <img className="importantwidthheight" style={{objectFit: "contain"}} src={imglogo} />
                                   </div>
                               )}*/}
                           </div>
                       ))}
                       touchMoveDefaultEvents={true} disableButtonsControls={true}/>
    );
}