import React, {useEffect, useState} from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from "@mui/material/Button";
import {Typography} from "@mui/material";

const SnackbarWithCustomBackground = ({ isOpen, onClose, message }) => {
    const [open, setOpen] = useState(isOpen);
    const [smessage, setSmessage] = useState(message);
    const vertical = 'bottom';
    const horizontal= 'center';

    useEffect(() => {
        setOpen(isOpen);
        setSmessage(message);
    }, [isOpen]);
    const handleClose = () => {
        setOpen(false);
        if (onClose) {
            onClose();
        }
    };

    return (
            <Snackbar
                open={open}
                autoHideDuration={6000} // Snackbar will automatically disappear after 6 seconds
                onClose={handleClose}
                anchorOrigin={{vertical, horizontal}}
                message={smessage} // Replace this with your actual message
                action={
                    <>
                        <Button className="button-style-noborders" style={{color:"white"}} onClick={handleClose}>
                            <Typography variant="button" style={{ textTransform: 'none', marginLeft:"6px", marginRight:"6px", width:"100%" }}>
                                Compris
                            </Typography>
                        </Button>
                    </>
                }
            />
    );
};

export default SnackbarWithCustomBackground;