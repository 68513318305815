import React, {useEffect, useState} from "react";
import {Divider, styled, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {FaTimes, FaTrashAlt} from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import Switch, { SwitchProps } from '@mui/material/Switch';
import {useLocation, useNavigate} from "react-router-dom";
import SnackbarWithCustomBackground from "./snackbar";
import {useDataContext} from "./datacontext";
import firebase from "firebase/compat/app";
import 'firebase/firestore'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import {getUserPostCreateStatus} from "../firebase";
import trashlogo from "../assets/trashic.svg";
import {HeaderHome} from "./headerHome";

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch size="medium" focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 63,
    height: 38,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 6,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(23px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                background: 'linear-gradient(90deg, #FF0844 0%, #4808FF 100%)',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 28, // Adjusted width (smaller)
        height: 28, // Adjusted height (smaller)
        transform: 'translateY(-4%)',
        //transform: 'translateY(50%)', // Adjust vertical position
    },
    '& .MuiSwitch-track': {
        borderRadius: 39 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export default function Newquestionnaire() {


    const { data, updateData } = useDataContext();

    const { pathname } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const [showBackdrop, setShowBackdrop] = useState(false);

    useEffect( () => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged( async(user) => {
            if (!user) {
                navigate("/");
            } else {
                setShowBackdrop(true);

                //TODO Store the postCreateDone in context
                const fetchData = async () => {
                    try {
                        const postCreateDone = await getUserPostCreateStatus(user.uid);
                        return postCreateDone;
                    } catch (error) {
                        console.error('Error retrieving postCreateDone status:', error);
                    }
                };
                // Call the fetchData function directly
                const displayPage = await fetchData(user.uid);
                if (!displayPage){
                    //User needs to setup minimal info before seeing this page
                    setShowBackdrop(false);
                    navigate("/info" , { replace: true });
                } else {

                    if (data.isQuestionAdded){
                        setQuestions(data.questions);
                        setIntro(data.intro);
                    }

                }
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => {
            unregisterAuthObserver();
        }
    }, []);

    const deleteButtonStyle = {
        position: 'absolute',
        top: '5px',
        right: '5px',
        backgroundColor: 'grey',
        opacity:'60%',
        color: 'red',
        fontSize: '1.5rem',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        zIndex: 1,
    };

    const [checked, setChecked] = useState(true);
    const [intro, setIntro] = useState("");
    const [questions, setQuestions] = useState([
        /*{
            question : "",
            image: "",
            checked: false,
        },*/
        /*{
            question : "",
            image: "",
            checked: false
        },
        {
            question : "",
            image: "",
            checked: false
        },*/
    ])
    const handleChange = (event, index) => {
        const newQuestions = [...questions];
        newQuestions[index].checked = event.target.checked;
        setQuestions(newQuestions);
    };

    const handleQuestionChange = (index, newText) => {
        const newQuestions = [...questions];
        newQuestions[index] = { ...newQuestions[index], question: newText };
        setQuestions(newQuestions);
    };

    const handleAddQuestion = () => {
        const newQuestion = {
            question: "",
            image: "",
            checked: false,
        };
        setQuestions([...questions, newQuestion]);
    };

    const handleDeleteQuestion = (index) => {
        handleDeleteQuestionImage(index);
        const newQuestions = [...questions];
        newQuestions.splice(index, 1);
        setQuestions(newQuestions);
    };

    const handleImageUpload = (event, index) => {
        const file = event.target.files[0];
        if (file) {
            const newQuestions = [...questions];
            newQuestions[index].image = URL.createObjectURL(file);
            newQuestions[index].file = file;
            setQuestions(newQuestions);
        }
    };

    const handleDeleteQuestionImage = (index) => {
        if (questions[index].image.includes("firebasestorage")) {
            const updatedLinksToDelete = [
                ...(data.linksToDelete || []),
                questions[index].image
            ];
            updateData({
                ...data,
                linksToDelete: updatedLinksToDelete
            });
        }
    }
    const handleDeleteImage = (index) => {
        if (questions[index].image.includes("firebasestorage")){
            const updatedLinksToDelete = [
                ...(data.linksToDelete || []),
                questions[index].image
            ];
            updateData({
                ...data,
                linksToDelete: updatedLinksToDelete
            });
        }
        const newQuestions = [...questions];
        newQuestions[index].image = "";
        setQuestions(newQuestions);
        const fileInput = document.getElementById('file-input'+index);
        if (fileInput) {
            fileInput.value = null;
        }
    };


    const handleDeleteQuestionnaire = async () => {
        updateData({
            questions: [],
            isQuestionAdded: false,
            intro: ""
        })
        await navigateBack()
    }

    const handleFormSubmit = async () => {

        if (questions.length<1){
            handleOpenSnackbar("Vous devez ajouter des questions pour enregistrer");
            return;
        }

        for (let i = questions.length - 1; i >= 0; i--) {
            if (questions[i].question.length < 1) {
                //questions.splice(i, 1);
                handleOpenSnackbar("Vous devez remplir le champ 'question' ou supprimer des questions pour enregistrer");
                return;
            }
        }

        //just updating Context - final upload happens on new presta published
        if (questions.length>0){
            updateData({
                questions: questions,
                isQuestionAdded: true,
                intro: intro
            })
        } else {
            updateData({
                questions: [],
                isQuestionAdded: false,
                intro: ""
            })
        }
        await navigateBack();
    }
    const navigateBack = async () => {
        navigate(-1);
    }
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const handleTextChange = (event) => {
        const newText = event.target.value.replace(/\r?\n/g, '\n');
        setIntro(newText);
    };

    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            <div className="sub-container-no-top">
                <span className="title-style">Questionnaire</span>
                <div className="field-container-b">
                    <div className="title-field-container">
                                      <span className="text-field-style">
                                          Texte d'introduction
                                      </span>
                    </div>
                    {/*//todo review max lines / number of characters allowed with JCVD*/}
                    <TextField className="field-style" /*label={userObject.presentation} */ variant="outlined" /*value={formData.pres}*/
                               multiline
                               label="Ecrire"
                               maxRows={10}
                               value={intro}
                               onChange={handleTextChange}
                               /*onChange={handleFormChange} *//>
                </div>
                <div className="field-container">
                    <div className="title-field-container">
                                      <span className="text-field-style">
                                          Ajouter une question
                                      </span>
                    </div>
                </div>

                <div style={{marginTop:"5px", display:"flex", flexDirection:"column", width:"100%"}}>

                    {questions.map((object,index)=> (
                        <div style={{width:"100%", marginTop:"10px"}} key={index}>
                        <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                        <span style={{width:"60%", color:"#4808FF"}}>
                            Nouvelle question
                        </span>
                        <IconButton onClick={() => handleDeleteQuestion(index)} className="button-style-borders-icon" variant="contained" style={{ color:"white", display:"flex"}}>
                            <img src={trashlogo} style={{width:"20px", height:"20px"}}/>
                        </IconButton>
                    </div>
                    <div style={{width:"100%", display:"flex", flexDirection:"row", marginTop:"14px" }}>
                    <TextField className="field-style" label="Ecrire"  variant="outlined" value={object.question}
                               onChange={(evt) => handleQuestionChange(index, evt.target.value)} />

                </div>
                            {object.image && (
                                <div style={{width:"100%", marginTop:"20px", display:"flex",alignItems:"center", alignContent:"center", justifyContent:"center"}}>
                                    <div key={index} className="image-wrapper-q">
                                        <IconButton
                                            style={deleteButtonStyle}
                                            onClick={() => handleDeleteImage(index)}
                                        >
                                            <FaTimes style={{color:"black"}} />
                                        </IconButton>
                                        <img src={object.image} alt={`${index + 1}`} />
                                    </div>
                                </div>
                            )}

                <div style={{ marginTop:"22px" ,width:"100%", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                        <span style={{width:"60%", color:"black"}}>
                            Option d'insertion d'image
                        </span>
                    <IOSSwitch
                        checked={object.checked}
                        onChange={(event) => handleChange(event, index)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </div>
                            {/*{object.checked && (
                                <Button component="label" variant="contained" style={{marginTop:"16px", backgroundColor:"black", color:"white", display:"flex", width:"70%", textAlign:"center"}}>
                                    <Typography variant="button" style={{ textTransform: 'none', marginLeft:"6px", marginRight:"6px", width:"100%" }}>
                                        Ajouter une image
                                    </Typography>
                                    <input accept="image/jpeg,image/png,image/gif,image/svg+xml"
                                           id={"file-input" + index} style={{ display: 'none' }} type="file"
                                           onChange={(event) => handleImageUpload(event, index)}
                                    />
                                </Button>
                            )}*/}

                            {index!==questions.length-1 && (<Divider style={{marginTop:"30px"}}/>)}

                        </div>
                    ))}

                </div>


                <div style={{ textAlign:"center", color:"white", display:"flex", alignSelf:"start", alignContent:'start', alignItems:'start', justifyContent:'center', marginTop:"10px"}}>
                    <Button disableElevation onClick={handleAddQuestion} component="label" className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}}>
                        <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px" }}>
                            Ajouter une question
                        </Typography>
                    </Button>
                </div>

                <div className="button-container" style={{marginTop:"70px"}}>
                    <Button onClick={handleFormSubmit} className="button-style button-style-noborders" variant="contained">
                        <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                            Enregistrer
                        </Typography>
                    </Button>
                </div>

                <div className="button-container" style={{marginTop:"15px"}}>
                    <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateBack}>
                        <Typography variant="button" style={{ textTransform: 'none' }}>
                            Annuler
                        </Typography>
                    </Button>
                </div>
                <div  className="button-container">
                    <Button style={{marginBottom:"80px"}} className="button-style-nogr button-style-noborders" disableElevation variant="contained" onClick={handleDeleteQuestionnaire}>
                        <Typography variant="button" style={{ textTransform: 'none', textDecoration: "underline" }}>
                            Supprimer le questionnaire
                        </Typography>
                    </Button>
                </div>
                <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />
            </div>
        </div>
    )
}