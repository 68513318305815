import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {FaEye, FaGoogle, FaInstagram} from "react-icons/fa";
import testlogo from "../assets/goodlogo.png";
import Button from "@mui/material/Button";
import {Backdrop, CircularProgress, Divider, Snackbar, TextField, Typography} from "@mui/material";
import {createUserWithGoogle, loginEmailUser} from "../firebase";
import { useNavigate } from 'react-router-dom';
import firebase from "firebase/compat/app";
import 'firebase/firestore'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import IconButton from "@mui/material/IconButton";
import {FaEyeSlash} from "react-icons/fa6";
import SnackbarWithCustomBackground from "./snackbar";
import googleButton from "../assets/googleButton.svg"

const validateEmail = (email) => {
    // Basic email validation using a regular expression
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
};

function Login() {

    const { pathname } = useLocation();

    const navigate = useNavigate();

    const [showBackdrop, setShowBackdrop] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);

        const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // User is signed in, redirect to the new page
                navigate("/info");
            } else {
                // User is signed out, you can handle this case as needed
                setShowBackdrop(false);
            }
        });

        // Cleanup the observer when the component is unmounted
        return () => unregisterAuthObserver();
    }, [pathname]);


    const handleIconEyeClick = () => {
        setShowPassword(!showPassword);
    }
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };


    //Display before data loaded
    if (showBackdrop) {
        return <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showBackdrop}
        >
            <CircularProgress color="inherit" />
        </Backdrop>;
    }

    const handleLogin = async () => {

        if (password.length<6){
            handleOpenSnackbar("Votre mot de passe doit contenir au moins 6 caractères.");
            return;
        }

        if (!validateEmail(email)){
            handleOpenSnackbar("Votre adresse email ne semble pas valide.");
            return;
        }

        try {
            await loginEmailUser(email, password);
        } catch (error) {
            let errorMessage = '';
            console.log(error.code);
            switch (error.code) {
                case 'auth/invalid-email':
                case 'auth/wrong-password':
                    errorMessage = 'Adresse e-mail ou mot de passe invalide';
                    break;
                case 'auth/user-not-found':
                    errorMessage = "L'utilisateur n'a pas été trouvé";
                    break;
                case 'auth/user-disabled':
                    errorMessage = 'L\'utilisateur a été désactivé';
                    break;
                case 'auth/too-many-requests':
                    errorMessage = 'Trop de tentative, votre compte a été temporairement desactivé. Attendez un peu avant de réessayer ou réinitialisez votre mot de passe.';
                    break;
                // Add more cases as needed for other error types
                default:
                    errorMessage = 'Erreur lors de la connexion';
            }
            console.log("msg ", errorMessage);
            handleOpenSnackbar("Quelque chose s'est mal passé : " + errorMessage + ". Il est possible que vous deviez effectuer une connexion avec Google dans le cas où vous auriez effectué une connexion avec Google.");
            console.log("error ", error.message);
        }
    }



    const handleGoogleSignIn = async () => {
        try {
            const user = await createUserWithGoogle();
        } catch (error) {
            console.error('Error signing in with Google:', error);
        }
    };

    const handleResetPassword = () => {
        navigate("/resetpassword")
    };

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    return (

        <div className="sub-container">

            <div className="sub-container-no-top">
                {/*<Button disableElevation variant="contained" onClick={toggleTheme}>Toggle Theme</Button>*/}

                <div className="title-container">
                    <span className="title-style">Connexion</span>
                </div>

                <div className="field-container">
                    <div className="title-field-container">
                                      <span className="text-field-style">
                                          Email
                                      </span>
                    </div>
                    <TextField onChange={handleEmailChange} className="field-style" id="outlined-basic" label="Ecrire" variant="outlined" />
                </div>

                <div className="field-container-b">
                    <div className="title-field-container">
                                      <span className="text-field-style">
                                          Mot de passe
                                      </span>
                    </div>
                    <TextField type={showPassword ? "text" : "password"} className="field-style" label="Ecrire"
                               onChange={handlePasswordChange} variant="outlined"
                               InputProps={{
                                   endAdornment: (
                                       <IconButton onClick={handleIconEyeClick} edge="end">
                                           {showPassword ? <FaEye /> : <FaEyeSlash />}
                                       </IconButton>
                                   ),
                               }}/>
                </div>

                <div className="button-container">
                    <Button className="button-style button-style-noborders" variant="contained" onClick={handleLogin}>
                        <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                            Se connecter
                        </Typography>
                    </Button>
                </div>
                <div className="button-container-noborder">
                    <Button className="button-style-noborders-mdp" disableElevation onClick={handleResetPassword}>
                        <Typography variant="button" style={{ textTransform: 'none', fontWeight:400, textDecoration:"underline", lineHeight:"22px"}}>
                            Mot de passe oublié ?
                        </Typography>
                    </Button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', margin: '1rem', width:'300px', marginTop:'10px', position: 'relative' }}>
                    <Divider className="custom-divider" style={{ flexGrow: 1, background:'grey'}} />
                    <span style={{ margin: '0 0.5rem', fontWeight:'500', color:'grey' }}>OU</span>
                    <Divider className="custom-divider"  style={{ flexGrow: 1 , background:'grey' }} />
                </div>

                <div className="button-container b">
                    <Button disableElevation={true} onClick={handleGoogleSignIn} className="g-button" variant="contained" startIcon={<img style={{width:"20px", height:"20px", marginRight:"4px"}} src={googleButton}></img>}>
                        <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF', fontFamily:"Roboto Medium, sans-serif", fontWeight:400}}>
                            Se connecter avec Google
                        </Typography>
                    </Button>
                </div>

                <div className="button-container" style={{marginTop:"100px"}}>
                    <Button disableElevation={true} style={{ marginBottom:"100px"}} className="button-style-nogr button-style-borders" component={Link} to="/create" variant="contained">
                        <Typography variant="button" style={{ textTransform: 'none' }}>
                            S'inscrire
                        </Typography>
                    </Button>
                </div>
                <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />
            </div>

        </div>
    );
}

export default Login;