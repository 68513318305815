import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDataContext} from "./datacontext";
import {getPrestaDoc} from "../firebase";
import SnackbarWithCustomBackground from "./snackbar";
import {Backdrop, CircularProgress, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import firebase from "firebase/compat/app";
import 'firebase/firestore'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import {Gallery} from "./carouselpresta";
import {useParams} from "react-router";
import pinMapGrey from "../assets/ph_map-pin_grey.png";
import {HeaderHome} from "./headerHome";

const faImageStyle = {
    position: 'absolute',
    top: '5px',
    right: '5px',
    opacity:'80%',
    color: '#F2F2F2',
    fontSize: '1.5rem',
    width: '30px',
    height: '30px',
    zIndex: 1,
};

function durationFormatMin(duration){
    const [hours, minutes] = duration.split('h');
    return parseInt(hours) === 0 ? `${minutes} min` : `${duration}`;
}
export default function RdvPrestaB() {

    const { id } = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { data, updateData } = useDataContext();
    const getFreeTimeSlotsv2break = firebase.functions().httpsCallable('getFreeTimeSlotsv2break');
    const freeTimeSlotsFunction = firebase.functions().httpsCallable('getFreeTimeSlotsv2');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect( () => {
        updateData({});
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged( async(user) => {
            if (!user) {
                navigate("/");
            } else {
                setShowBackdrop(true);
                //warmup
                getFreeTimeSlotsv2break({isWarmUp:true}).catch((e)=>console.log(e));
                freeTimeSlotsFunction({isWarmUp:true}).catch((e)=>console.log(e));
                async function fetchPrestaData() {
                    try {
                        const userPresta = await getPrestaDoc(id);
                        if (userPresta!=null){
                            setPrestaObject(userPresta)
                            if (userPresta?.imageLinks[0]?.length<1){
                                setPrestaObject({
                                    ...userPresta,
                                    imageLinks:["https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA.."]
                                });
                            }
                        } else {
                            new Error("Presta doesn't exists")
                        }
                    } catch (e){
                        handleOpenSnackbar("Ooops quelque chose s'est mal passé, contactez nous et communiquez cette erreur => ", e);
                    }
                    setShowBackdrop(false);
                }
                await fetchPrestaData();
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => unregisterAuthObserver();
    }, []);

    const [showBackdrop, setShowBackdrop] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [prestaObject, setPrestaObject] = useState({});
    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const navigateToAppointment = () => {
        navigate("/appointdate/"+id);
    };

    const navigateBack = async () => {
        navigate('/rdvpresta');
    }

    if (Object.keys(prestaObject).length === 0) {
        return <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showBackdrop}
        >
            <CircularProgress color="inherit" />
        </Backdrop>;
    }

    return(
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            <div className="sub-container-no-top">
                <span className="title-style">Planifier un rendez-vous</span>

                <div style={{display:"flex", marginTop:"18px", width:"100%"}}>
                    <span className="title-style-presta">{prestaObject.title}</span>
                </div>

                <div style={{display:"flex", flexDirection:"row", marginTop:"10px", width:"100%"}}>
                    <div style={{display: "flex", padding: "5px 10px", alignItems: "center", gap: "10px", borderRadius:"99px", background:"#DAE0E5"}}>
                        <span style={{color: "#000", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "normal"}}>
                            {prestaObject.price}
                        </span>
                    </div>
                    <div style={{display: "flex", padding: "5px 10px", alignItems: "center", gap: "10px", borderRadius:"99px", background:"#DAE0E5", marginLeft:"10px"}}>
                        <span style={{color: "#000", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "normal"}}>
                            {durationFormatMin(prestaObject.prestaDuration)}
                        </span>
                    </div>
                </div>

                <div style={{display:"flex", width:"100%", marginTop:"20px"}}>
                    {prestaObject.imageLinks.length>0 ? <Gallery urls={prestaObject.imageLinks}>
                    </Gallery> : <Gallery urls={[{
                        imageLink:"https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA..",
                        vlink:""
                    }]}>
                    </Gallery>}

                </div>

                <span style={{fontSize:"16px", marginTop:"20px", display:"flex", width:"100%", whiteSpace:"pre-line"}}>
                    {prestaObject.description}
                </span>

                {prestaObject.selectedAddress.length > 2 ? (
                    <div
                        style={{
                            display: "flex",
                            marginTop: "15px",
                            minHeight: "10px",
                            borderRadius: "15px",
                            boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.25)",
                            width: "100%",
                            flexDirection: "column", // Change to column to stack items vertically
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop:"25px", marginRight:"5px" }}>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row", // Change to row to have icon and content side by side
                                alignItems: "center", // Center icon and content vertically
                                padding: "1px 20px", // Add padding to create some space around the content
                                marginBottom:"25px"
                            }}
                        >
                            <div style={{ width: "10%" }}>
                                <img src={pinMapGrey} style={{width:"30px", height:"30px"}}/>
                            </div>
                            <div
                                style={{
                                    width: "90%", // Adjusted width to make space for the close icon
                                    marginLeft: "10px",
                                }}
                            >
                                <span style={{color:"black"}}>{prestaObject.selectedAddress}</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <span style={{marginTop: "35px", width:"100%"}}>Contactez vos clients pour définir le lieu</span>
                )}

                <div className="button-container-noborder" style={{marginTop:"60px"}}>
                    <Button className="button-style button-style-noborders" variant="contained" onClick={navigateToAppointment}>
                        <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                            Prendre RDV
                        </Typography>
                    </Button>
                </div>

                <div className="button-container-noborder" style={{marginTop:"15px"}}>
                    <Button style={{marginBottom:"80px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateBack}>
                        <Typography variant="button" style={{ textTransform: 'none' }}>
                            Retour
                        </Typography>
                    </Button>
                </div>

                <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />
            </div>
        </div>
    )
}