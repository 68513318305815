import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const TimeBeforePrestaPicker = ({ onTimeSelected, selectedTime }) => {
    const timeOptions = [
        '1h', '2h', '3h', '4h', '8h', '12h',
        '1j', '2j', '4j', '5j', '6j', '7j'
    ];

    const [value, setValue] = useState(selectedTime);

    useEffect(() => {
        if (selectedTime && selectedTime.length > 2) {
            setValue(selectedTime);
        }
    }, [selectedTime]);

    const handleTimeChange = (_, newValue) => {
        setValue(newValue);
        onTimeSelected(newValue);
    };

    const getOptionStyle = () => ({
        borderRadius: "15px",
        margin: "10px 0",
        background: "#DAE0E5",
        padding: "15px",
        color: "#000",
    });

    return (
        <div style={{width:"100%"}}>
            <Autocomplete
                PopperComponent={(props) => (
                    <div style={{display:"flex", flexDirection:"column"}}>
                        {props.children}
                    </div>
                )}
                disableClearable={true}
                className="custom-autocomplete button-autocomplete"
                style={{ background: "#F1F5F8", borderRadius: "15px", width: "100%" }}
                onChange={handleTimeChange}
                value={value}
                options={timeOptions}
                renderOption={(props, option, { selected }) => {
                    return <li style={getOptionStyle()} {...props}>{option}</li>;
                }}
                renderInput={({ inputProps, ...params }) => (
                    <TextField {...params}
                                 label="Durée"
                                 inputProps={{ ...inputProps, readOnly: true }}/>
                )}
            />
        </div>
    );
};

export default TimeBeforePrestaPicker;
