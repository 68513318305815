import React, { createContext, useContext, useState } from 'react';

const DataContext = createContext();

export const useDataContext = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
    const [data, setData] = useState({});

    // Function to update the data
    const updateData = (updatedState) => {
        if (Object.keys(updatedState).length === 0) {
            // If the updatedState is an empty object, clear the data
            setData({});
        } else {
            setData((prevData) => ({ ...prevData, ...updatedState }));
        }
    };

    return (
        <DataContext.Provider value={{ data, updateData }}>
            {children}
        </DataContext.Provider>
    );
};