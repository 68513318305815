import React, {useEffect, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const TimePickerWholeDay = ({ onTimeSelected, selectedTime, disabled, isStart, selectedOtherValue, timezone }) => {
    // Generate time options with 10-minute steps from 0 to 5 hours, memoized to avoid re-computations
    const timeOptions = React.useMemo(() => {
        const options = [];
        for (let hours = 7; hours <= 23; hours++) {
            for (let minutes = 0; minutes < 60; minutes += 15) {
                const time = `${String(hours).padStart(2, '0')}h${String(minutes).padStart(2, '0')}`;
                options.push(time);
            }
        }
        return options;
    }, []);

    const [value, setValue] = useState(null);
    useEffect(() => {
        if (selectedTime && selectedTime.length > 2) {
            setValue(selectedTime); // Set the value to the selectedTime or an empty string if selectedTime is null
        }
    }, [selectedTime]);

    const handleTimeChange = (_, newValue) => {
        setValue(newValue);
        onTimeSelected(newValue);
    };

    const filteredTimeOptions = timeOptions.filter(option => {
        if (isStart && selectedOtherValue) {
            const selectedOtherTime = parseInt(selectedOtherValue.slice(0, 2)) * 60 + parseInt(selectedOtherValue.slice(3, 5));
            const currentTime = parseInt(option.slice(0, 2)) * 60 + parseInt(option.slice(3, 5));
            return currentTime >= selectedOtherTime;
        } /*else if (!isStart && selectedOtherValue) {
            const selectedOtherTime = parseInt(selectedOtherValue.slice(0, 2)) * 60 + parseInt(selectedOtherValue.slice(3, 5));
            const currentTime = parseInt(option.slice(0, 2)) * 60 + parseInt(option.slice(3, 5));
            return currentTime <= selectedOtherTime;
        }*/
        return true;
    });

    const getOptionStyle = () => ({
        borderRadius: "15px", // Set the border radius for each option
        margin: "10px 0", // Add space between the list items
        background: "#DAE0E5", // Set background color for each option
        padding: "15px", // Add padding for each option
        color:"#000",
        fontSize: "14px !important"
    });

    return (
        <div style={{width:"100%"}}>
            <Autocomplete
                PopperComponent={(props) => (
                    <div style={{display:"flex", flexDirection:"column"}}>
                        {props.children}
                    </div>
                )}
                disableClearable={true} className="custom-autocomplete button-autocomplete" style={{background:"#F1F5F8", borderRadius:"15px", width:"100%"}}
                          onChange={handleTimeChange}
                          value={value}
                          options={filteredTimeOptions} // Use the filtered options
                          disabled={disabled}
                          renderOption={(props, option, { selected }) => {
                              return <li style={getOptionStyle()} {...props}>{option}</li>;
                          }}
                          renderInput={({ inputProps, ...params }) => (
                             <TextField {...params}
                                       label="Heure"
                                       inputProps={{ ...inputProps, readOnly: true }}/>
                          )}
            />

        </div>
    );
};

export default TimePickerWholeDay;