import axios from "axios";
import {DateTime} from "luxon";

export const fetchLatLong = async (address) => {
    try {
        const encodedAddress = encodeURIComponent(address);
        const url = `https://api-adresse.data.gouv.fr/search/?q=${encodedAddress}&type=housenumber&autocomplete=0`;

        const response = await axios.get(url);

        if (response.data && response.data.features && response.data.features.length > 0) {
            const { geometry } = response.data.features[0];

            if (geometry && geometry.coordinates) {
                const [longitude, latitude] = geometry.coordinates;
                return { latitude, longitude };
            }
        }

        throw new Error('No coordinates found for the given address.');
    } catch (error) {
        console.error(error);
        throw new Error('An error occurred while fetching the coordinates.');
    }
};

export function convertUTCToTimeZone(utcTime, timeZone) {
    utcTime = utcTime.replace('h', ':');
    // Parse the UTC time
    const utcDateTime = DateTime.fromFormat(utcTime, 'HH:mm', { zone: 'utc' });
    // Set the desired time zone
    const localTime = utcDateTime.setZone(timeZone);
    // Format the local time as 'HH:mm'
    let formattedLocalTime = localTime.toFormat('HH:mm');
    formattedLocalTime = formattedLocalTime.replace(':', 'h')
    return formattedLocalTime;
}