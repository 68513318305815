import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import fmsm from '../assets/fmsm.png';
import {
    FaCalendarWeek,
    FaCheckCircle,
    FaCloud, FaPlane,
    FaRocket, FaTimesCircle,
} from "react-icons/fa";
import firebase from "firebase/compat/app";
import Button from "@mui/material/Button";
import {Divider, Skeleton, Typography} from "@mui/material";
import {getUserSubscription} from "../firebase";
import SnackbarWithCustomBackground from "./snackbar";
import calCheckGrey from "../assets/calendarCheckGrey.png";
import ccardsm from "../assets/creditcardsm.png";
import icCheck from "../assets/ic_check.svg";
import diamondIc from "../assets/diamondColored.svg";

import {FaPlaneDeparture, FaPlaneUp} from "react-icons/fa6";
import {HeaderHome} from "./headerHome";

function formatDate(timestamp) {
    const monthsInFrench = [
        'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
        'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];

    const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const formattedDate = `${day} ${monthsInFrench[monthIndex]} ${year}`;

    return formattedDate;
}

function formatDateTime(timestamp) {
    const monthsInFrench = [
        'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
        'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];

    const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0'); // Format hours with leading zero
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Format minutes with leading zero

    const formattedDate = `${day} ${monthsInFrench[monthIndex]} ${year} ${hours}h${minutes}`;

    return formattedDate;
}

export default function Subscription() {
    const { pathname } = useLocation();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    //firebase.functions().useEmulator("127.0.0.1", 5001);
    const [subData, setSubData] = useState(null);
    const [changePlan, setChangePlan] = useState(false);
    const [userCreatedAt, setUserCreatedAt] = useState(false);
    const [portalUrl, setPortalUrl] = useState(null);
    const getSubscription = firebase.functions().httpsCallable('getSubscription');
    const createPaymentLink = firebase.functions().httpsCallable('createPaymentLink');
    const [paymentLink, setPaymentLink] = useState("");
    const [invoice, setInvoice] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [payLink6, setPayLink6] = useState('');
    const [payLink12, setPayLink12] = useState('');
    const [userClickedChangeSub, setUserClickedChangeSub] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect( () => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async (user) => {
            if (!user || user.isAnonymous) {
                navigate("/");
            } else {
                setUserCreatedAt(user.metadata?.createdAt)
                if (id!==null && id.length>2) {
                    try {
                        const getSessionDataCallable = firebase.functions().httpsCallable('getSessionData');
                        const result = await getSessionDataCallable({ sessionId: id });
                        const sub = await getSubscription({subscriptionId : result.data.subscription, callbackUrl : window.location.href.split('?')[0]});
                        setPortalUrl(sub.data.portalUrl);
                        setInvoice(sub.data.invoice);
                        setPaymentMethod(sub.data.paymentMethod);
                        setSubData(sub.data.subscription);
                    } catch (error) {
                        setSubData(false);
                        console.error('Error:', error);
                    }
                } else {
                    try {
                        const subObj = await getUserSubscription(user.uid);
                        if (subObj?.subscriptionId){
                            const sub = await getSubscription({subscriptionId : subObj.subscriptionId, callbackUrl : window.location.href});
                            setPortalUrl(sub.data.portalUrl);
                            setInvoice(sub.data.invoice);
                            setPaymentMethod(sub.data.paymentMethod);
                            setSubData(sub.data.subscription);
                            if (subObj?.status!=="trialing" || subObj?.status!=="active"){
                                const paymentLinkPromise6month = await createPaymentLink({callback : window.location.href, trialDone:true, price : "price_1NyuBNEXMYpBYl4MkMmCK2Nj"});
                                const paymentLinkPromise12month = await createPaymentLink({callback : window.location.href, trialDone:true, price : "price_1NyuBUEXMYpBYl4Mqkaw9vdW"});
                                const [paymentLink6, paymentLink12] = await Promise.all([
                                    paymentLinkPromise6month,
                                    paymentLinkPromise12month,
                                ]);
                                setPayLink6(paymentLink6.data.url);
                                setPayLink12(paymentLink12.data.url);
                                /*const paymentLink = await createPaymentLink({callback : window.location.href, trialDone:false, price : subObj.price});
                                if (paymentLink.data.url){
                                    setPaymentLink(paymentLink.data.url)
                                }*/
                            }
                        } else {
                            const paymentLinkPromise6month = await createPaymentLink({callback : window.location.href, trialDone:false, price : "price_1NyuBNEXMYpBYl4MkMmCK2Nj"});
                            const paymentLinkPromise12month = await createPaymentLink({callback : window.location.href, trialDone:false, price : "price_1NyuBUEXMYpBYl4Mqkaw9vdW"});
                            const [paymentLink6, paymentLink12] = await Promise.all([
                                paymentLinkPromise6month,
                                paymentLinkPromise12month,
                            ]);
                            setPayLink6(paymentLink6.data.url);
                            setPayLink12(paymentLink12.data.url);
                            setSubData(false);
                        }
                    } catch (e) {
                        //setSubData(false);
                        console.log(e.message);
                        handleOpenSnackbar("Quelque chose s'est mal passé, actualisez la page ou contactez nous.")
                    }
                }
            }

        })
        return () => unregisterAuthObserver();
    }, []);

    const navToWebsite = () => {
        window.open("https://faismoibg.com/", '_blank');
    };

    const navigateHome = () => {
        navigate("/home");
    };

    const handlePortal = (isPayment, is6) => {
        if (payLink12.length>2 && payLink6.length>2 && isPayment){
            if (is6){
                window.open( payLink6);
            } else {
                window.open( payLink12);
            }
        } else {
            window.open( portalUrl);
        }
    };

    const handleInvoice = (isPayment) => {
        if (invoice.invoice_pdf.length>2){
            const pdfUrl = invoice.invoice_pdf;
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.target = '_blank';
            link.rel = 'noopener noreferrer';
            link.click();
        } else {
            handleOpenSnackbar("Quelque chose s'est mal passé, veuillez réessayer ou nous contacter.")
        }
    };

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    const navigateToHome = () => {
        navigate("/home");
    };

    const changeP = () => {
        setChangePlan(true);
        setUserClickedChangeSub(true);
    };

    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            {subData==null ?
            (
                <div className="sub-container-no-top">
                    <div style={{display:"flex", width:"100%", flexDirection:"column",
                        justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                        <Skeleton style={{borderRadius:"15px"}} variant="text" sx={{ fontSize: '2rem' }} width="70%" />
                        <Skeleton style={{marginTop:"50px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                    </div>
                </div>
            ) : (

            subData && !changePlan ? (
                <div className="sub-container-no-top">
                    <span className="title-style">Votre abonnement</span>
                    <div className="field-container" style={{marginTop:"28px"}}>
                        <div className="title-field-container">
                                      <span className="text-field-style">
                                          Informations sur l'abonnement actuel
                                      </span>
                        </div>
                    </div>
                    <div style={{display:"flex",backgroundColor:"white", marginTop:"10px", borderRadius:"15px", boxShadow:"0px 4px 15px 0px rgba(218, 224, 229, 0.90)", width:"100%", flexDirection:"row"}}>

                        <div style={{width:"92%", marginTop:"20px", marginLeft:"20px",marginBottom:"20px",marginRight:"4px"}}>
                            {subData.status!=="active" && subData.status!=="trialing" ? (
                                <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
                                    <span style={{fontWeight:700}}>Faismoibg gratuit</span>
                                    <span style={{fontWeight:400, lineHeight:"20px"}}>Inscription depuis le {formatDate(parseInt(userCreatedAt)/1000)}</span>
                                </div>
                            ) : (
                                <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
                                    <span style={{fontWeight:700}}>Faismoibg premium</span>
                                    <span style={{fontWeight:"400", lineHeight:"20px"}}>souscrit depuis le {formatDate(subData.current_period_start)} est valide jusqu'au {formatDate(subData.current_period_end)}.
                                        {(subData.cancel_at_period_end) ? " Celui-ci ne sera pas renouvelé automatiquement." : ""}
                                        {(subData.status==="active" && !subData.cancel_at_period_end) ? " Renouvellement automatique." : ""}
                                        {(subData.status==="trialing" && !subData.cancel_at_period_end) ? " Votre premier paiement sera effectué automatiquement à cette date." : ""}
                                        </span>
                                </div>
                            )}
                        </div>
                        <div style={{width:"8%", padding:"20px", alignContent:"center", alignItems:"center", justifyContent:"center", display:"flex"}}>
                            {<img src={icCheck} style={{width:"40px", height:"40px", color:"#28A745"}}></img>}
                        </div>
                    </div>
                    <div style={{ color:"white", display:"flex", alignSelf:"start", alignContent:'start', alignItems:'start', justifyContent:'center', marginTop:"20px"}} >
                        {subData.status!=="active" && subData.status!=="trialing" ? (
                                <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
                                    <Button onClick={() => changeP()}
                                            disableElevation={true}
                                            className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}}>
                                        <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px"}}>
                                            Je m'abonne
                                        </Typography>
                                    </Button>
                                </div>
                            ) :  (
                                    <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
                                        {(subData.cancel_at_period_end) ?
                                            <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
                                                <div>
                                                    <Button disableElevation={true} onClick={() => handlePortal(false)}
                                                            className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}}>
                                                        <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px" }}>
                                                            Réabonnement
                                                        </Typography>
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button disableElevation={true} onClick={() => changeP()}
                                                            className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white", marginTop:"16px"}}>
                                                        <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px" }}>
                                                            Changer de plan
                                                        </Typography>
                                                    </Button>
                                                </div>
                                            </div>
                                            : <div></div>}
                                        {(subData.status==="active" && !subData.cancel_at_period_end) ? <Button disableElevation={true} onClick={() => handlePortal(false)}
                                                                               className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}}>
                                            <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px"}}>
                                                Gérer mon abonnement
                                            </Typography>
                                        </Button> : ""}
                                        {(subData.status==="trialing" && !subData.cancel_at_period_end) ? <Button onClick={() => handlePortal(false)}
                                                                                                                  disableElevation={true}
                                                                                 className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}}>
                                            <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px"}}>
                                                Gérer mon abonnement
                                            </Typography>
                                        </Button> : ""}
                                </div>


                        )}
                    </div>
                    <div className="field-container">
                        <div className="title-field-container">
                                      <span className="text-field-style">
                                          Transactions
                                      </span>
                        </div>
                    </div>

                    <div style={{display:"flex",backgroundColor:"white", marginTop:"10px", borderRadius:"15px", boxShadow:"0px 4px 15px 0px rgba(218, 224, 229, 0.90)", width:"100%", flexDirection:"column"}}>
                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop:"25px", marginRight:"5px" }}>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row", // Change to row to have icon and content side by side
                                alignItems: "center", // Center icon and content vertically
                                padding: "1px 20px", // Add padding to create some space around the content
                                marginBottom:"25px"
                            }}
                        >
                            <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex" }}>
                                <img src={calCheckGrey} style={{width:"30px", height:"30px"}}/>
                            </div>

                            <div  style={{
                                    width: "90%", // Adjusted width to make space for the close icon
                                    marginLeft: "10px",
                                }}>
                                <span style={{fontWeight:"400", fontSize:"15px"}}>
                                    {formatDateTime(invoice.effective_at)}
                                </span>
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row", // Change to row to have icon and content side by side
                                alignItems: "center", // Center icon and content vertically
                                padding: "1px 20px", // Add padding to create some space around the content
                                marginBottom:"25px"
                            }}
                        >
                            <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex" }}>
                                <img onLoad={handleImageLoad}
                                     className="fade-in white" src={diamondIc} alt="fmbg"/>
                            </div>

                            <div  style={{
                                width: "90%", // Adjusted width to make space for the close icon
                                marginLeft: "10px",
                                display:"flex",
                                flexDirection:"column"
                            }}>
                                <span style={{fontWeight:"500"}}>{invoice.lines.data[0].plan.nickname}</span>
                                <span style={{fontWeight:"400", fontSize:"15px"}}>
                                    {invoice.amount_paid/100} € H.T
                                </span>
                            </div>
                        </div>
                        <div style={{margin:"20px 20px", marginTop:"0", width:"80%"}}>
                                <Button className="button-style-borders-lil-white" disableElevation variant="contained" onClick={handleInvoice}>
                                    <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px" }}>
                                        Télécharger la facture
                                    </Typography>
                                </Button>
                        </div>
                    </div>

                    {((subData?.status==="trialing" || subData?.status==="active") && !subData?.cancel_at_period_end) && (
                        <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
                            <div className="field-container">
                                <div className="title-field-container">
                                      <span className="text-field-style">
                                          Mode de paiement
                                      </span>
                                </div>
                            </div>

                            <div style={{display:"flex",backgroundColor:"white", marginTop:"10px", borderRadius:"15px", boxShadow:"0px 4px 15px 0px rgba(218, 224, 229, 0.90)", width:"100%", flexDirection:"column"}}>
                                <div style={{ display: "flex", justifyContent: "flex-end", marginTop:"25px", marginRight:"5px" }}>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row", // Change to row to have icon and content side by side
                                        alignItems: "center", // Center icon and content vertically
                                        padding: "1px 20px", // Add padding to create some space around the content
                                        marginBottom:"25px"
                                    }}
                                >
                                    <div style={{ alignItems:"center",padding: "7px 7px 7px 7px",
                                        alignContent:"center", justifyContent:"center", display:"flex", borderRadius:"999px",
                                        background:"rgba(218, 224, 229, 0.79)"
                                    }}>
                                        <img src={ccardsm} style={{width:"15px", height:"15px"}}/>
                                    </div>

                                    <div  style={{
                                        width: "90%", // Adjusted width to make space for the close icon
                                        marginLeft: "10px",
                                        display:"flex",
                                        flexDirection:"column"
                                    }}>
                                <span style={{fontWeight:"400", fontSize:"15px"}}>
                                    Paiement par carte :
                                </span>
                                        <span style={{fontWeight:"400", fontSize:"15px"}}>
                                    {paymentMethod?.card?.brand} XXXX XXXX XXXX {paymentMethod?.card?.last4}
                                </span>
                                    </div>
                                </div>
                            </div>

                            <div style={{display:"flex", flexDirection:"column", marginTop:"30px", alignSelf:"start"}}>
                                <Button disableElevation={true} onClick={() => handlePortal(false)}
                                        className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}}>
                                    <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px"}}>
                                        Changer de mode de paiement
                                    </Typography>
                                </Button>
                            </div>
                            <span style={{marginTop:"30px"}}>
                                Pour résilier votre forfait, cliquez sur "Gérer mon abonnement", si vous êtes en période d'essai, celle-ci restera valide pour la durée restante.
                            </span>
                        </div>

                    )}
                </div>
                ) : (
                    <div className="sub-container-no-top">
                        <span className="title-style">Votre abonnement</span>
                        <div className="field-container" style={{marginTop:"28px"}}>
                            <div className="title-field-container">
                                      <span className="text-field-style">
                                          Informations sur l'abonnement actuel
                                      </span>
                            </div>
                        </div>
                        <div style={{display:"flex",backgroundColor:"white", marginTop:"10px", borderRadius:"15px", boxShadow:"0px 4px 15px 0px rgba(218, 224, 229, 0.90)", width:"100%", flexDirection:"row"}}>

                            <div style={{width:"92%", marginTop:"20px", marginLeft:"20px",marginBottom:"20px",marginRight:"4px"}}>
                                {subData.status!=="active" && subData.status!=="trialing" ? (
                                    <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
                                        <span style={{fontWeight:700}}>Faismoibg gratuit</span>
                                        <span style={{fontWeight:400, lineHeight:"20px"}}>Inscription depuis le {formatDate(parseInt(userCreatedAt)/1000)}</span>
                                    </div>
                                ) : (
                                    <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
                                        <span style={{fontWeight:700}}>Faismoibg premium</span>
                                        <span style={{fontWeight:"400", lineHeight:"20px"}}>souscrit depuis le {formatDate(subData.current_period_start)} est valide jusqu'au {formatDate(subData.current_period_end)}.
                                            {(subData.cancel_at_period_end) ? " Celui-ci ne sera pas renouvelé automatiquement." : ""}
                                            {(subData.status==="active" && !subData.cancel_at_period_end) ? " Celui-ci sera renouvelé automatiquement." : ""}
                                            {(subData.status==="trialing" && !subData.cancel_at_period_end) ? " Votre premier paiement sera effectué automatiquement à cette date." : ""}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div style={{width:"8%", padding:"20px", alignContent:"center", alignItems:"center", justifyContent:"center", display:"flex"}}>
                                {<img src={icCheck} style={{width:"40px", height:"40px", color:"#28A745"}}></img>}
                            </div>
                        </div>
                        {userClickedChangeSub && (
                            <div style={{marginTop:"10px", width:"100%"}}>
                                <Button disableElevation={true} onClick={() => setChangePlan(false)}
                                        className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white", marginTop:"10px"}}>
                                    <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px"}}>
                                        Revenir vers mon abonnement
                                    </Typography>
                                </Button>
                            </div>
                        )}


                        <div className="field-container">
                            <div className="title-field-container">
                                      <span className="text-field-style">
                                          Mettre à niveau votre abonnement
                                      </span>
                            </div>
                        </div>

                        <div style={{display:"flex",backgroundColor:"white", marginTop:"10px", borderRadius:"15px", boxShadow:"0px 4px 15px 0px rgba(218, 224, 229, 0.90)", width:"100%", flexDirection:"row"}}>

                            <div style={{width:"100%", margin:"30px", display:"flex", flexDirection:"column"}}>
                                <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                    <img src={diamondIc} style={{width:"30px", height:"30px"}}/>
                                    <div style={{display:"flex", flexDirection:"column", marginLeft:"15px"}}>
                                        <span style={{fontWeight:700}}>Faismoibg premium mensuel</span>
                                        <span>14€ H.T/mois (168€ H.T/an)</span>
                                    </div>
                                </div>

                                <span style={{lineHeight:"22px", marginTop:"15px"}}>
                                    Abonnement mensuel avec 14 jours d’essai gratuit (possibilité d'annuler avant le premier paiement, valable une fois). Cet abonnement vous donne accès aux réservations illimitées ainsi qu'aux fonctionnalités de base et avancées. <a target="_blank" href="https://shine.faismoibg.com/fonctionnalites">En savoir plus.</a>
                                </span>

                                <div style={{marginTop:"15px"}}>
                                    <Button disableElevation={true} onClick={() => handlePortal(true, true)}
                                            className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white", marginTop:"10px"}}>
                                        <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px"}}>
                                            S'abonner
                                        </Typography>
                                    </Button>
                                </div>

                            </div>
                        </div>

                        <div style={{display:"flex",backgroundColor:"white", marginTop:"20px", borderRadius:"15px", boxShadow:"0px 4px 15px 0px rgba(218, 224, 229, 0.90)", width:"100%", flexDirection:"row"}}>

                            <div style={{width:"100%", margin:"30px", display:"flex", flexDirection:"column"}}>
                                <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                    <img src={diamondIc} style={{width:"30px", height:"30px"}}/>
                                    <div style={{display:"flex", flexDirection:"column", marginLeft:"15px"}}>
                                        <span style={{fontWeight:700}}>Faismoibg premium annuel</span>
                                        <span>126€ H.T/an</span>
                                    </div>
                                </div>

                                <span style={{lineHeight:"22px", marginTop:"15px"}}>
                                    Abonnement annuel avec 14 jours d’essai gratuit (possibilité d'annuler avant le premier paiement, valable une fois). Cet abonnement vous donne accès aux réservations illimitées ainsi qu'aux fonctionnalités de base et avancées. <a target="_blank" href="https://shine.faismoibg.com/fonctionnalites">En savoir plus.</a>
                                </span>
                                <div style={{marginTop:"15px"}}>
                                    <Button disableElevation={true} onClick={() => handlePortal(true, false)}
                                            className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white", marginTop:"10px"}}>
                                        <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px"}}>
                                            S'abonner
                                        </Typography>
                                    </Button>
                                </div>

                            </div>
                        </div>
                    </div>
                )

            )}
            <div className="sub-container-no-top" style={{marginTop:"20px"}}>
                {subData!==null && (
                    <span style={{width:"100%", marginBottom:"50px", marginTop:"70px"}}>
                                En vous abonnant vous confirmez avoir pris connaissance de <a href="https://shine.faismoibg.com/cgv" target="_blank" rel="noreferrer">nos conditions générales de vente</a>
                     </span>
                )}

                {subData!==null && (
                    <div className="button-container">
                        <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateToHome}>
                            <Typography variant="button" style={{ textTransform: 'none', fontWeight:"400" }}>
                                Retour
                            </Typography>
                        </Button>
                    </div>
                )}
            </div>
            <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />

        </div>
    )
}