import React, { createContext, useContext, useState } from 'react';

const DataContext = createContext();

export const useAgDataContext = () => useContext(DataContext);

export const AgDataProvider = ({ children }) => {
    const [agData, setAgData] = useState({});

    // Function to update the data
    const updateAgData = (updatedState) => {
        if (Object.keys(updatedState).length === 0) {
            // If the updatedState is an empty object, clear the data
            setAgData({});
        } else {
            setAgData((prevData) => ({ ...prevData, ...updatedState }));
        }
    };

    return (
        <DataContext.Provider value={{ agData, updateAgData }}>
            {children}
        </DataContext.Provider>
    );
};