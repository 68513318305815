import React, {useEffect, useState} from "react";
import {TextField, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import SnackbarWithCustomBackground from "./snackbar";
import IconButton from "@mui/material/IconButton";
import {FaEye} from "react-icons/fa";
import {FaEyeSlash} from "react-icons/fa6";
import Button from "@mui/material/Button";
import {completePasswordReset} from "../firebase";

export function NewPassword() {

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [success, setSuccess] = useState(false);
    const [oobCode, setOobCode] = useState("");

    useEffect(() => {
        // Extract oobCode from the URL query parameters
        const urlSearchParams = new URLSearchParams(window.location.search);
        const oobCodeParam = urlSearchParams.get("oobCode");
        setOobCode(oobCodeParam || "");
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const [password, setPassword] = useState("");
    const handleIconEyeClick = () => {
        setShowPassword(!showPassword);
    }
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };
    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };

    const handleReinit = async () => {
        if (oobCode.length<2){
            handleOpenSnackbar("Lien invalide.");
            return;
        }
        if (password.length>5){
            try {
                await completePasswordReset(oobCode, password);
                setSuccess(true);
                handleOpenSnackbar("Votre mot de passe a été reinitalisé avec succès.");
            } catch (e) {
                console.log(e.message);
                handleOpenSnackbar("Quelque chose s'est mal passé, réessayez ou contactez nous.");
            }
        } else {
            handleOpenSnackbar("Votre nouveau mot de passe doit contenir 6 caractères minimum");
        }
    };

    const navigateHome = () => {
        navigate("/")
    }

    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    return (
        <div className="sub-container">
            <div className="sub-container-no-top">

                    <div className="title-container">
                        <span className="title-style">Récupérer votre mot de passe</span>
                    </div>

                    <p style={{fontSize:"20px", textAlign:"center"}}>
                        Veuillez saisir votre nouveau mot de passe.
                    </p>

                    <div className="field-container-b">
                        <div className="title-field-container">
                                          <span className="text-field-style">
                                              Renseignez votre nouveau mot de passe.
                                          </span>
                        </div>
                        <TextField type={showPassword ? "text" : "password"} className="field-style" label="Ecrire"
                                   onChange={handlePasswordChange} variant="outlined"
                                   InputProps={{
                                       endAdornment: (
                                           <IconButton onClick={handleIconEyeClick} edge="end">
                                               {showPassword ? <FaEye /> : <FaEyeSlash />}
                                           </IconButton>
                                       ),
                                   }}/>
                    </div>
                    <div className="button-container">
                        <Button disabled={success} className="button-style button-style-noborders" onClick={handleReinit} disableElevation variant="contained">
                            <Typography variant="button" style={{ textTransform: 'none', color:"white"}}>
                                {success ? "C'est fait" : "Réinitialiser"}
                            </Typography>
                        </Button>
                    </div>
                    <div className="button-container" style={{marginTop:"100px"}}>
                        <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-borders" onClick={navigateHome} disableElevation variant="contained">
                            <Typography variant="button" style={{ textTransform: 'none'}}>
                                Retour
                            </Typography>
                        </Button>
                    </div>
                    <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />
            </div>

        </div>
    )
}