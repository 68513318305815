import React, {useEffect, useState} from "react";
import {
    Backdrop,
    Chip,
    CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem,
    Skeleton,
    styled,
    TextField,
    Typography, useMediaQuery, useTheme
} from "@mui/material";
import {FaRegEye, FaRegEyeSlash, FaTimes} from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import TimePicker from "./timepicker";
import DayPicker from "./daypicker";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import ImageCarousel from "./carousel";
import {useLocation, useNavigate} from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/firestore'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import {
    deleteImageByLink, getPrestaDoc,
    getUser, getUserPresta, getUserSubscription,
    handleImageUpload, updatePresta, updateUserDocument,
} from "../firebase";
import SnackbarWithCustomBackground from "./snackbar";
import {useDataContext} from "./datacontext";
import Switch, {SwitchProps} from "@mui/material/Switch";
import {Timestamp, serverTimestamp} from "@firebase/firestore";
import {useParams} from "react-router";
import plusicon from "../assets/ph_plus-bold.png";
import pinMapBlue from "../assets/ph_map-pin_blue.png";
import pinMapGrey from "../assets/ph_map-pin_grey.png";
import icLock from "../assets/icLock.svg";
import trashic from "../assets/trashic.svg";
import deleteic from "../assets/icdeletetag.svg";
import {HeaderHome} from "./headerHome";
import AutocompleteAddressFrance from "./addressac";
import pinGrey from "../assets/ph_map-pin.svg";
import diamondIc from "../assets/diamondColored.svg";
import orderImagesIc from "../assets/orderImages.svg";
import trashlogo from "../assets/trashic.svg";
import playIc from "../assets/playSvg.svg";
import imglogo from "../assets/imgIcon.svg";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch size="medium" focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 63,
    height: 38,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 6,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(23px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                background: 'linear-gradient(90deg, #FF0844 0%, #4808FF 100%)',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 28, // Adjusted width (smaller)
        height: 28, // Adjusted height (smaller)
        transform: 'translateY(-4%)',
        //transform: 'translateY(50%)', // Adjust vertical position
    },
    '& .MuiSwitch-track': {
        borderRadius: 39 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const deleteButtonStyle = {
    position: 'absolute',
    top: '5px',
    right: '5px',
    backgroundColor: '#F2F2F2',
    opacity:'70%',
    fontSize: '1.5rem',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

function urlContainsInstagramReel(url) {
    return url.includes("https://www.instagram.com/reel/");
}
export default function ModifyPresta() {

    const { data, updateData } = useDataContext();
    const { id } = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    const typeIconStyle = {
        position: 'absolute',
        bottom: '5px',
        right: '5px',
        opacity:'94%',
        width: '30px',
        height: '30px',
        zIndex: 1,
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect( () => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged( async(user) => {
            if (!user) {
                navigate("/");
            } else {
                setShowSkeleton(true);
                //TODO Check post create in route "/"

                // fetch page data
                async function fetchUserData() {
                    try {
                        const userData = await getUser(user?.uid);
                        const subObj = await getUserSubscription(firebase.auth().currentUser.uid);
                        if (subObj?.status){
                            setSubStatus(subObj.status);
                        }
                        setUserObject({...userData});

                        if (userData && userData.address && userData.address.length > 0) {
                            // If user data exists, has an 'address' property, and the address array is not empty
                            setFormData({
                                ...formData,
                                selectedAddress: userData.address[0]
                            });
                        } else {
                            // If user data doesn't have a valid address
                            setFormData({
                                ...formData,
                                selectedAddress: ""
                            });
                        }

                        //either we go for state data or server data
                        //todo clean state once presta is registered - page is left to home
                        if (data?.formData){
                            setFormData({
                                ...data.formData,
                            });
                            setIsQuestionAdded(data.isQuestionAdded);
                            setTempFiles(data.tempFiles);
                            setTempImages(data.tempImages);
                            setSelectedDays(data.formData.selectedDays);
                            setSelectedTime(data.formData.prestaTimeMillis);
                            setSelectedTimeBreak(data.formData.breakMillis);
                            setStartDate(data.formData.startTime);
                            setEndDate(data.formData.endTime);
                            if (data.isQuestionAdded){
                                setIsQuestionAdded(data.isQuestionAdded);
                            }
                        } else {
                            //override default with state data
                            const userPresta = await getPrestaDoc(id);
                            setFormData({
                                title : userPresta.title,
                                keywords : userPresta.keywords,
                                prestaTimeMillis : userPresta.prestaDuration,
                                breakMillis : userPresta.breakDuration,
                                price : userPresta.price,
                                selectedDays : userPresta.selectedDays,
                                startTime : dayjs(userPresta.startTime.toDate()),
                                endTime : dayjs(userPresta.endTime.toDate()),
                                selectedAddress : userData.address.includes(userPresta?.selectedAddress) ? userPresta?.selectedAddress : "",
                                isPinned : userPresta.isPinned,
                                isAutoBooking : userPresta.isAutoBooking,
                                isVisible : userPresta.isVisible,
                                isDeposit: userPresta?.isDeposit || false,
                                description: userPresta.description,
                                isValidation: userPresta?.isValidation || false,
                                isAddressVisible: userPresta.isAddressVisible,
                                status: userPresta.status
                            });
                            setSelectedDays(userPresta.selectedDays);
                            setSelectedTime(userPresta.prestaDuration);
                            setSelectedTimeBreak(userPresta.breakDuration);
                            setStartDate(dayjs(userPresta.startTime.toDate()));
                            setEndDate(dayjs(userPresta.endTime.toDate()));
                            const goodFormatTempImages = [];
                            for (let image of userPresta.imageLinks){
                                goodFormatTempImages.push(
                                    {
                                        imageLink : image.imageLink,
                                        date : new Date(),
                                        vlink: image.vlink
                                    }
                                )
                            }
                            const newImageArray = tempImages.concat(goodFormatTempImages);
                            setTempImages(newImageArray);
                            //see if you want to set up the original object from db or also fill checked and file
                            if (userPresta?.isDeposit && userPresta?.deposit){
                                updateData({
                                    deposit: userPresta.deposit
                                })
                            }
                            if (userPresta?.questions?.length>0){
                                setIsQuestionAdded(true);
                                let questionsArray = [];
                                for (let question of userPresta.questions){
                                    questionsArray.push({
                                        image: question.image,
                                        question: question.question,
                                        checked : question.checked || false,
                                    })
                                }
                                updateData({
                                    isQuestionAdded: userPresta.questions.length>0,
                                    intro: userPresta.intro,
                                    questions: questionsArray
                                })
                            }
                        }
                    } catch (e) {
                        console.log(e.message);
                        handleOpenSnackbar("Quelque chose s'est mal passé, contactez nous. " + e.message);
                    }
                    setShowSkeleton(false);
                }
                await fetchUserData();
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => {
            unregisterAuthObserver();
        }
    }, []);

    const [formData, setFormData] = useState({
        title : "", keywords : [], prestaTimeMillis : "", breakMillis : "", price : "",
        selectedDays : "", startTime : dayjs(), endTime :dayjs(), selectedAddress : "", isPinned : false,
        isAutoBooking : false, isVisible : true, description:"", isAddressVisible : false, status:'draft', isDeposit:false, isValidation:false
    });


    const [tempImages, setTempImages] = useState([]);
    const [tempReorderImages, setTempReorderImages] = useState([]);
    const [tempFiles, setTempFiles] = useState([]);
    const [tempPopImage, setTempPopImage] = useState(null);
    const [tempPopFile, setTempPopFile] = useState(null);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [userObject, setUserObject] = useState({});
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [isQuestionAdded, setIsQuestionAdded] = useState(false);
    const [startIsOpen, setStartIsOpen] = useState(false);
    const [endIsOpen, setEndIsOpen] = useState(false);
    const [titleMissing, setTitleMissing] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [prestaTimeMissing, setPrestaTimeMissing] = useState(false);
    const [prestaBreakMissing, setPrestaBreakMissing] = useState(false);
    const [priceMissing, setPriceMissing] = useState(false);
    const [unconsistentDate, setUnconsistentDate] = useState(false);

    const [selectedTime, setSelectedTime] = useState(null);
    const [selectedTimeBreak, setSelectedTimeBreak] = useState(null);

    const [videoLink, setVideoLink] = useState('');
    const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);
    const [isDelDialogOpen, setIsDelDialogOpen] = useState(false);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
    const [currentImageDate, setCurrentImageDate] = useState(null);
    const [subStatus, setSubStatus] = useState("");

    const [isAddressDialogOpen, setIsAddressDialogOpen] = useState(false);
    const [tempAddress, setTempAddress] = useState("");

    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    const handleTimeSelected = (time) => {
        setSelectedTime(time);
        setFormData({...formData,
            prestaTimeMillis: time
        })
    };

    const handleTimeBreakSelected = (time) => {
        setSelectedTimeBreak(time);
        setFormData({...formData,
            breakMillis: time
        })
    };

    const [currentKey, setCurrentKey] = useState("");

    const handleFormDescriptionChange = (event) => {

        const newText = event.target.value.replace(/\r?\n/g, '\n');
        setFormData({
            ...formData,
            [event.target.name]: newText
        });

    };

    const handleFormChange = (event) => {

        if (event.target.name==="keywords"){
            setCurrentKey(event.target.value);
        } else {
            setFormData({
                ...formData,
                [event.target.name]: event.target.value
            });
        }
    };
    const handleAddKey = (event) => {
        if (currentKey.length>0){
            setFormData({
                ...formData,
                keywords: [...formData.keywords, currentKey.trim()],
            });
            setCurrentKey("");
        }
    }

    const handleDelete = (index) => {
        setFormData((prevFormData) => {
            const updatedKeywords = [...prevFormData.keywords];
            updatedKeywords.splice(index, 1);
            return {
                ...prevFormData,
                keywords: updatedKeywords,
            };
        });
    };

    //selected days logic
    const [selectedDays, setSelectedDays] = useState([]);

    const handleDaySelect = (day, isSelected) => {
        if (isSelected) {
            setSelectedDays((prevSelectedDays) => [...prevSelectedDays, day]);
        } else {
            setSelectedDays((prevSelectedDays) =>
                prevSelectedDays.filter((selectedDay) => selectedDay !== day)
            );
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            selectedDays: isSelected
                ? [...prevFormData.selectedDays, day]
                : prevFormData.selectedDays.filter((selectedDay) => selectedDay !== day),
        }));
    };

    useEffect(() => {
        const currentState = {
            formData:formData,
            isQuestionAdded: isQuestionAdded,
            tempFiles: tempFiles,
            tempImages: tempImages,
        }
        updateData(currentState);
    }, [formData,isQuestionAdded,tempFiles,tempImages]);

    //Selected Date logic

    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());

    // Handle the change of the "Début" date picker
    const handleStartDateChange = (newDate) => {
        setStartDate(newDate.startOf('day'));
        setFormData({...formData,
            startTime: newDate.startOf('day')
        })
        setStartIsOpen(false);
    };

    // Handle the change of the "Fin" date picker
    const handleEndDateChange = (newDate) => {
        // Here, you can perform any additional logic if needed
        setEndDate(newDate);
        setFormData({...formData,
            endTime: newDate
        })
        setEndIsOpen(false);
    };


    //Handle file change
    const handleFileChange = (event) => {
        const newfile = event.target.files[0];
        const date = new Date();
        const newFileObject = {
            file: newfile,
            date: date,
            vlink: "",
        };
        if (newfile) {
            const reader = new FileReader();
            reader.onload = () => {
                // reader.result contains the data URL
                // reader.result contains the data URL
                // reader.result contains the data URL
                const newImageObject = {
                    imageLink: reader.result,
                    date: date,
                    vlink: "",
                };

                // Assuming tempImages is an array of objects
                setTempImages([...tempImages, newImageObject]);
                setTempFiles([...tempFiles, newFileObject]);
            };
            reader.readAsDataURL(newfile);
        }
    };

    const handlePopFileChange = (event) => {
        const newfile = event.target.files[0];
        const date = new Date();
        const newFileObject = {
            file: newfile,
            date: date,
            vlink: "",
        };
        if (newfile) {
            const reader = new FileReader();
            reader.onload = () => {
                // reader.result contains the data URL
                // reader.result contains the data URL
                // reader.result contains the data URL
                const newImageObject = {
                    imageLink: reader.result,
                    date: date,
                    vlink: "",
                };

                setTempPopImage(newImageObject);
                setTempPopFile(newFileObject);
            };
            reader.readAsDataURL(newfile);
        }
    };

    const handleDeleteImage = async (index) => {
        if (tempImages[index].imageLink.includes("firebasestorage")){
            const updatedLinksToDelete = [
                ...(data.linksToDelete || []),
                tempImages[index].imageLink
            ];
            updateData({
                ...data,
                linksToDelete: updatedLinksToDelete
            });
        }
        const updatedImages = [...tempImages];
        updatedImages.splice(index, 1);
        setTempImages(updatedImages);

        const updatedTempFiles = [...tempFiles];
        updatedTempFiles.splice(index, 1);
        setTempFiles(updatedTempFiles);

        //This is required to handle the case where a deleted image is added again
        const fileInput = document.getElementById('file-input');
        if (fileInput) {
            fileInput.value = null;
        }
    };

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const handleCardClick = (addressValue) => {
        if (formData.selectedAddress===addressValue){
            setFormData({...formData,
                selectedAddress:""
            })
        } else {
            setFormData({...formData,
                selectedAddress:addressValue
            })
        }
    };

    //Navigation
    const navigateToQuestionnaire = () => {
        navigate("/questionnaire");
    };

    const navigateToDeposit = () => {
        if (formData?.isValidation){
            handleOpenSnackbar("Vous nous pouvez pas activer l'acompte lorsque vous choisissez la réservation avec validation");
            return;
        }
        if (subStatus=="trialing" || subStatus=="active"){
            navigate("/deposit");
        } else {
            handleOpenSnackbar("Cette fonctionnalité est disponible uniquement pour les comptes Faismoibg premium");
        }
    };


    const handlePinChanged = (event) => {
        setFormData({
            ...formData,
            isPinned:!formData.isPinned
        })
    };

    const handleAutoBookingChanged = (value) => {
        /*setFormData({
            ...formData,
            isAutoBooking:!formData.isAutoBooking
        })*/
        if (value==="auto"){
            setFormData({
                ...formData,
                isAutoBooking:true,
                isValidation: false
            })
        } else if (value==="manual"){
            setFormData({
                ...formData,
                isAutoBooking:false,
                isValidation: false
            })
        } else if (value==="validation"){
            if (formData.isDeposit){
                handleOpenSnackbar("Vous ne pouvez pas choisir la réservation avec validation lorsque l'acompte est activé.");
                return;
            }
            if (subStatus!=="trialing" && subStatus!=="active"){
                handleOpenSnackbar("Vous devez bénéficier d'un forfait premium pour utiliser la réservation avec validation.");
                return;
            }
            setFormData({
                ...formData,
                isAutoBooking:true,
                isValidation: true
            })
        }
    };

    const handleAddressVisibleChanged = (event) => {
        setFormData({
            ...formData,
            isAddressVisible:!formData.isAddressVisible
        })
    };

    const handleStatusChanged = () => {
        setFormData({
            ...formData,
            status: formData.status==="draft" ? "production" : "draft"
        })
    };

    const handleEyeClicked = (event) => {
        setFormData({
            ...formData,
            isVisible:!formData.isVisible
        })
    };

    const handleFormSubmit = async () => {

        setShowBackdrop(true);

        //task to do => handle errors on fields
        //Checks ====> title > 2 - durée exists - temps de pause exists - price exists - days > 0 - debut >= fin
        if (formData.title.length<2){
            handleOpenSnackbar("Vous devez ajouter un titre.");
            setTitleMissing(true);
            setShowBackdrop(false);
            return;
        } else {
            setTitleMissing(false);
        }

        if (formData.prestaTimeMillis.length<2){
            handleOpenSnackbar("Vous devez ajouter une durée pour votre prestation.");
            setPrestaTimeMissing(true);
            setShowBackdrop(false);
            return;
        } else {
            setPrestaTimeMissing(false)
        }

        if (formData.breakMillis.length<2){
            handleOpenSnackbar("Vous devez ajouter un temps de pause pour votre prestation, ajoutez 00:00 si vous ne prenez pas de pause.");
            setPrestaBreakMissing(true);
            setShowBackdrop(false);
            return;
        } else {
            setPrestaBreakMissing(false);
        }

        if (formData.price.length<1){
            handleOpenSnackbar("Vous devez ajouter un prix.");
            setPriceMissing(true);
            setShowBackdrop(false);
            return;
        } else {
            setPriceMissing(false)
        }

        if (formData.selectedDays.length<1){
            handleOpenSnackbar("Vous devez choisir au moins un jour de la semaine pour votre prestation.");
            setShowBackdrop(false);
            return;
        }

        if (formData.startTime>endDate){
            setUnconsistentDate(true)
            handleOpenSnackbar("Vous devez choisir au moins une date de fin supérieure ou égale à la date de début.");
            setShowBackdrop(false);
            return;
        } else {
            setUnconsistentDate(false)
        }

        let questionsForDb = [];
        if (data?.questions){
            for (let question of data.questions){
                let newLink = "";
                //file logic
                if (question.file){
                    try {
                        newLink = await handleImageUpload(question.file);
                    } catch (e){
                        handleOpenSnackbar("Quelques chose s'est mal passé lors du chargement de vos images, contactez nous => ", e.message);
                    }
                }
                questionsForDb.push({
                    question: question.question,
                    checked: question.checked || false,
                    image:question?.image.includes("firebasestorage") ? question.image : newLink
                })
            }
        }

        let imagesForDb = [];
        for (let image of tempImages){
            if (image.imageLink.includes("firebasestorage")){
                imagesForDb.push({
                    imageLink : image.imageLink,
                    vlink : image.vlink,
                    date : image.date
                });
            } else {
                for (let file of tempFiles){
                    if (file.date===image.date){
                        let newLink = "";
                        //file logic
                        if (file){
                            try {
                                newLink = await handleImageUpload(file.file);
                            } catch (e){
                                handleOpenSnackbar("Quelques chose s'est mal passé lors du chargement de vos images, contactez nous => ", e.message);
                                setShowBackdrop(false);
                                return;
                            }
                        }
                        imagesForDb.push({
                            imageLink : newLink,
                            vlink : file.vlink,
                            date : file.date
                        })
                    }
                }
            }
        }

        /*console.log("images for db => ", imagesForDb);

        for (let file of tempFiles){
            let newLink = "";
            //file logic
            if (file){
                try {
                    newLink = await handleImageUpload(file.file);
                } catch (e){
                    handleOpenSnackbar("Quelques chose s'est mal passé lors du chargement de vos images, contactez nous => ", e.message);
                    setShowBackdrop(false);
                    return;
                }
            }
            imagesForDb.push({
                imageLink : newLink,
                vlink : file.vlink,
                date : file.date
            })
        }*/

        //todo create utils / date to db (dayjs to db) and db to front
        //todo create a dayjs provider for converting timestamps

        const startTime = formData.startTime.toDate();
        startTime.setHours(0, 0, 0, 0);

        let dbObject = {
            questions: questionsForDb,
            intro: data.intro || "",
            timestamp: serverTimestamp(),
            imageLinks: imagesForDb,
            title : formData.title,
            keywords : formData.keywords,
            prestaDuration : formData.prestaTimeMillis,
            breakDuration : formData.breakMillis,
            price : formData.price,
            selectedDays : formData.selectedDays,
            startTime : Timestamp.fromDate(startTime),
            endTime : Timestamp.fromDate(formData.endTime.toDate()),
            selectedAddress : formData.selectedAddress,
            isPinned : formData.isPinned,
            isAutoBooking : formData.isAutoBooking,
            isAddressVisible: formData.isAddressVisible,
            isDeposit : formData.isDeposit,
            isValidation : formData.isValidation,
            deposit : data?.deposit || {amount : "", paymentLinks: []},
            isVisible : formData.isVisible,
            description : formData.description,
            uid : firebase.auth().currentUser.uid,
            status : formData.status
        }

        try {
            await updatePresta(dbObject, id);
        } catch (e) {
            console.log("error when uploading data to db => ", e);
            setShowBackdrop(false);
            handleOpenSnackbar("Quelques chose s'est mal passé lors du chargement de votre prestation, contactez nous ", e);
            return;
        }

        try {
            if (data?.linksToDelete){
                for (let link of data?.linksToDelete){
                    await deleteImageByLink(link);
                }
            }
        } catch (e) {
            console.log("error when uploading data to db => ", e);
            setShowBackdrop(false);
            handleOpenSnackbar("Quelques chose s'est mal passé lors du chargement de votre prestation, contactez nous ", e);
            return;
        }

        //everything went well - clear context
        updateData({});
        setShowBackdrop(false);

        navigate("/home");


    }

    const navigateHome = async () => {
        navigate("/home")
    }

    const handleVideoDialogClose = () => {
        setIsVideoDialogOpen(false);
    };

    const handleVideoDialogOpen = () => {
        setVideoLink("");
        setTempPopImage({});
        setTempPopFile({});
        //setCurrentVideoIndex(index);
        setIsVideoDialogOpen(true);
    };

    const handleRemoveLink = (index) => {
        for (let fileObj of tempFiles){
            if (fileObj.date===tempFiles[index]?.date){
                const updatedTempFiles = [...tempFiles];
                updatedTempFiles[index].vlink = "";
                setTempFiles(updatedTempFiles);
            }
        }
        for (let imageObj of tempImages){
            if (imageObj.date===tempImages[index]?.date){
                const updatedTempImg = [...tempImages];
                updatedTempImg[index].vlink = "";
                setTempImages(updatedTempImg);
            }
        }
    };

    const handleAddVideoLink = () => {
        if (videoLink?.length>0 && tempPopImage?.imageLink?.length>0 && tempPopFile){
            const updatedTempPopImage = {...tempPopImage, vlink:videoLink};
            const newTempImagesArray = [...tempImages, updatedTempPopImage];
            setTempImages(newTempImagesArray);
            const updatedTempPopFile = {...tempPopFile, vlink:videoLink};
            const newTempFilesArray = [...tempFiles, updatedTempPopFile];
            setTempFiles(newTempFilesArray);
            setIsVideoDialogOpen(false);
        } else {
            handleOpenSnackbar("Vous devez ajouter un lien vers une vidéo et une image pour enregistrer.");
        }
    };

    const handleVideoLinkChange = (event) => {
        // Update the link state when the input field changes
        setVideoLink(event.target.value);
    };

    const handleKeyPressTag = (e) => {
        if (e.key === 'Enter') {
            // Prevent the default behavior of the Enter key (e.g., form submission)
            e.preventDefault();

            // Call the function to handle button click
            handleAddKey();
        }
    };

    const handleDeletePopImage = (index) => {
        setTempPopImage({});
        setTempPopFile({});
    }

    //Handle tag history

    const [openTagDialog, setOpenTagDialog] = useState(false);
    const [tagHistory, setTagHistory] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const handleOpenTagsDialog = () => {
        setOpenTagDialog(true);
    };

    const handleTagDialogCloseOutside = (event) => {
        setSelectedTags([]);
        setOpenTagDialog(false);
    };

    const [openReorderImagesDialog, setOpenReorderImagesDialog] = useState(false);
    const handleOpenReorderImagesDialog = () => {
        const deepCopyOfTempImages = tempImages.map(img => ({ ...img }));
        setTempReorderImages(deepCopyOfTempImages);
        setOpenReorderImagesDialog(true);
    };

    const handleReorderImagesDialogCloseOutside = (event) => {
        setOpenReorderImagesDialog(false);
    };

    const handleReorderImagesClose = (event) => {
        const deepCopyOfTempReorderImages = tempReorderImages.map(img => ({ ...img }));
        setTempImages(deepCopyOfTempReorderImages);
        setOpenReorderImagesDialog(false);
    };

    const handleTagHistoryDialog = async () => {
        const result = await getUserPresta(firebase.auth().currentUser.uid);
        const builtTagArray = [];
        for (let doc of result){
            if (doc?.keywords?.length>0 && doc?.documentId!==id){
                builtTagArray.push(...doc?.keywords)
            }
        }

        //remove duplicates
        let uniqueTagsSet = new Set(builtTagArray);
        let uniqueTagsArray = Array.from(uniqueTagsSet);
        setTagHistory(uniqueTagsArray)
        handleOpenTagsDialog();


    };

    const handleTagDialogClose = (event) => {
        if (selectedTags.length>0){
            setFormData({
                ...formData,
                keywords: [...formData.keywords, ...selectedTags],
            });
        }
        setSelectedTags([]);
        setOpenTagDialog(false);
    };

    const handleTempSelection = (keyword) => {
        setSelectedTags((prevSelectedTags) => {
            // Check if the keyword is already in selectedTags
            const isTagSelected = prevSelectedTags.includes(keyword);
            // If the tag is already selected, remove it; otherwise, add it
            if (isTagSelected) {
                return prevSelectedTags.filter(tag => tag !== keyword);
            } else {
                return [...prevSelectedTags, keyword];
            }
        });
    };

    const handleDelDialogClose = () => {
        setIsDelDialogOpen(false);
    };

    const handlePrestaDelete = async () => {
        setShowBackdrop(true);
        try {
            await updatePresta({prestaDeleted:true}, id)
        } catch (e) {
            console.log(e.message);
            handleOpenSnackbar("Quelque chose s'est mal passé, réessayez ou contactez nous.");
            setShowBackdrop(false);
            return;
        }
        setIsDelDialogOpen(false);
        updateData({});
        setShowBackdrop(false);
        navigate("/home");
    };

    const handleAddressCloseOutside = (event) => {
        setIsAddressDialogOpen(false);
        setTempAddress("");
    };

    const handleAddressSelected = (selectedAddress) => {
        if (selectedAddress?.length>0){
            setTempAddress(selectedAddress);
        }
    };

    const handleAddressDialogClose = async () => {
        setIsAddressDialogOpen(false);
        setShowBackdrop(true);
        if (tempAddress?.length>0){
            setFormData({
                ...formData,
                selectedAddress: tempAddress
            });
            setUserObject({
                ...userObject,
                address: [tempAddress]
            })
            const dataToUpdate = {
                address: [tempAddress]
            };
            try {
                await updateUserDocument(firebase.auth().currentUser.uid, dataToUpdate);
            } catch (e){
                console.error(e);
                handleOpenSnackbar("Quelque chose s'est mal passé, réessayez ou contactez nous.");
            }
            setTempAddress("");
        } else {
            setTempAddress("");
        }
        setShowBackdrop(false);
    };

    const onDragEnd = (result) => {
        // Dropped outside the list
        if (!result.destination) {
            return;
        }

        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        // Early return if the position is the same
        if (sourceIndex === destinationIndex) {
            return;
        }

        // Creating a new array for manipulation
        const newOrder = Array.from(tempReorderImages);

        // Moving the item within the array
        const [removed] = newOrder.splice(sourceIndex, 1);
        newOrder.splice(destinationIndex, 0, removed);

        // Updating state with the new array
        setTempReorderImages(newOrder);
    };


    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            {showSkeleton ?
                (
                    <div className="sub-container-no-top">
                        <div style={{display:"flex", width:"100%", flexDirection:"column",
                            justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                            <Skeleton style={{borderRadius:"15px"}} variant="text" sx={{ fontSize: '2rem' }} width="70%" />
                            <Skeleton style={{marginTop:"50px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                            <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                            <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        </div>
                    </div>
                ) : (
            <div className="sub-container-no-top">
                <span className="title-style">Modifier une prestation</span>
                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Titre de la prestation <span className="aster">*</span>
                                  </span>
                    </div>
                    <TextField className="field-style" id="outlined-basic" label="Ecrire" variant="outlined" value={formData.title} error={titleMissing}
                               onChange={handleFormChange} name="title"/>
                </div>
                <div className="field-container-b">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Description
                                  </span>
                    </div>
                    {/*//todo review max lines / number of characters allowed with JCVD*/}
                    <TextField className="field-style" variant="outlined" value={formData.description}
                               multiline
                               maxRows={12}
                               label="Ecrire"
                               onChange={handleFormDescriptionChange} name="description"/>
                </div>
                <div className="field-container">
                    <div className="title-field-container" style={{marginBottom:"none"}}>
                                  <span className="text-field-style">
                                      Associer un ou des mots clés
                                  </span>
                    </div>
                    <div onClick={handleTagHistoryDialog} style={{marginBottom:"10px", display:"flex", width:"100%"}}>
                        <Button disableElevation={true}
                                className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}}>
                            <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px"}}>
                                Mots clés existants
                            </Typography>
                        </Button>
                    </div>
                    <div style={{display:"flex", flexDirection:"row", width:"100%"}}>
                        <div style={{display:"flex", flexDirection:"row", width:"82%"}}>
                            <TextField className="field-style" id="outlined-basic" label="Ecrire" variant="outlined" value={currentKey}
                                       onChange={handleFormChange} name="keywords" onKeyDown={handleKeyPressTag}/>
                        </div>
                        <div style={{display:"flex", flexDirection:"row", width:"18%", alignItems:"center",
                            alignContent:"center", justifyContent:"center"}}>
                            <IconButton className="iconbutton-noborders" onClick={handleAddKey} style={{backgroundColor:"black"}}>
                                <img src={plusicon} style={{width:"20px", height:"20px", color:"white"}}></img>
                            </IconButton>
                        </div>
                    </div>
                    <div style={{display:"flex", flexWrap:"wrap", marginTop:"10px", alignContent:"start",
                        justifyContent:"start", width:"100%"
                    }}>
                        {formData.keywords.map((keyword, index) => (
                            <Chip className="iconbutton-noborders"
                                  style={{background:"linear-gradient(90deg, #4808FF 0%, #9747FF 100%)",
                                      color:"white", border:"none !important", margin:"3px", fontWeight:"400",lineHeight:"20px",
                                  }} variant="filled"
                                  key={index}
                                  inputProps={{ enterKeyHint: 'enter' }}
                                  inputMode="text"
                                  label={keyword}
                                  deleteIcon={<img src={deleteic} style={{width:"20px", height:"20px", marginRight:"8px"}}></img>}
                                  onDelete={() => handleDelete(index)}
                            />
                        ))}
                    </div>
                </div>
                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Durée
                                  </span>
                    </div>
                    <div style={{width:"40%", display:"flex", alignSelf:"start"}}>
                        <TimePicker selectedTime={selectedTime} onTimeSelected={handleTimeSelected}></TimePicker>
                    </div>
                </div>
                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Temps de pause après la prestation
                                  </span>
                    </div>
                    <div style={{width:"40%", display:"flex", alignSelf:"start"}}>
                        <TimePicker selectedTime={selectedTimeBreak}  onTimeSelected={handleTimeBreakSelected}></TimePicker>
                    </div>
                </div>
                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Prix <span className="aster">*</span>
                                  </span>
                    </div>
                    <TextField className="field-style" id="outlined-basic" label="Ecrire" variant="outlined" value={formData.price}
                               style={{width:"50%", display:"flex", alignSelf:"start"}}
                               onChange={handleFormChange} name="price"/>
                </div>
                <div className="field-container">
                    <div className="title-field-container" style={{marginBottom:"0"}}>
                                  <span className="text-field-style">
                                      Intégrer un acompte
                                  </span>
                    </div>
                </div>
                {subStatus=="trialing" || subStatus=="active" ? (
                    <div className="sub-container-no-top">
                        {formData?.isDeposit ? (
                            <div style={{ width: "100%", display: "flex", flexDirection: "column" , marginTop:"15px"}}>
                                <div style={{display: "flex",  flexDirection: "column", justifyContent: "center", alignItems: "flex-start", alignSelf:"start", width:"100%",
                                    gap: "30px", borderRadius: "15px", background: "#FFF", boxShadow: "0px 4px 15px 0px rgba(218, 224, 229, 0.90)",
                                }}>
                                    <div style={{flexDirection:"column", display:"flex", marginTop: "20px",  marginBottom: "20px", width:"100%"}}>

                                        <div style={{flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"space-between", marginLeft:"20px", marginRight:"20px"}}>
                                            <span>Montant</span>
                                            <div style={{display: "flex", padding:" 5px 10px", alignItems: "center", gap: "10px", borderRadius:"99px", background:"#DAE0E5", fontWeight:700, fontSize:"14px"}}>
                                                {data?.deposit?.amount}
                                            </div>
                                        </div>
                                        <div style={{flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"space-between", marginTop:"10px", marginLeft:"20px", marginRight:"20px"}}>
                                            <span>Page de paiement</span>
                                            <div style={{display:"flex", flexDirection:"column"}}>
                                                {
                                                    data.deposit.paymentLinks.map((paymentLink, index) => (
                                                        <div style={{display: "flex", padding:" 5px 10px", alignItems: "center", gap: "10px", borderRadius:"99px", background:"#DAE0E5", fontWeight:700, fontSize:"14px", marginTop:"5px"}} key={index+"pay"}>
                                                            {paymentLink.network}
                                                        </div>
                                                    ))
                                                }
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div style={{ width: "100%", display: "flex", flexDirection: "column" , marginTop:"15px"}}>
                                <div style={{display: "flex",  flexDirection: "column", justifyContent: "center", alignItems: "flex-start", alignSelf:"start", width:"100%",
                                    gap: "30px", borderRadius: "15px", background: "#FFF", boxShadow: "0px 4px 15px 0px rgba(218, 224, 229, 0.90)",
                                }}>
                                    <span style={{lineHeight: "20px", margin: "20px 20px 20px 20px"}}>Il n’y a aucun acompte associé à cette prestation</span>
                                </div>
                            </div>
                        )}
                        <div style={{ textAlign:"center", color:"white", display:"flex", alignSelf:"start", alignContent:'start', alignItems:'start', justifyContent:'center', marginTop:"15px"}}>
                            <Button disableElevation={true} onClick={navigateToDeposit} component="label" className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}}>
                                {formData.isDeposit ? <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px" }}>
                                    Modifier l'acompte
                                </Typography> : <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px" }}>
                                    Ajouter un acompte
                                </Typography>}
                            </Button>
                        </div>
                    </div>

                ) : (
                    <div className="sub-container-no-top">
                        <div style={{display: "flex", padding: "20px 20px 20px 20px", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", gap: "30px",
                            borderRadius: "15px", background: "#FFF", boxShadow: "0px 4px 15px 0px rgba(218, 224, 229, 0.90)", marginTop:"15px"}}>
                            <span style={{lineHeight: "20px"}}>Cette fonctionnalité n’est pas disponible en forfait gratuit, nous vous invitons à passer au forfait Premium pour pouvoir l’utiliser.</span>
                        </div>
                        <Button startIcon={<img src={diamondIc}></img>} onClick={()=> navigate("/subscription")} disableElevation={true} component="label" className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"white", color:"black", marginTop:"15px", display:"flex", alignSelf:"start", border:"1px solid black"}}>
                            <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px" }}>
                                Faismoibg premium
                            </Typography>
                        </Button>
                    </div>
                )}
                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Paramètres de disponiblités <span className="aster">*</span>
                                  </span>
                    </div>
                    <span style={{fontSize:"16px"}}>
                                  Sélectionner les jours ainsi que les dates de début et fin que vous souhaitez pour cette prestation.
                </span>
                    <span style={{fontSize:"16px"}}>
                    ⚠️ Les dates non conformes à votre agenda ne pourront pas être sélectionnées pour effectuer une réservation.
                </span>
                    <div className="title-field-container" style={{marginTop:"10px"}}>
                                  <span className="text-field-light-style">
                                      Quel(s) jour(s) :
                                  </span>
                    </div>

                    <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"space-around"}}>
                        <DayPicker label="Lundi" onDaySelect={handleDaySelect} selected={selectedDays} />
                        <DayPicker label="Mardi" onDaySelect={handleDaySelect} selected={selectedDays} />
                        <DayPicker label="Mercredi" onDaySelect={handleDaySelect} selected={selectedDays} />
                        <DayPicker label="Jeudi" onDaySelect={handleDaySelect} selected={selectedDays} />
                        <DayPicker label="Vendredi" onDaySelect={handleDaySelect} selected={selectedDays} />
                        <DayPicker label="Samedi" onDaySelect={handleDaySelect} selected={selectedDays} />
                        <DayPicker label="Dimanche" onDaySelect={handleDaySelect} selected={selectedDays} />
                    </div>

                    <div style={{display:"flex", flexDirection:"row", marginTop:"15px", width:"100%" }}>
                        <div style={{display:"flex", width:"50%", justifyContent:"center"}}>
                            <DatePicker style={{width:"90% !important"}}
                                        className="iconbutton-noborders whitePaper customDatePickerWidth"
                                        label="Début"
                                        minDate={dayjs()}
                                        value={startDate}
                                        onChange={handleStartDateChange}
                                        closeOnSelect={true}
                                        slotProps={{
                                            textField: {
                                                error: false,
                                            },
                                        }}
                            />
                        </div>
                        <div style={{display:"flex", width:"50%", justifyContent:"center"}}>
                            <DatePicker style={{width:"90% !important"}}
                                        className="iconbutton-noborders whitePaper customDatePickerWidth"
                                        label="Fin"
                                        minDate={dayjs()}
                                        value={endDate}
                                        onChange={handleEndDateChange}
                                        closeOnSelect={true}
                            />
                        </div>
                    </div>

                </div>

                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Ajouter un média
                                  </span>
                    </div>
                </div>
                <div style={{ textAlign:"center", color:"white", display:"flex", alignSelf:"start", alignContent:'start', alignItems:'start', justifyContent:'center', marginTop:"15px"}}>
                    <Button disableElevation={true} component="label" className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}}>
                        <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px" }}>
                            Image
                        </Typography>
                        <input
                            id="file-input" style={{ display: 'none', width:"100%" }} type="file"
                            accept="image/jpeg,image/png,image/gif,image/svg+xml" onChange={handleFileChange} />
                    </Button>
                    <Button disableElevation={true} component="label" className="button-style-noborders-lil"
                            variant="contained" onClick={handleVideoDialogOpen}
                            style={{backgroundColor:"black", color:"white", marginLeft:"10px"}}>
                        <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px"}}>
                            Vidéo
                        </Typography>
                    </Button>
                    {
                        tempImages.length>1 && (
                            <IconButton onClick={()=> handleOpenReorderImagesDialog()} className="icon" style={{ width: "36px", height:"36px", display: "flex", alignSelf: "top", marginLeft:"10px" }}>
                                <img src={orderImagesIc} style={{width:"30px", height:"30px"}}/>
                            </IconButton>
                        )
                    }
                </div>
                <div style={{marginTop:"15px", display:"flex", width:"100%"}}>
                    {tempImages?.length<1 ?
                        <div>Aucun média séléctionné</div> :  <ImageCarousel style={{marginTop:"10px"}} imagesArray={tempImages} onDeleteImage={handleDeleteImage} onAddInstaLink={handleVideoDialogOpen} onRemoveInstaLink={handleRemoveLink}/>}
                </div>

                <div className="field-container">
                    <div className="title-field-container" style={{marginBottom:"0"}}>
                                  <span className="text-field-style">
                                      Associer une adresse
                                  </span>
                    </div>
                </div>
                <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    {Array.isArray(userObject.address) && userObject.address[0] ? (
                        userObject.address.map((addressValue, index) => (
                            addressValue.length>0 && (
                            <div
                                key={index}
                                onClick={() => handleCardClick(addressValue)}
                                style={{
                                    display: "flex",
                                    background:
                                        formData.selectedAddress === addressValue
                                            ? "linear-gradient(90deg, #4808FF 0%, #9747FF 100%)"
                                            : "white",
                                    /*background: "linear-gradient(90deg, #4808FF 0%, #9747FF 100%)",*/
                                    /*...formData.selectedAddress === addressValue
                                            ? {backgroundColor: 'linear-gradient(90deg, #4808FF 0%, #9747FF 100%) !important'}
                                            : {backgroundColor:'white'},*/
                                    marginTop: "15px",
                                    cursor: 'pointer',
                                    minHeight:"10px",
                                    borderRadius: "15px",
                                    boxShadow: "0px 4px 15px 0px rgba(218, 224, 229, 0.90)",
                                    width: "100%",
                                    flexDirection: "column", // Change to column to stack items vertically
                                }}
                            >
                                <div style={{ display: "flex", justifyContent: "flex-end", marginTop:"25px", marginRight:"5px" }}>

                                    {/*<IconButton  className="iconbutton-noborders"
                                             size="small"
                                >
                                    <FaTimes />
                                </IconButton>*/}
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row", // Change to row to have icon and content side by side
                                        alignItems: "center", // Center icon and content vertically
                                        padding: "1px 20px", // Add padding to create some space around the content
                                        marginBottom:"25px"
                                    }}
                                >
                                    <div style={{ width: "10%" }}>
                                            {formData.selectedAddress === addressValue ? (
                                                <img src={pinMapBlue} style={{width:"30px", height:"30px"}}/>
                                            ) : (
                                                <img src={pinMapGrey} style={{width:"30px", height:"30px"}}/>
                                            )}

                                            {/*<FaLocationDot style={{ fontSize: "1.6rem", color: formData.selectedAddress === addressValue
                                                ? "white"
                                                : "black", }} />*/}
                                        {/*<FaLocationDot style={{ fontSize: "1.6rem", color: formData.selectedAddress === addressValue
                                                ? "white"
                                                : "black", }} />*/}
                                    </div>
                                    <div
                                        style={{
                                            width: "90%", // Adjusted width to make space for the close icon
                                            marginLeft: "10px",
                                        }}
                                    >
                                    <span style={{
                                        color: formData.selectedAddress === addressValue
                                            ? "white"
                                            : "black",
                                    }}>{addressValue}</span>
                                    </div>
                                </div>
                            </div>)
                        ))
                    ) : (
                        <div className="sub-container-no-top" style={{alignItems:"start"}}>
                            <div style={{display: "flex",
                                padding: "20px",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "flex-start",
                                gap: "15px",
                                borderRadius: "15px",
                                marginTop:"20px",
                                background: "#FFF",
                                boxShadow: "0px 4px 15px 0px rgba(218, 224, 229, 0.90)",
                            }}>
                                <div style={{flexDirection:"row", display:"flex", width:"100%", alignItems:"center"}}>
                                    <img src={pinGrey} alt="pingreyloc"/>
                                    <span style={{marginLeft:"10px"}}>Aucune adresse disponible.</span>
                                </div>
                            </div>

                            <Button onClick={()=>setIsAddressDialogOpen(true)} disableElevation={true} component="label" className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white", marginTop:"15px"}}>
                                <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px" }}>
                                    Ajouter une adresse
                                </Typography>
                            </Button>
                        </div>

                    )}
                </div>

                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Afficher la localisation
                                  </span>
                    </div>
                    <div>
                        {formData.isAddressVisible ? <span>⚠️ Activé : Si une adresse est séléctionnée, elle sera visible dès la page descriptive de la prestation.</span>:
                            <span>⚠️ Desactivé : Si une adresse est séléctionnée, elle sera visible sur la page de confirmation ainsi que sur l'email de réservation.</span>
                        }
                    </div>
                </div>

                <div style={{ marginTop:"10px" ,width:"100%", display:"flex", flexDirection:"row", justifyContent:"start", alignItems:"center"}}>

                    <IOSSwitch
                        checked={formData.isAddressVisible}
                        onChange={(event) => handleAddressVisibleChanged(event)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />

                    {formData.isAddressVisible ? <span style={{marginLeft:"20px", color:"black"}}>Activé</span>
                        : <span style={{marginLeft:"20px", color:"black"}}>Desactivé</span>}

                </div>

                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Associer un questionnaire
                                  </span>
                    </div>
                </div>

                <div style={{ textAlign:"center", color:"white", display:"flex", alignSelf:"start", alignContent:'start', alignItems:'start', justifyContent:'center', marginTop:"10px"}}>
                    <Button disableElevation={true} onClick={navigateToQuestionnaire} component="label" className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}}>
                        {isQuestionAdded ? <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px" }}>
                            Modifier le questionnaire
                        </Typography> : <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px"  }}>
                            Ajouter un questionnaire
                        </Typography>}
                    </Button>
                </div>

                {isQuestionAdded && (
                    <div style={{display: "flex", padding: '20px', flexDirection: "column", justifyContent: "center", marginTop:"20px", width:"84%",
                        alignItems: "center", borderRadius:"15px", background: "#FFF",
                        boxShadow: '0px 4px 15px 0px rgba(218, 224, 229, 0.90)'}}>
                        {data.questions.map((object,index)=> (
                            <div key={index} style={{display:"flex", flexDirection:"column", width:"100%", marginTop:
                                    index>0 ? "20px" : "5px"}}>
                                <div style={{display:"flex", flexDirection:"row"}}>
                                    <span style={{color:"#4808FF", fontSize:"16px"}}>
                                        Question {index+1}
                                    </span>
                                    {object.checked && (
                                        <span style={{color:"#4808FF", fontSize:"16px", marginLeft:"10px"}}>
                                            (Avec insertion d'image)
                                        </span>
                                    )}
                                </div>
                                <span style={{width:"96%", fontSize:"16px", alignSelf:"flex-end", marginTop:"10px"}}>
                                    {object.question}
                                </span>
                            </div>
                        ))}
                    </div>
                )}

                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Epingler la prestation
                                  </span>
                    </div>
                </div>
                <div style={{ marginTop:"10px" ,width:"100%", display:"flex", flexDirection:"row", justifyContent:"start", alignItems:"center"}}>

                    <IOSSwitch
                        checked={formData.isPinned}
                        onChange={(event) => handlePinChanged(event)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />

                    {formData.isPinned ? <span style={{marginLeft:"20px", color:"black"}}>Activé</span>
                        : <span style={{marginLeft:"20px", color:"black"}}>Desactivé</span>}

                </div>

                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Système de réservation
                                  </span>
                    </div>
                </div>

                <div style={{ marginTop:"10px" ,width:"100%", display:"flex", flexDirection:"column", justifyContent:"start", alignItems:"start"}}>

                    {/*<IOSSwitch
                        checked={formData.isAutoBooking}
                        onChange={(event) => handleAutoBookingChanged(event)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />

                    {formData.isAutoBooking ? <span style={{marginLeft:"20px", color:"black"}}>Activé</span>
                        : <span style={{marginLeft:"20px", color:"black"}}>Desactivé</span>}*/}
                    <MenuItem onClick={()=> (handleAutoBookingChanged("manual"))}
                              style={{
                                  zIndex:1000,
                                  background:!formData.isAutoBooking && !formData.isValidation
                                      ? "linear-gradient(150deg, #4808FF 0%, #9747FF 100%)"
                                      : "#DAE0E5",
                                  padding: "10px 15px",
                                  borderRadius:"15px",
                                  width:"200px"
                              }}
                    >
                            <span style={{width: "100%",
                                overflow: "hidden",
                                color:!formData.isAutoBooking && !formData.isValidation
                                    ? "white"
                                    : "black",
                                fontWeight:!formData.isAutoBooking && !formData.isValidation
                                    ? 700
                                    : 400,
                                fontSize:"16px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis"}}>
                                Manuel
                            </span>
                    </MenuItem>
                    <MenuItem onClick={()=> (handleAutoBookingChanged("auto"))}
                              style={{
                                  zIndex:1000,
                                  background: formData.isAutoBooking && !formData.isValidation
                                      ? "linear-gradient(150deg, #4808FF 0%, #9747FF 100%)"
                                      : "#DAE0E5",
                                  padding: "10px 15px",
                                  borderRadius:"15px",
                                  marginTop: "10px",
                                  width:"200px"
                              }}
                    >
                            <span style={{width: "100%",
                                overflow: "hidden",
                                color: formData.isAutoBooking && !formData.isValidation
                                    ? "white"
                                    : "black",
                                fontWeight: formData.isAutoBooking && !formData.isValidation
                                    ? 700
                                    : 400,
                                fontSize:"16px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis"}}>
                                Automatique
                            </span>
                    </MenuItem>
                    <MenuItem onClick={()=> (handleAutoBookingChanged("validation"))}
                              style={{
                                  zIndex:1000,
                                  background:formData.isAutoBooking && formData.isValidation
                                      ? "linear-gradient(150deg, #4808FF 0%, #9747FF 100%)"
                                      : "#DAE0E5",
                                  marginBottom: "10px",
                                  padding: "10px 15px",
                                  borderRadius:"15px",
                                  marginTop: "10px",
                                  width:"200px"
                              }}
                    >
                            <span style={{width: "100%",
                                overflow: "hidden",
                                color:formData.isAutoBooking && formData.isValidation
                                    ? "white"
                                    : "black",
                                fontWeight:formData.isAutoBooking && formData.isValidation
                                    ? 700
                                    : 400,
                                fontSize:"16px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis"}}>
                                Avec validation
                            </span>
                    </MenuItem>

                </div>

                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Statut de publication
                                  </span>
                    </div>
                </div>

                <div style={{ marginTop:"10px" ,width:"100%", display:"flex", flexDirection:"row", justifyContent:"start", alignItems:"center"}}>

                    <IOSSwitch
                        checked={formData.status==="production"}
                        onChange={(event) => handleStatusChanged(event)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />

                    {formData.status==="production" ? <span style={{marginLeft:"20px", color:"black"}}>Publiée</span>
                        : <span style={{marginLeft:"20px", color:"black"}}>Brouillon</span>}

                </div>


                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Afficher ou masquer la prestation
                                  </span>
                    </div>
                </div>

                <div style={{width:"100%", display:"flex",flexDirection:"row", marginTop:"10px",alignItems:"center"}}>
                    <button className="eye-button" style={{
                        background: formData.isVisible ?  "linear-gradient(180deg, #FF0844 0%, #4808FF 100%)" :"#DAE0E5",
                        color: formData.isVisible ?  "white" :"black"
                    }}
                            onClick={handleEyeClicked}>
                        <FaRegEye style={{fontSize:"1.2rem"}}></FaRegEye>
                    </button>
                    <button className="eye-button" style={{background: !formData.isVisible ?  "linear-gradient(180deg, #FF0844 0%, #4808FF 100%)" :"#DAE0E5", marginLeft:"25px",
                        color: !formData.isVisible ?  "white" :"black"}} onClick={handleEyeClicked}>
                        <FaRegEyeSlash style={{fontSize:"1.2rem"}}></FaRegEyeSlash>
                    </button>
                    {formData.isVisible ? <span style={{marginLeft:"20px", color:"black"}}>Visible</span>
                        : <span style={{marginLeft:"20px", color:"black"}}>Masquée</span>}
                </div>


                {formData.status==="draft" ? (
                            <div className="button-container" style={{marginTop:"70px"}}>
                                <Button onClick={() => handleFormSubmit()} className="blue-button button-style-noborders" variant="contained">
                                    <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                        Enregistrer
                                    </Typography>
                                </Button>
                            </div>
                        ) : (
                            <div className="button-container" style={{marginTop:"70px"}}>
                                <Button onClick={() => handleFormSubmit()} className="button-style button-style-noborders" variant="contained">
                                    <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                        Enregistrer et publier
                                    </Typography>
                                </Button>
                            </div>
                )}

                <div className="button-container" style={{marginTop:"15px"}}>
                    <Button className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateHome}>
                        <Typography variant="button" style={{ textTransform: 'none' }}>
                            Annuler
                        </Typography>
                    </Button>
                </div>

                <div className="button-container" style={{marginTop:"70px"}}>
                    <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-noborders" disableElevation variant="contained" onClick={() => setIsDelDialogOpen(true)}>
                        <Typography variant="button" style={{ textTransform: 'none', textDecoration: "underline" }}>
                            Supprimer
                        </Typography>
                    </Button>
                </div>


                <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={showBackdrop}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>)}
            <Dialog open={isAddressDialogOpen} onClose={handleAddressCloseOutside}
                    fullWidth={!isLargeScreen}
                    PaperProps={{
                        style: {
                            minWidth: isLargeScreen ? '500px' : 'auto',
                            maxWidth: isLargeScreen ? '500px' : 'auto'
                        },
                    }}
            >

                <DialogContent style={{backgroundColor:"white"}}>
                    <div style={{display:"flex", flexDirection:"column"}}>
                            <span style={{fontWeight:700, fontSize:"16px"}}>
                                Séléctionner une adresse
                            </span>
                        <div style={{display:"flex", flexDirection:"column", marginTop:"12px" }}>
                            <AutocompleteAddressFrance onAddressSelected={handleAddressSelected} />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{backgroundColor:"white" }}>
                    <div style={{width:"100%", display:"flex", alignItems:"end", justifyContent:"end"}}>
                        <Button className="button-style-noborders-lil" style={{marginRight:"6px", marginBottom:"6px", backgroundColor:"black"}}
                                variant="button" onClick={handleAddressDialogClose}>
                            <Typography variant="button" style={{textTransform: 'none', color:"white", fontSize:"14px", fontWeight:400}}>
                                Valider
                            </Typography>
                        </Button>
                    </div>

                </DialogActions>
            </Dialog>
            <Dialog open={isDelDialogOpen} onClose={handleDelDialogClose}
                    fullWidth={!isLargeScreen}
                    PaperProps={{
                        style: {
                            minWidth: isLargeScreen ? '500px' : 'auto',
                            maxWidth: isLargeScreen ? '500px' : 'auto'
                        },
                    }}>
                <DialogContent style={{backgroundColor:"white"}}>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"start"}}>
                        <span style={{fontSize:"16px", fontWeight:700, marginTop:"30px"}}>Supprimer cette prestation</span>
                        <span style={{fontSize:"16px", marginTop:"30px"}}>Votre prestation ne sera plus visible. Les réservations à venir et passées apparaîtront toujours sur la page "Rendez-vous".</span>
                    </div>
                </DialogContent>
                <DialogActions style={{backgroundColor:"white"}}>
                    <div className="button-container" style={{marginBottom:"30px",marginTop:"6px", marginLeft:"30px", marginRight:"30px"}}>
                        <Button disableElevation onClick={handlePrestaDelete} className="button-style-nogr button-style-borders" variant="contained">
                            <Typography variant="button" style={{ textTransform: 'none', color:'black' }}>
                                Supprimer
                            </Typography>
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isVideoDialogOpen}
                onClose={handleVideoDialogClose}
                fullWidth={!isLargeScreen}
                PaperProps={{
                    style: {
                        minWidth: isLargeScreen ? '500px' : 'auto',
                        maxWidth: isLargeScreen ? '500px' : 'auto',
                    },
                }}
            >
                <DialogContent style={{ backgroundColor: "white" }}>
                    <div style={{display:"flex", flexDirection:"column"}}>
                        <span style={{fontWeight:700, fontSize:"16px"}}>
                            Ajouter une image de couverture
                        </span>
                        <div style={{marginTop:"15px"}}>
                            <Button disableElevation={true} component="label" className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}}>
                                <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px" }}>
                                    Insérer
                                </Typography>
                                <input
                                    id="file-input" style={{ display: 'none', width:"100%" }} type="file"
                                    accept="image/jpeg,image/png,image/gif,image/svg+xml" onChange={handlePopFileChange} />
                            </Button>
                        </div>
                        {tempPopImage?.imageLink && (
                            <div style={{width:"50%", marginTop:"20px"}}>
                                <div className="image-wrapper-pop">
                                    <img src={tempPopImage?.imageLink} alt={`pop`} />
                                    <IconButton
                                        style={deleteButtonStyle}
                                        onClick={() => handleDeletePopImage()}
                                    >
                                        <img src={trashic} style={{width:"20px", height:"20px"}}/>
                                    </IconButton>
                                </div>
                            </div>
                        )}
                        <span style={{fontWeight:700, fontSize:"16px", marginTop:"30px"}}>
                            Url de la vidéo
                        </span>

                        <TextField style={{marginTop:"10px"}} onChange={handleVideoLinkChange} className="field-style"
                                   label="Copiez/collez votre lien" variant="outlined" multiline={true} />
                    </div>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "white" }}>
                    <Button className="blue-button button-style-noborders" style={{marginLeft:"32px", marginRight:"32px", marginBottom:"30px", marginTop:"10px", backgroundColor:"black"}} variant="button"
                            onClick={() => {
                                handleAddVideoLink(); // Replace indexToDelete with the actual index you want to delete
                            }}
                    >
                        <Typography variant="button" style={{textTransform: 'none', color:"white"}}>
                            Confirmer
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openTagDialog} onClose={handleTagDialogCloseOutside}
                    fullWidth={!isLargeScreen}
                    PaperProps={{
                        style: {
                            minWidth: isLargeScreen ? '500px' : 'auto',
                            maxWidth: isLargeScreen ? '500px' : 'auto',
                        },
                    }}
            >

                <DialogTitle style={{backgroundColor:"white", display:"flex", justifyContent:"center", padding:"none"}}>
                    <span style={{fontSize:"16px", fontWeight:"700px", marginTop:"30px"}}>Filtrer par catégorie</span>
                </DialogTitle>
                <DialogContent style={{backgroundColor:"white"}}>
                    <div style={{display:"flex", width:"100%", maxHeight:"600px", overflowY:"auto",flex: 1,
                        flexDirection:"column", paddingTop:"20px", paddingBottom:"20px"}}>
                        {tagHistory
                            .sort((a, b) => a.localeCompare(b))
                            .map((keyword, index, array) => (
                                <MenuItem onClick={()=> (handleTempSelection(keyword))}
                                          style={{
                                              zIndex:1000,
                                              background:selectedTags.includes(keyword)
                                                  ? "linear-gradient(150deg, #4808FF 0%, #9747FF 100%)"
                                                  : "#DAE0E5",
                                              marginBottom: "10px",
                                              padding: "10px 15px",
                                              borderRadius:"15px"
                                          }}
                                          key={index}
                                          value={keyword}
                                >
                                                            <span style={{width: "100%",
                                                                overflow: "hidden",
                                                                color:selectedTags.includes(keyword)
                                                                    ? "white"
                                                                    : "black",
                                                                fontWeight:selectedTags.includes(keyword)
                                                                    ? 700
                                                                    : 400,
                                                                fontSize:"16px",
                                                                whiteSpace: "nowrap",
                                                                textOverflow: "ellipsis"}}>
                                                                {keyword}
                                                            </span>
                                </MenuItem>
                            ))
                        }


                        {/*<div style={{display: "flex", width:"90%", flexDirection: "column", justifyContent: "start", marginTop:"10px", alignSelf:"center",
                                    alignItems: "start", borderRadius: "15px", background: "#FFF", boxShadow:'0px 4px 15px 0px rgba(218, 224, 229, 0.90)'}}>
                                    <span style={{lineHeight: "24px", padding:"20px"}}>
                                        Vous n'avez pas encore ajouté de tag à vos prestations
                                    </span>
                                </div>*/}
                        {tagHistory.length==0 && (
                            <div style={{
                                display: "flex",
                                width: "90%",
                                alignSelf:"center",
                                flexDirection: "column",
                                alignItems: "center",
                                marginTop: "20px"
                            }}>
                                      <span style={{
                                          lineHeight: "24px",
                                          padding: "20px",
                                          borderRadius: "15px",
                                          background: "#FFF",
                                          boxShadow: '0px 4px 15px 0px rgba(218, 224, 229, 0.90)'
                                      }}>
                                        Vous n'avez pas encore ajouté de tag à vos autres prestations
                                      </span>
                            </div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions style={{backgroundColor:"white", marginTop:"0"}}>
                    <div className="button-container" style={{marginBottom:"30px",marginTop:"6px", marginLeft:"30px", marginRight:"30px"}}>
                        <Button onClick={handleTagDialogClose} className="button-style button-style-noborders" variant="contained">
                            <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                Enregistrer
                            </Typography>
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
            <Dialog open={openReorderImagesDialog} onClose={handleReorderImagesDialogCloseOutside}
                    fullWidth={!isLargeScreen}
                    PaperProps={{
                        style: {
                            minWidth: isLargeScreen ? '500px' : 'auto',
                            maxWidth: isLargeScreen ? '500px' : 'auto',
                        },
                    }}
            >

                <DialogTitle style={{backgroundColor:"white", display:"flex", justifyContent:"center", padding:"none"}}>
                    <span style={{fontSize:"16px", fontWeight:"700px", marginTop:"30px"}}>Changer l'ordre des images</span>
                </DialogTitle>
                <DialogContent style={{backgroundColor:"white"}}>

                    <span style={{lineHeight:"16px"}}>Appuyez longuement pour séléctionner une image, puis glissez la vers sa nouvelle position ✨</span>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable" direction="horizontal">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                        scrollBehavior: "smooth",
                                        overflowX: "auto",
                                    }}
                                >
                                    {tempReorderImages.map((imageUrl, index) => (
                                        <Draggable key={index} draggableId={String(index)} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        display: "flex",
                                                        marginTop: "15px",
                                                        marginLeft: "15px",
                                                        marginBottom: "15px",
                                                        ...(index === tempReorderImages.length - 1 ? { marginRight: "15px" } : {}),
                                                    }}
                                                >
                                                    <img onLoad={handleImageLoad} style={{
                                                        width: "133px",
                                                        height: "200px",
                                                        objectFit: "cover",
                                                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                                                        borderRadius: "15px",
                                                    }} className="fade-in" src={imageUrl.imageLink} alt={`Image ${index + 1}`} />
                                                    {/*{imageUrl.vlink.length > 0 ? (
                                                        <div style={typeIconStyle}>
                                                            <img className="importantwidthheight" style={{ objectFit: "contain", cursor: "pointer" }} src={playIc} alt="Play icon"/>
                                                        </div>
                                                    ) : (
                                                        <div style={typeIconStyle}>
                                                            <img className="importantwidthheight" style={{ objectFit: "contain" }} src={imglogo} alt="Image logo"/>
                                                        </div>
                                                    )}*/}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>


                </DialogContent>
                <DialogActions style={{backgroundColor:"white", marginTop:"0"}}>
                    <div className="button-container" style={{marginBottom:"30px",marginTop:"6px", marginLeft:"30px", marginRight:"30px"}}>
                        <Button onClick={handleReorderImagesClose} className="button-style button-style-noborders" variant="contained">
                            <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                Enregistrer
                            </Typography>
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    )
}
