import React, {useEffect, useState} from 'react';
import logo from "../assets/svgLogo.svg";
import Button from "@mui/material/Button";
import {FaEye, FaGoogle, FaInstagram} from "react-icons/fa";
import {Backdrop, CircularProgress, Divider, TextField, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {useLocation} from "react-router-dom";
import {checkIfEmailExists, createUserWithEmailAndPassword, createUserWithGoogle} from "../firebase";
import { useNavigate } from 'react-router-dom';
import firebase from "firebase/compat/app";
import 'firebase/firestore'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import IconButton from "@mui/material/IconButton";
import {FaEyeSlash} from "react-icons/fa6";
import SnackbarWithCustomBackground from "./snackbar";
import googleButton from "../assets/googleButton.svg";

const validateEmail = (email) => {
    // Basic email validation using a regular expression
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
};

function Create() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const navigate = useNavigate();
    const [showBackdrop, setShowBackdrop] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // User is signed in, redirect to the new page
                navigate("/info");
            } else {
                // User is signed out, you can handle this case as needed
                setShowBackdrop(false)
            }
        });

        // Cleanup the observer when the component is unmounted
        return () => unregisterAuthObserver();
    }, []);

    //Display before data loaded
    if (showBackdrop) {
        return <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showBackdrop}
        >
            <CircularProgress color="inherit" />
        </Backdrop>;
    }

    const handleGoogleSignIn = async () => {
        try {
            const user = await createUserWithGoogle();
        } catch (error) {
            // Handle any errors that occur during the sign-in process
            console.error('Error signing in with Google:', error);
        }
    };

    const handleIconEyeClick = () => {
        setShowPassword(!showPassword);
    }

    const handleFirstNameChange = (event) => {
        setPrenom(event.target.value);
    };

    const handleLastNameChange = (event) => {
        setNom(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleFormSubmit = async () => {
        setShowBackdrop(true);
        if (prenom.length<1){
            handleOpenSnackbar("Vous devez remplir votre prénom.");
            setShowBackdrop(false);
            return;
        } else if (nom.length<1){
            handleOpenSnackbar("Vous devez remplir votre nom.");
            setShowBackdrop(false);
            return;
        } else if (password.length<6){
            handleOpenSnackbar("Vous devez remplir ajouter un mot de passe de plus de 6 caractères.");
            setShowBackdrop(false);
            return;
        } else if (!validateEmail(email)){
            handleOpenSnackbar("L'adresse email n'est pas valide, vérifiez la.");
            setShowBackdrop(false);
            return;
        }

        try {
            const emailExist = await checkIfEmailExists(email);
            if (emailExist){
                handleOpenSnackbar("Cette adresse email ne peut pas être utilisée :/");
                setShowBackdrop(false);
                return;
            }
        } catch (e) {
            console.log("erreur ", e.message);
            handleOpenSnackbar("Quelque chose s'est mal passé, réessayez ou contactez nous.");
            setShowBackdrop(false);
            return;
        }

        try {
            const result = await createUserWithEmailAndPassword(email, password, prenom, nom);
        } catch (e) {
            console.log("error while creating user : ",e.message);
            setShowBackdrop(false);
            return;
        }
        setShowBackdrop(false);
    }

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const handleOpenLink = (link) => {
        window.open(link);
    };


    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    return (
        <div className="sub-container">
            <div className="sub-container-no-top">

                <div className="title-container">
                    <span className="title-style">Inscription</span>
                </div>

                <div className="field-container">
                    <div className="title-field-container">
                                      <span className="text-field-style">
                                          Prénom <span className="aster">*</span>
                                      </span>
                    </div>
                    <TextField className="field-style" label="Ecrire" variant="outlined"
                    onChange={handleFirstNameChange}/>
                </div>

                <div className="field-container-b">
                    <div className="title-field-container">
                                      <span className="text-field-style">
                                          Nom <span className="aster">*</span>
                                      </span>
                    </div>
                    <TextField className="field-style" label="Ecrire" variant="outlined"
                               onChange={handleLastNameChange}/>
                </div>

                <div className="field-container-b">
                    <div className="title-field-container">
                                      <span className="text-field-style">
                                          Email <span className="aster">*</span>
                                      </span>
                    </div>
                    <TextField className="field-style" label="Ecrire" variant="outlined"
                               onChange={handleEmailChange}/>
                </div>
                <div className="field-container-b">
                    <div className="title-field-container">
                                      <span className="text-field-style">
                                          Mot de passe <span className="aster">*</span>
                                      </span>
                    </div>
                    <TextField type={showPassword ? "text" : "password"} className="field-style" label="Ecrire"
                               onChange={handlePasswordChange} variant="outlined"
                               InputProps={{
                                   endAdornment: (
                                       <IconButton onClick={handleIconEyeClick} edge="end">
                                           {showPassword ? <FaEye /> : <FaEyeSlash />}
                                       </IconButton>
                                   ),
                               }}/>
                </div>
                <div className="button-container">
                    <Button className="button-style button-style-noborders" variant="contained" onClick={handleFormSubmit} >
                        <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                            S'inscrire
                        </Typography>
                    </Button>
                </div>

                <div className="button-container" style={{marginTop:'30px', marginLeft:"20px", fontSize:'16px', color:'#000000'}}>
                    <p style={{width:"100%"}}>
                        En vous inscrivant vous acceptez <a target="_blank" href="https://shine.faismoibg.com/cgu" style={{textDecoration:"underline", cursor:"pointer"}}>nos CGU</a> <span>ainsi que</span> <a target="_blank" href="https://shine.faismoibg.com/politique-confidentialite" style={{textDecoration:"underline", cursor:"pointer"}}>notre politique de confidentialité</a>.
                    </p>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', margin: '1rem', width:'300px', marginTop:'30px', position: 'relative' }}>
                    <Divider className="custom-divider" style={{ flexGrow: 1, background:'black'}} />
                    <span style={{ margin: '0 0.5rem', fontWeight:'500', color:'grey' }}>OU</span>
                    <Divider className="custom-divider"  style={{ flexGrow: 1 , background:'black' }} />
                </div>
                <div className="button-container b">
                    <Button disableElevation={true} onClick={handleGoogleSignIn} className="g-button" variant="contained" startIcon={<img style={{width:"20px", height:"20px", marginRight:"4px",}} src={googleButton}></img>}>
                        <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF', fontFamily:"Roboto Medium, sans-serif", fontWeight:400}}>
                            S'inscrire avec Google
                        </Typography>
                    </Button>
                </div>

                <div className="button-container" style={{marginTop:"100px"}}>
                    <Button disableElevation={true} style={{marginBottom:"100px"}} className="button-style-nogr button-style-borders" component={Link} to="/" variant="contained">
                        <Typography variant="button" style={{ textTransform: 'none' }}>
                            Se connecter
                        </Typography>
                    </Button>
                </div>
                <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />
            </div>
        </div>
    )
}

export default Create;
