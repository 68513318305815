import React, {useEffect, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {Popper} from "@mui/material";

const TimePicker = ({ onTimeSelected, selectedTime }) => {


    // Generate time options with 10-minute steps from 0 to 5 hours, memoized to avoid re-computations
    const timeOptions = React.useMemo(() => {
        const options = [];
        for (let hours = 0; hours <= 10; hours++) {
            for (let minutes = 0; minutes < 60; minutes += 5) {
                const time = `${String(hours).padStart(2, '0')}h${String(minutes).padStart(2, '0')}`;
                options.push(time);
                if (hours === 10 && minutes === 0) {
                    // We stop generating options after 5:00
                    break;
                }
            }
        }
        return options;
    }, []);

    const [value, setValue] = useState(null);
    useEffect(() => {
        if (selectedTime && selectedTime.length > 2) {
            setValue(selectedTime); // Set the value to the selectedTime or an empty string if selectedTime is null
        }
    }, [selectedTime]);

    const handleTimeChange = (_, newValue) => {
        setValue(newValue);
        onTimeSelected(newValue);
    };

    const getOptionStyle = () => ({
        borderRadius: "15px", // Set the border radius for each option
        margin: "10px 0", // Add space between the list items
        background: "#DAE0E5", // Set background color for each option
        padding: "15px", // Add padding for each option
        color:"#000",
    });

    return (
        <div style={{width:"100%"}}>
            <Autocomplete
                PopperComponent={(props) => (
                    <div style={{display:"flex", flexDirection:"column"}}>
                        {props.children}
                    </div>
                )}
                className="custom-autocomplete button-autocomplete" style={{background:"#F1F5F8", borderRadius:"15px", width:"100%"}}
                onChange={handleTimeChange}
                value={value}
                          disableClearable={true}
                options={timeOptions}
                getOptionSelected={(option, value) => option === value} // Keep the original selected logic
                getOptionLabel={(option) => {
                    const [hours, minutes] = option.split('h');
                    return parseInt(hours) === 0 ? `${minutes} min` : `${option}`;
                }}
                renderOption={(props, option) => {
                    const [hours, minutes] = option.split('h');
                    const formattedOption = parseInt(hours) === 0 ? `${minutes} min` : `${option}`;
                    return (
                        <li style={getOptionStyle()} {...props}>
                            {formattedOption}
                        </li>
                    );
                }}
              /*renderOption={(props, option, { selected }) => {
                  return <li style={getOptionStyle()} {...props}>{option}</li>;
              }}*/
                renderInput={({ inputProps, ...params }) => (
                    <TextField {...params}
                               label="Durée"
                               inputProps={{ ...inputProps, readOnly: true }}/>
                )}
            />
        </div>
    );
};

export default TimePicker;