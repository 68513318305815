import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {Backdrop, CircularProgress, Skeleton, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {getAgendaDoc, getUser, getUserRdvPerDate, getUserSubscription} from "../firebase";
import firebase from "firebase/compat/app";
import pinMapGrey from "../assets/mapPinGrey.png";
import timeCheckGrey from "../assets/timecheckGrey.png";
import icRightArrow from "../assets/icRightArrow.svg";
import icLeftArrow from "../assets/icLeftArrow.svg";
import SnackbarWithCustomBackground from "./snackbar";
import {HeaderHome} from "./headerHome";
import checkGreenIc from "../assets/ic_check.svg";
import dollarIc from "../assets/dollaIc.svg";
import timeTableOrangeIc from "../assets/icTimeTableGreen.svg";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";

function formatDate(date) {
    const dayNames = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
    const monthNames = Object.keys(monthMap);
    return `${dayNames[date.getDay()]} ${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
}
const monthMap = {
    "janvier": 0,
    "février": 1,
    "mars": 2,
    "avril": 3,
    "mai": 4,
    "juin": 5,
    "juillet": 6,
    "août": 7,
    "septembre": 8,
    "octobre": 9,
    "novembre": 10,
    "décembre": 11
};

function convertToTimeZone(date, timezone) {

    const options = {
        timeZone: timezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);
    const formattedDate = formatter.format(date);

    return new Date(formattedDate);
}

function formatHour(date) {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}h${minutes}`;
}

const faStatusStyle = {
    position: 'absolute',
    top: '8px',
    right: '8px',
    opacity:'100%',
    zIndex: 1,
};

export default function Rdv() {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const realDate = new Date();
    const [items, setItems] = useState([]);
    const [displayDate, setDisplayDate] = useState(formatDate(new Date()));
    const [userHasAgenda, setUserHasAgenda] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [timeZoneState, setTimeZoneState] = useState("Europe/Paris");
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [subStatus, setSubStatus] = useState("");
    const [rdvLeft, setRdvLeft] = useState(0);
    const fallBackUrl = "https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA.."

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async (user) => {
            let timezone = "Europe/Paris";
            if (!user) {
                navigate("/");
            } else {
                const fetchData = async () => {
                    const currentDate = new Date();
                    try {
                        const subObj = await getUserSubscription(user.uid);
                        if (subObj?.status){
                            setSubStatus(subObj.status);
                            setRdvLeft(50 - subObj.count);
                        } else {
                            setSubStatus("free");
                            setRdvLeft(0);
                        }
                        const agendaDoc = await getAgendaDoc(user.uid);
                        const userData = await getUser(user.uid);
                        if (userData?.timezone) {
                            timezone = userData.timezone;
                            setTimeZoneState(timezone);
                        }
                        if (agendaDoc) {
                            setUserHasAgenda(true);
                        }
                        const rdvArray = await getUserRdvPerDate(user.uid, currentDate.getMonth(), currentDate.getDate(), currentDate.getFullYear());
                        /*const rdvArrayCleaned = rdvArray.filter(e => e.isCanceled === false);
                        if (rdvArrayCleaned.length > 0) {
                            //setItems(rdvArrayCleaned);
                        }*/
                        if (rdvArray.length>0){
                            setItems(rdvArray);
                        }
                    } catch (e) {
                        console.error('Error ', e.message);
                    }
                    setShowSkeleton(false)
                };
                await fetchData();
                setShowSkeleton(false)
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => {
            unregisterAuthObserver();
        }
    }, []);

    const navigatePreviousDay = async () => {
        setShowBackdrop(true);
        const previousDay = new Date(currentDate);
        previousDay.setDate(currentDate.getDate() - 1);
        setCurrentDate(previousDay);
        const newDateString = formatDate(previousDay);
        setDisplayDate(newDateString);

        let rdvArray = [];
        try {
            rdvArray = await getUserRdvPerDate(firebase.auth().currentUser.uid, previousDay.getMonth(), previousDay.getDate(), previousDay.getFullYear());
            //rdvArray = rdvArray.filter(e => e.isCanceled === false);
        } catch (e) {
            console.log(e.message);
        }
        setItems(rdvArray);

        setShowBackdrop(false);
    }

    const [openDatePicker, setOpenDatePicker] = useState(false);

    const navigateToDate = async (date) => {
        setShowBackdrop(true);
        const newDate = new Date(date);
        const newDateString = formatDate(newDate);
        setDisplayDate(newDateString);
        let rdvArray = [];
        try {
            rdvArray = await getUserRdvPerDate(firebase.auth().currentUser.uid, newDate.getMonth(), newDate.getDate(), newDate.getFullYear());
            //rdvArray = rdvArray.filter(e => e.isCanceled === false);
        } catch (e) {
            console.log(e.message);
        }
        setItems(rdvArray);
        setShowBackdrop(false);
    };

    // This function is used to toggle the DatePicker when the string is clicked
    const toggleDatePicker = () => {
        setOpenDatePicker(!openDatePicker);
    };

    // Function to prevent the DatePicker from closing when a date is selected
    const handleCalendarSelect = (date) => {
        setOpenDatePicker(false);
        setCurrentDate(new Date(date)); // Update the current date
        navigateToDate(date);
        // setOpenDatePicker(false); // Uncomment this if you want to close the picker after selection
    };

    const navigateNextDay = async () => {
        setShowBackdrop(true);
        const nextDay = new Date(currentDate);
        nextDay.setDate(currentDate.getDate() + 1);
        setCurrentDate(nextDay);
        const newDateString = formatDate(nextDay);
        setDisplayDate(newDateString);
        let rdvArray = [];
        try {
            rdvArray = await getUserRdvPerDate(firebase.auth().currentUser.uid, nextDay.getMonth(), nextDay.getDate(), nextDay.getFullYear());
            //rdvArray = rdvArray.filter(e => e.isCanceled === false);
        } catch (e) {
            console.log(e.message);
        }
        setItems(rdvArray);
        setShowBackdrop(false);
    };
    const navigateToRdvDetails = (index) => {
        navigate("/rdvdetails/" + items[index].documentId)
    };
    const navigateHome = () => {
        navigate("/home");
    };
    const navigateToRdvPresta = () => {
        if (rdvLeft>0 || (subStatus==="trialing" || subStatus==="active")){
            if (!userHasAgenda){
                handleOpenSnackbar("Cliquez sur 'Paramètres de l'agenda', ajoutez et enregistrez vos paramètres une fois pour commencer à prendre un rendez-vous.")
            } else {
                navigate("/rdvpresta");
            }
        } else {
            handleOpenSnackbar("Il ne vous reste plus de rendez-vous pour ce mois. Passez en forfait premium, ou attendez le mois prochain.")
        }
    };
    const navigateToAgendaParam = () => {
        navigate("/agendaparam");
    };

    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            // Close the dialog if a click outside the dialog's DOM subtree is detected.
            const clickedElementIsDialogPopup = event.target.classList.contains('MuiDialog-container');
            //const clickedElementIsDialogPopup = event.target.closest('[tabindex="-1"]');
            if (clickedElementIsDialogPopup) {
                setOpenDatePicker(false);
            }
        };

        if (openDatePicker) { // Add listener only if the dialog is open
            document.addEventListener('click', handleOutsideClick);
        }

        return () => {
            // Cleanup listener when the dialog is not open or if the component is unmounting
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [openDatePicker]);

    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            {showSkeleton ?
                (
                    <div className="sub-container-no-top">
                        <div style={{
                            display: "flex", width: "100%", flexDirection: "column",
                            justifyContent: "center", alignContent: "center", alignItems: "center"
                        }}>
                            <Skeleton style={{borderRadius: "15px"}} variant="text" sx={{fontSize: '2rem'}}
                                      width="70%"/>
                            <Skeleton style={{marginTop: "50px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                            <Skeleton style={{marginTop: "25px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                            <Skeleton style={{marginTop: "25px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                        </div>
                    </div>
                ) : (
                    <div className="sub-container-no-top">
                        <span className="title-style">Vos rendez-vous</span>
                        <div className="button-container">
                            <Button className="button-style button-style-noborders" variant="contained"
                                    onClick={navigateToRdvPresta}>
                                <Typography variant="button" style={{textTransform: 'none', color: '#FFFFFF'}}>
                                    Planifier un rendez-vous
                                </Typography>
                            </Button>
                        </div>
                        <div className="button-container" style={{marginTop: "23px"}}>
                            <Button className="button-style-agenda button-style-noborders" variant="contained"
                                    onClick={navigateToAgendaParam}>
                                <Typography variant="button" style={{textTransform: 'none', color: '#FFFFFF'}}>
                                    Paramètres de l'agenda
                                </Typography>
                            </Button>
                        </div>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "36px"
                        }}>
                            <IconButton onClick={navigatePreviousDay} variant="contained"
                                        style={{color: "white", display: "flex"}}>
                                <img src={icLeftArrow} style={{width: "40px", height: "40px"}}/>
                            </IconButton>
                            <div style={{
                                fontWeight: "700",
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center"
                            }}>
                                <span onClick={toggleDatePicker} style={{cursor:"pointer"}}>
                                    {displayDate}
                                </span>
                                    <div style={{position:"absolute", visibility:"hidden"}}>
                                        {openDatePicker && (
                                            <div>
                                                <DatePicker  selected={dayjs(currentDate)} style={{visibility:"hidden"}}
                                                             value={dayjs(currentDate)}
                                                             onChange={handleCalendarSelect}
                                                             open={openDatePicker}
                                                             closeOnSelect={true}
                                                             timezone={timeZoneState}
                                                             desktopModeMediaQuery='(min-width: 70768px)'
                                                />
                                            </div>
                                        )}
                                    </div>


                            </div>
                            <IconButton onClick={navigateNextDay} variant="contained"
                                        style={{color: "white", display: "flex"}}>
                                <img src={icRightArrow} style={{width: "40px", height: "40px"}}/>
                            </IconButton>
                        </div>

                            <div style={{position:"relative"}}>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={showBackdrop}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                            </div>

                        {items.sort((a, b) => a.start.seconds - b.start.seconds).map((item, index) => (
                            <div key={index+1} onClick={() => navigateToRdvDetails(index)}
                                 style={{
                                     display: "flex",
                                     background: item?.start?.toDate() > realDate ? "#FFF" : "#F5F5F5",
                                     /*opacity: item?.start?.toDate() > realDate ? "100%" : 0.7,
                                     WebkitOpacity: item?.start?.toDate() > realDate ? "100%" : 0.7,
                                     MozOpacity: item?.start?.toDate() > realDate ? "100%" : 0.7,*/
                                     cursor: item?.start?.toDate() > realDate ? "pointer" : "pointer",
                                     marginTop: "23px",
                                     minHeight: "10px",
                                     borderRadius: "15px",
                                     boxShadow: item?.start?.toDate() > realDate ? "0px 4px 15px 0px rgba(0, 0, 0, 0.25)" : "none",
                                     width: "100%",
                                     flexDirection: "column",
                                 }}
                            >
                                {item.isCanceled && (
                                    <div style={{position:"relative"}}>
                                        <div style={faStatusStyle} >
                                            <div style={{display: "flex", padding: "5px 10px", alignItems: "center", gap: "10px",
                                                borderRadius:"99px",
                                                background: item.isCanceled ? "linear-gradient(90deg, #8A9699 0%, #434849 100%)" : "linear-gradient(90deg, #06AC2C 0%, #28D850 100%)"
                                            }}>
                                    <span style={{color: "white", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "normal"}}>
                                        Annulé
                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                )}


                                <div style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginTop: "25px",
                                    marginRight: "5px"
                                }}>
                                </div>
                                <div
                                    style={{
                                        color: "inherit",
                                        display: "flex",
                                        flexDirection: "row", // Change to row to have icon and content side by side
                                        alignItems: "center", // Center icon and content vertically
                                        padding: "1px 20px", // Add padding to create some space around the content
                                        marginBottom: "15px"
                                    }}
                                >
                                    <div style={{
                                        width: "30%",
                                        alignItems: "center",
                                        alignContent: "center",
                                        justifyContent: "center",
                                        display: "flex"
                                    }}>
                                        <img onLoad={handleImageLoad}
                                             onError={(e) => {
                                                 if (e.target.src!==fallBackUrl){
                                                     e.target.onerror = null;
                                                     e.target.src= fallBackUrl
                                                 }
                                             }}
                                             className="fade-in" style={{
                                            color: "white",
                                            borderRadius: "5px",
                                            width: "60px",
                                            height: "60px",
                                            objectFit: "cover",
                                        }}
                                             src={item?.prestaSnapshot?.imageLinks?.length > 0 ? item?.prestaSnapshot?.imageLinks[0]?.imageLink : "https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA.."}/>
                                    </div>
                                    <div
                                        style={{
                                            width: "70%", // Adjusted width to make space for the close icon
                                            marginLeft: "10px",
                                            marginRight: "50px",
                                        }}
                                    >
                                        <span style={{
                                            color: "black",
                                            fontWeight: "700",
                                            fontSize: "16px"
                                        }}>{item.prestaSnapshot.title}</span>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row", // Change to row to have icon and content side by side
                                        alignItems: "center", // Center icon and content vertically
                                        padding: "6px 20px", // Add padding to create some space around the content
                                        marginBottom: "15px"
                                    }}
                                >
                                    <div style={{
                                        width: "10%",
                                        alignItems: "center",
                                        alignContent: "center",
                                        justifyContent: "center",
                                        display: "flex"
                                    }}>
                                        <img src={timeCheckGrey} style={{width: "30px", height: "30px"}}/>
                                    </div>
                                    <div
                                        style={{
                                            width: "90%",
                                            marginLeft: "10px",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignContent: "center",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <span
                                            style={{color: "black"}}>{formatHour(convertToTimeZone(new Date(item.start.seconds * 1000), timeZoneState))}</span>
                                    </div>
                                </div>

                                {item?.prestaSnapshot?.selectedAddress?.length > 2 && (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row", // Change to row to have icon and content side by side
                                            alignItems: "center", // Center icon and content vertically
                                            padding: "6px 20px", // Add padding to create some space around the content
                                            marginBottom: "15px"
                                        }}
                                    >
                                        <div style={{
                                            width: "10%",
                                            alignItems: "center",
                                            alignContent: "center",
                                            justifyContent: "center",
                                            display: "flex"
                                        }}>
                                            <img src={pinMapGrey} style={{width: "30px", height: "30px"}}/>
                                        </div>
                                        <div
                                            style={{
                                                width: "90%",
                                                marginLeft: "10px",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignContent: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <span style={{color: "black"}}>{item?.prestaSnapshot?.selectedAddress}</span>
                                        </div>
                                    </div>
                                )}

                                {!item.isCanceled && (
                                    item?.prestaSnapshot?.isValidation && !item?.isValidated ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row", // Change to row to have icon and content side by side
                                                alignItems: "center", // Center icon and content vertically
                                                padding: "6px 20px", // Add padding to create some space around the content
                                                marginBottom: "25px"
                                            }}
                                        >
                                            <div style={{
                                                width: "10%",
                                                alignItems: "center",
                                                alignContent: "center",
                                                justifyContent: "center",
                                                display: "flex"
                                            }}>

                                                <img src={timeTableOrangeIc} style={{width: "30px", height: "30px"}}/>

                                            </div>
                                            <div
                                                style={{
                                                    width: "90%",
                                                    marginLeft: "10px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignContent: "center",
                                                    justifyContent: "center"
                                                }}
                                            >
                                                <span style={{color: "#FBBC04", fontWeight:700}}>Réservation en attente</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row", // Change to row to have icon and content side by side
                                                alignItems: "center", // Center icon and content vertically
                                                padding: "6px 20px", // Add padding to create some space around the content
                                                marginBottom: "25px"
                                            }}
                                        >
                                            <div style={{
                                                width: "10%",
                                                alignItems: "center",
                                                alignContent: "center",
                                                justifyContent: "center",
                                                display: "flex"
                                            }}>
                                                {!item.prestaSnapshot?.isDeposit ? (<img src={checkGreenIc} style={{width: "30px", height: "30px"}}/>):(
                                                    <img src={dollarIc} style={{width: "30px", height: "30px"}}/>
                                                )}
                                            </div>
                                            <div
                                                style={{
                                                    width: "90%",
                                                    marginLeft: "10px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignContent: "center",
                                                    justifyContent: "center"
                                                }}
                                            >
                                                <span style={{color: "#06AC2C", fontWeight:700}}>Réservation validée</span>
                                            </div>
                                        </div>
                                )

                                )}
                            </div>
                        ))}

                        {items.length === 0 && !showBackdrop && (
                            <div className="rdv-card" style={{marginTop: "40px"}}>
                                <div style={{margin: "20px"}}>
                                    <span>Aucun rdv ce jour</span>
                                </div>
                            </div>
                        )}

                        <div className="button-container" style={{marginTop: "70px"}}>
                            <Button style={{marginBottom: "100px"}} className="button-style-nogr button-style-borders"
                                    disableElevation variant="contained" onClick={navigateHome}>
                                <Typography variant="button" style={{textTransform: 'none'}}>
                                    Retour
                                </Typography>
                            </Button>
                        </div>
                        <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar}
                                                      message={snackMessage} style={{width: "100%"}}/>
                    </div>
                )}
        </div>
    )
}