import React, {useEffect, useState} from "react";
import {TextField, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import SnackbarWithCustomBackground from "./snackbar";
import Button from "@mui/material/Button";
import {resetPassword} from "../firebase";
const validateEmail = (email) => {
    // Basic email validation using a regular expression
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
};
export function ResetPassword() {

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const [email, setEmail] = useState("");
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };
    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };

    const navigateHome = () => {
        navigate("/")
    }

    const handleSendReset = async () => {
        if (validateEmail(email)){
            const success = await resetPassword(email);
            if (success) {
                handleOpenSnackbar("Un lien vous a été envoyé");
            } else {
                handleOpenSnackbar("Quelque chose s'est mal passé");
            }
        } else {
            handleOpenSnackbar("Cette adresse email n'est pas valide.");
        }
    }

    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    return (
        <div className="sub-container">
            <div className="sub-container-no-top">

                <div className="title-container">
                    <span className="title-style" style={{textAlign:"center"}}>Récupérer votre mot de passe</span>
                </div>

                <p style={{fontSize:"20px", textAlign:"center"}}>
                    Veuillez saisir votre adresse e-mail ci-dessous. Nous vous enverrons les instructions pour créer un nouveau mot de passe.
                </p>

                <div className="field-container-b">
                    <div className="title-field-container">
                                      <span className="text-field-style">
                                          Votre Email
                                      </span>
                    </div>
                    <TextField className="field-style" label="Ecrire" variant="outlined"
                               onChange={handleEmailChange}/>
                </div>
                <div className="button-container">
                    <Button className="button-style button-style-noborders" onClick={handleSendReset} disableElevation variant="contained">
                        <Typography variant="button" style={{ textTransform: 'none',color:"white" }}>
                            Envoyer
                        </Typography>
                    </Button>
                </div>
                <div className="button-container" style={{marginTop:"100px"}}>
                    <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-borders" onClick={navigateHome} disableElevation variant="contained">
                        <Typography variant="button" style={{ textTransform: 'none'}}>
                            Retour
                        </Typography>
                    </Button>
                </div>
                <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />
            </div>
        </div>
    )
}